import React from 'react';
import { Grid } from '@material-ui/core';

import GrossTonnagesTable from './grossTonnages/Table'
import GrossTonnageForm from './grossTonnages/Form'

export default function GrossTonnages(props) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          {!props.match.params.grossTonnageId ?
            <GrossTonnagesTable
              harbourId={props.match.params.harbourId}
              actorId={props.match.params.id ? props.match.params.id : null}
            />
            :
            <GrossTonnageForm
              actorId={props.match.params.id ? props.match.params.id : null}
              harbourId={props.match.params.harbourId}
              grossTonnageId={props.match.params.grossTonnageId}
            />
          }
        </Grid>
      </Grid>
    )
}