import { setCall, handleResponse } from '../_helpers';

export const sheetHeadersServices = { sheetHeadersFetchAll, sheetHeaderInsert, sheetHeaderUpdate, sheetHeaderDelete }

async function sheetHeadersFetchAll() {
  let requestOptions = {
    method: 'POST',
    body: setCall('expense_sheet_headers', 'get_expense_sheet_headers', {}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function sheetHeaderInsert(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('expense_sheet_headers', 'expense_sheet_header_insert', data),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function sheetHeaderUpdate(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('expense_sheet_headers', 'expense_sheet_header_update', data),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function sheetHeaderDelete(keys) {
  const requestOptions = {
    method: 'POST',
    body: setCall('expense_sheet_headers', 'expense_sheet_header_remove', keys),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}