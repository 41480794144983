export const checkLocalStorage = (type = "statements") => {
  switch(type) {
    case "statements":
      const storageAccountingData = JSON.parse(localStorage.getItem("accounting_form"))
      const storageExpensesData = JSON.parse(localStorage.getItem("expenses_form"))
      const storageSharesData = JSON.parse(localStorage.getItem("shares_form"))
    
      return {
        accountingData: storageAccountingData ? storageAccountingData : null,
        expensesData: storageExpensesData ? storageExpensesData : null,
        sharesData: storageSharesData ? storageSharesData : null
      }
    case "statistics":
      const storageRevenueData = JSON.parse(localStorage.getItem("revenue_form"))
      const storageGrossTonnageData = JSON.parse(localStorage.getItem("grossTonnage_form"))
      const storageSurchargesData = JSON.parse(localStorage.getItem("surcharges_form"))
      const storageDiscountsData = JSON.parse(localStorage.getItem("discounts_form"))
    
      return {
        revenueData: storageRevenueData ? storageRevenueData : null,
        grossTonnageData: storageGrossTonnageData ? storageGrossTonnageData : null,
        surchargesData: storageSurchargesData ? storageSurchargesData : null,
        discountsData: storageDiscountsData ? storageDiscountsData : null
      }
    case "turnover":
      const storageTurnoverData = JSON.parse(localStorage.getItem("turnover_form"))
      const storageShipsData = JSON.parse(localStorage.getItem("ships_form"))
      const storageCreditsData = JSON.parse(localStorage.getItem("credits_form"))

      return {
        turnoverData: storageTurnoverData ? storageTurnoverData : null,
        shipsData: storageShipsData ? storageShipsData : null,
        creditsData: storageCreditsData ? storageCreditsData : null,
      }
    default:
      return
  }
}

export const resetStorage = (type) => {
  switch (type) {
    case 'statements':
      localStorage.removeItem('accounting_form')
      localStorage.removeItem('expenses_form')
      localStorage.removeItem('shares_form')
      break
    case 'statistics':
      localStorage.removeItem('revenue_form')
      localStorage.removeItem('grossTonnage_form')
      localStorage.removeItem('surcharges_form')
      localStorage.removeItem('discounts_form')
      break
    case 'turnover':
      localStorage.removeItem('turnover_form')
      localStorage.removeItem('ships_form')
      localStorage.removeItem('credits_form')
      break
    default:
      break
  }
  
}