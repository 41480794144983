import { customersConstants } from '../../_constants'
/*eslint eqeqeq: "off"*/

export function customers(state = [], action) {
  switch(action.type) {
    case customersConstants.CREATE_CUSTOMERS_LIST:
      return action.list
    case customersConstants.INIT_CUSTOMERS_LIST: 
      return action.list;
    case customersConstants.CLEAR:
      return null
    case customersConstants.ADD_NEW_CUSTOMER:
      if (action.data) {
        let transit = [...state]
        transit.push(action.data)
        return transit
      }
      return state
    case customersConstants.UPDATE_CUSTOMER:
      const customersList = [...state]
      
      if (action.data.length) {
        for (let i = 0 ; i < action.data.length ; i++) {
          const itemIndex = customersList.findIndex(elem => elem.id == action.data[i].id)
  
          customersList[itemIndex] = {...customersList[itemIndex], ...action.data[i]}
        }
      } else {
        const itemIndex = customersList.findIndex(elem => elem.id == action.data.id && elem.actor_id == action.data.actor_id)

        customersList[itemIndex] = {...customersList[itemIndex], ...action.data}
      }
      
      return customersList
    case customersConstants.DELETE_CUSTOMER:
      let customersArray = [...state]

      const index = customersArray.findIndex(elem => elem.id == action.data.id && elem.actor_id === action.data.actor_id)

      customersArray.splice(index, 1);
      return customersArray
    default:
      return state
  }
}