import { statisticsConstants } from '../../_constants'

export function statistics(state = {
  revenue: {},
  grossTonnages: {},
  surcharges: {
    formPattern: []
  },
  discounts: {}
}, action) {
  switch(action.type) {
    case statisticsConstants.CREATE_SURCHARGES_FORM:
      return {
        formPattern: action.formPattern
      }
    case statisticsConstants.CLEAR:
      return null
    default:
      return state
  }
}