import { userConstants, actorsConstants, shipTypesConstants, customersConstants } from '../../_constants';
import { userServices } from '../../_services';

/*
 * action creators
 */

export function login(params) {
  return function(dispatch) {
    return new Promise((resolve) => {
      userServices.userLogin(params)
        .then(json => {
          
            dispatch(receiveUserData(json.header.result, json.response.details)) 
            
        });
        resolve();
    })
  };
}

export function logout() {
  return function(dispatch) {
    return new Promise((resolve) => {
      userServices.userLogout()
        .then(json => {
          if (json) {
            dispatch(destroyUserData(json.header.result))
            dispatch({type: actorsConstants.CLEAR})
            dispatch({type: shipTypesConstants.CLEAR})
            dispatch({type: customersConstants.CLEAR})
          }
        });
        resolve();
    })
  };
}

export function updateConfiguration(selected, key){
  return { type: userConstants.UPDATE_CONFIGURATION, selected: selected, key: key}
}

export function destroyUserData(result){
  return { type: userConstants.DESTROY_USER_DATA, result: result}
}

export function receiveUserData(result, user_data) {
  return { type: userConstants.RECEIVE_USER_DATA, result: result, user_data: user_data}
}