import React, { forwardRef, useEffect, useState }  from 'react';
import {connect} from 'react-redux'

import MaterialTable from 'material-table'

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
}  from '@material-ui/icons';

import { actorServices } from '../../_services/actors.services'
import { partnerServices } from '../../_services/partners.services'
import { dic, dicTypes } from '../../_constants/dictionary.constants'

import { useSnackbar  } from 'notistack';
import ActorsBreadcrumbs from '../../_components/utils/ActorsBreadcrumbs'



function PartnersTable(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [fetchingData, setFetchingData] = useState(false)
    const [selectedActor, setSelectedActor] = useState(false);
    const [partnersList, setPartnersList] = useState([])

    const { actorId } = props;
    const { actor } = props.userData;
    
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };
      
      const parameters = [
        { title: dic.ID, field: 'id', validate: rowData => rowData.id !== '' && rowData.id !== undefined, editable: "onAdd"},
        { title: dic.SURNAME, field: 'surname', validate: rowData => rowData.surname !== '' && rowData.surname !== undefined, cellStyle: {color: "#1E7DA5"}},
        { title: dic.NAME, field: 'name', validate: rowData => rowData.name !== '' && rowData.name !== undefined, cellStyle: {color: "#1E7DA5"}}
      ]
    const responseNotification = (data) => {
        if (data.header.result === 'ok'){
            enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
            getPartners();
        }
        else{
            for (var i in data.response.details)
                enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
            setFetchingData(false)
        }
    }

    const responseTextNotification = (response) => {
        switch (response.header.function){
            case 'partner_insert':
                return dic.PARTNER_INSERTED
            case 'partner_update':
                return dic.PARTNER_UPDATED
            case 'partner_remove':
                return dic.PARTNER_DELETED
            default:
                return
        }
    }
    const getPartners = () => {
        setFetchingData(true)
        partnerServices.partnersFetchAll(actor.actor_type === 'admin' ? {actor_id: actorId} : {})
            .then(resp => {
                setPartnersList(resp.response.details)
                setFetchingData(false)
            })
    }

    const getActor = () => {
        actorServices.actorFetch({actor_id: actorId})
            .then(resp => {
            setSelectedActor(resp.response.details)
        })
    }

    useEffect(() => {
        if (actor.actor_type === 'admin' && !selectedActor)
            getActor();
        
        getPartners();
    }, [])

    const breadCrumbsList = {
        actors : {label: dic.ACTORS, url: '/actors'},
        users : {label: dic.PARTNERS, url: '/actors/'+actorId+'/partners', selected: selectedActor.corporate_name, actual: true},
    }
    return (
        <div>
            {actor.actor_type === 'admin' &&
                <ActorsBreadcrumbs breadCrumbsList={breadCrumbsList} />
            }
            <MaterialTable
            title={selectedActor ? selectedActor.corporate_name+" - "+dic.PARTNERS : dic.PARTNERS}
            icons={tableIcons}
            columns={parameters}
            isLoading={fetchingData}
            data={partnersList}  
            options={{actionsColumnIndex: -1, exportButton: true, exportAllData: true}}
            localization={dicTypes._TABLES_LOCALIZATION_}
            editable={{
                onRowAdd: newData => {
                    return new Promise((resolve, reject) => {
                        if (actor.actor_type === 'admin')
                            newData.actor_id = actorId
                        if (!newData.surname || !newData.name)
                            return reject();
                        
                        partnerServices.partnerInsert(newData)
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                            })
                    })
                },
                onRowUpdate: (newData, oldData) => {
                    return new Promise((resolve, reject) => {
                        if (actor.actor_type === 'admin')
                            newData.actor_id = actorId
                        if (!newData.surname || !newData.name)
                            return reject();
                        newData.id = oldData.id
                        partnerServices.partnerUpdate(newData)
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                        })
                    })
                },
                onRowDelete: oldData =>{
                    return new Promise((resolve, reject) => {
                        partnerServices.partnerDelete({id: oldData.id, actor_id: oldData.actor_id})
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                        })
                    })
                },
            }}
            />
        </div>
    )
}
let PartnersTableConnected = connect(state => ({userData: state.user}))(PartnersTable)
export default (PartnersTableConnected)