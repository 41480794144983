import { statementsConstants } from '../../_constants'

export function statements(state = {
  accounting: {},
    expenses: {
      formPattern: [],
      sections: []
    },
    shares: {}
  }, action) {
  switch(action.type) {
    case statementsConstants.CREATE_EXPENSES_FORM:
      return {
        ...state,
        expenses: {
          formPattern: action.formPattern,
          sections: action.sections
        }
      }
    case statementsConstants.CLEAR:
      return null
    default:
      return state
  }
}