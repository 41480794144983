import React from 'react';
import { Grid } from '@material-ui/core';

import SurchargesTable from './settings/SurchargesTable'
import ShipTypesTable from './settings/ShipTypesTable'
import SheetHeadersTable from './settings/SheetHeadersTable'
import SheetItemsTable from './settings/SheetItemsTable'

export default function SettingsTable(props) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          {props.match.path === '/settings/surcharges' ?
            <SurchargesTable />
          : null}
          {props.match.path === '/settings/ship_types' ?
            <ShipTypesTable />
          : null}
          {props.match.path === '/settings/sheet_headers' ?
            <SheetHeadersTable />
          : null}
          {props.match.path === '/settings/sheet_items' ?
            <SheetItemsTable />
          : null}
        </Grid>
      </Grid>
    )
}