import { setCall, handleResponse } from '../_helpers';

export const shipTypesServices = { shipTypesFetchAll, shipTypeInsert, shipTypeUpdate, shipTypeDelete }

async function shipTypesFetchAll() {
  let requestOptions = {
    method: 'POST',
    body: setCall('ship_types', 'get_ship_types', {}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function shipTypeInsert(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('ship_types', 'ship_type_insert', data),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function shipTypeUpdate(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('ship_types', 'ship_type_update', data),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function shipTypeDelete(keys) {
  const requestOptions = {
    method: 'POST',
    body: setCall('ship_types', 'ship_type_remove', keys),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}