import React from 'react';
import { Grid } from '@material-ui/core';

import ActorsTable from './actors/Table'
import ActorForm from './actors/Form';

export default function Actors(props) {
  if (props.match.params.id === undefined) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          <ActorsTable />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className="root" justifyContent="center">
      <Grid item xs={11} sm={8}>
        <ActorForm actorId={props.match.params.id} />
      </Grid>
    </Grid>
  );
}