import React, { forwardRef, useEffect, useState, Fragment } from "react"
import { history } from '../../_helpers/history'
import { connect } from 'react-redux'

// Material UI components
import { Grid, Chip } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table'
import { useSnackbar  } from 'notistack';

// Material UI icons
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Tune,
  Search,
  ViewColumn,
  PictureAsPdf,
  GridOn,
  Business,
  DateRange
}  from '@material-ui/icons';

// Personal components
import FilterDialog from "./FilterDialog"
import PDFViewer from "./PDFViewer"
import XLSXViewer from "./XLSXViewer"

import { updateConfiguration } from '../../_redux/_actions'
import { turnoversServices } from '../../_services'
import { dic, dicTypes } from '../../_constants';

const TurnoversTable = (props) => {
  const { userData, updateConfiguration, actors } = props;
  const { enqueueSnackbar } = useSnackbar()

  const selected = userData.configuration.turnovers;
  const setSelected = (data) => { updateConfiguration(data, 'turnovers')};

  const [dataList, setDataList] = useState([])
  const [loading, setLoading] = useState(false)
  
  const [init, setInit] = useState(true)
  const [openDialog, setOpenDialog] = useState(false)
  const [openDialogXlsx, setOpenDialogXlsx] = useState(false)
  const [openFilterDialog, setOpenFilterDialog] = useState(false)
  const [pdfData, setPdfData] = useState(false)
  const [xlsxData, setXlsxData] = useState(false)
  const [startSearch, setStartSearch] = useState(false)

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
  
  const parameters = [
    { title: dic.YEAR, field: 'year', type: "numeric", cellStyle: {width: 50, minWidth: 50, maxWidth: 50}, headerStyle: {width:50, minWidth: 50, maxWidth: 50}},
    { title: dic.MONTH, field: 'month', type: "numeric",cellStyle: {width: 50, minWidth: 50, maxWidth: 50}, headerStyle: {width:50, minWidth: 50, maxWidth: 50}},
    { title: dic.CORPORATE_NAME, field: 'corporate_name', cellStyle: {color: "#1E7DA5", width: 1024, minWidth: 450}, headerStyle: {width:1024, minWidth: 450}},
    { title: dic.LAST_UPDATE, field: 'timestamp', type: 'date', cellStyle: {width: 50, minWidth: 50}, headerStyle: {width:50, minWidth: 50}},
    { title: dic.CREATION_DATE, field: 'creation_date', type: 'date', cellStyle: {width: 50, minWidth: 50}, headerStyle: {width:50, minWidth: 50}}
  ]
  
  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      await turnoversServices.turnoversParamSearch(
        selected.actor_id?actors[selected.actor_id].id:null,
        {
          month: selected.month.from,
          year: selected.year.from
        },
        {
          month: selected.month.to,
          year: selected.year.to
        }
      )
      .then(resp => {
        setDataList(resp.response.details)
      })
      setLoading(false)
    }

    if (init) {
      getData()
      setInit(false)
    } else {
      getData()
      setStartSearch(false)
    }

  }, [startSearch])

  const setSelectedDate = (type, value) => {
    let transit = {...selected}
    transit.year[type] = value.getFullYear()
    transit.month[type] = value.getMonth() + 1
    setSelected(transit)
  }

  return (
    <Fragment>
      <PDFViewer
        open = { openDialog }
        setOpen = { setOpenDialog }
        data = { pdfData }
      />

      <XLSXViewer
        open = { openDialogXlsx }
        setOpen = { setOpenDialogXlsx }
        data = { xlsxData }
      />

      <FilterDialog
        open = { openFilterDialog }
        setOpen = { setOpenFilterDialog }
        selected = { selected }
        setSelectedDate = { setSelectedDate }
        setSelected = { setSelected }
        actors = { actors }
        userData = { props.userData }
        setSearch = { (val) => setStartSearch(val) }
      />
      
      <MaterialTable
        title={dic.TURNOVERS}
        icons={tableIcons}
        columns={parameters}
        data={dataList}
        isLoading={loading}
        options={{
          actionsColumnIndex: -1
        }}
        actions={[
          {
            icon: AddBox,
            tooltip: dic.CREATE,
            isFreeAction: true,
            onClick: () => history.push('/turnover/new')
          },
          {
            icon: Tune,
            tooltip: dic.FILTER,
            isFreeAction: true,
            onClick: () => setOpenFilterDialog(true)
          },
          {
            icon: Edit,
            tooltip: dic.EDIT,
            onClick: (event, rowData) => history.push('/turnover/' + rowData.id + '/' + rowData.year + '/' + rowData.month + '/edit')
          },
          {
            icon: PictureAsPdf,
            tooltip: dic.PDF,
            onClick: (event, rowData) => {
              setPdfData(rowData)
              setOpenDialog(true)
            }
          },
          {
            icon: GridOn,
            tooltip: dic.XLSX,
            onClick: (event, rowData) => {
              setXlsxData(rowData)
              setOpenDialogXlsx(true)
            }
          }
        ]}
        
        onRowClick={(
          (event, rowData) => {
            history.push('/turnover/' + rowData.id + '/' + rowData.year + '/' + rowData.month + '/edit')
          }
        )}
        localization={dicTypes._TABLES_LOCALIZATION_}
        editable={{
          onRowDelete: oldData =>{
            return new Promise((resolve, reject) => {
              turnoversServices.turnoversDelete(oldData.actor_id, oldData.month, oldData.year)
              .then(resp => {
                setStartSearch(true)
                enqueueSnackbar(dic.TURNOVER_DELETED, { variant: 'success', persist: false, })
                return resolve()
              })
            })
          },
        }}
        components={{
          Toolbar: props => (
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <MTableToolbar {...props} />
              </Grid>
              <Grid item xs={12} style={{textAlign: "left", paddingLeft: 30}}>
                  {userData.actor.actor_type === "admin" &&
                  <Chip
                    icon={<Business />}
                    label={actors[selected.actor_id]? (selected.actor_id < 1 ? dic.ALL : actors[selected.actor_id].corporate_name):null}
                    onClick={() => setOpenFilterDialog(true)}
                  />
                  }
                  <Chip
                    icon={<DateRange />}
                    label={dic.MONTHS[parseInt(selected.month.from)]+" "+selected.year.from+" / "+dic.MONTHS[parseInt(selected.month.to)]+" "+selected.year.to}
                    onClick={() => setOpenFilterDialog(true)}
                  />
              </Grid>
            </Grid>
          )
        }}
      />
    </Fragment>
  )
}

let TurnoversTableConnected = connect(
  state => ({
    userData: state.user,
    actors: state.actors
  }),{updateConfiguration}
)(TurnoversTable)
export default TurnoversTableConnected