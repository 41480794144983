import React, { forwardRef, useEffect, useState }  from 'react';
import {connect} from 'react-redux'
import { history } from '../../../_helpers/history'

import MaterialTable from 'material-table'

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
}  from '@material-ui/icons';

import { actorServices } from '../../../_services/actors.services'
import { harbourServices } from '../../../_services/harbours.services'
import { gross_tonnagesServices } from '../../../_services/gross_tonnages.services'
import { dic, dicTypes } from '../../../_constants/dictionary.constants'

import { useSnackbar  } from 'notistack';
import ActorsBreadcrumbs from '../../../_components/utils/ActorsBreadcrumbs'



function GrossTonnagesTable(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [fetchingData, setFetchingData] = useState(false)
    const [selectedActor, setSelectedActor] = useState(false);
    const [selectedHarbour, setSelectedHarbour] = useState(false);
    const [grossTonnagesList, setGrossTonnagesList] = useState([])

    const { actorId } = props;
    const { harbourId } = props;
    const { actor } = props.userData;

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };
      
    const parameters = [
    { title: dic.VALID_FROM_DATE, field: 'start_date', type: "date", cellStyle: {color: "#1E7DA5"}},
    { title: dic.TO_DATE, field: 'end_date', type: "date", cellStyle: {color: "#1E7DA5"}},
    ]
    
    const responseNotification = (data) => {
        if (data.header.result === 'ok'){
            enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
            getGrossTonnages();
        }
        else{
            for (var i in data.response.details)
                enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
                setFetchingData(false)
        }
    }

    const responseTextNotification = (response) => {
        switch (response.header.function){
            case 'gross_tonnage_insert':
                return dic.GROSS_TONNAGE_INSERTED
            case 'gross_tonnage_update':
                return dic.GROSS_TONNAGE_UPDATED
            case 'gross_tonnage_remove':
                return dic.GROSS_TONNAGE_DELETED
            default:
                return
        }
    }
    const getGrossTonnages = () => {
        setFetchingData(true)
        gross_tonnagesServices.grossTonnageSearch(actorId, harbourId)
            .then(resp => {
                setGrossTonnagesList(resp.response.details)
                setFetchingData(false)
            })
    }

    const getActor = () => {
        actorServices.actorFetch({actor_id: actorId})
            .then(resp => {
            setSelectedActor(resp.response.details)
        })
    }

    const getHarbour = () => {
        harbourServices.harbourFetch({harbour_id: harbourId})
            .then(resp => {
            setSelectedHarbour(resp.response.details)
        })
    }

    useEffect(() => {
        if (actor.actor_type === 'admin' && !selectedActor)
            getActor();
        
        getHarbour();
        getGrossTonnages();
    }, [])

    const breadCrumbsList =  actor.actor_type === 'admin' ? {
        actors : {label: dic.ACTORS, url: '/actors'},
        harbours : {label: dic.HARBOURS, url: '/actors/'+actorId+'/harbours', selected: selectedActor.corporate_name},
        gross_tonnages : {label: dic.GROSS_TONNAGES, url: '/actors/'+actorId+'/harbours/'+harbourId+'/gross_tonnages', selected: selectedHarbour.name, actual: true}
    } :
    {
        harbours : {label: dic.HARBOURS, url: '/actors/'+actorId+'/harbours', selected: selectedActor.corporate_name},
        gross_tonnages : {label: dic.GROSS_TONNAGES, url: '/actors/'+actorId+'/harbours/'+harbourId+'/gross_tonnages', selected: selectedHarbour.name, actual: true}
    }


    return (
        <div>
            <ActorsBreadcrumbs breadCrumbsList={breadCrumbsList} />
            <MaterialTable
            title={selectedActor ? selectedActor.corporate_name+" - "+selectedHarbour.name+" - "+dic.GROSS_TONNAGES : selectedHarbour.name+" - "+dic.GROSS_TONNAGES}
            icons={tableIcons}
            columns={parameters}
            data={grossTonnagesList}
            isLoading={fetchingData}
            options={{actionsColumnIndex: -1, exportButton: true, exportAllData: true}}
            localization={dicTypes._TABLES_LOCALIZATION_}
            actions={[
                {
                icon: AddBox,
                tooltip: dic.CREATE,
                isFreeAction: true,
                onClick: () => history.push('/actors/'+ actorId + '/harbours/'+ harbourId + '/gross_tonnages/new')
                },
                {
                    icon: Edit,
                    tooltip: dic.EDIT,
                    onClick: (event, rowData) => history.push('/actors/'+ actorId + '/harbours/' + harbourId +  '/gross_tonnages/' + rowData.id)
                },
            ]}
            onRowClick={(
                (event, rowData) => {
                history.push('/actors/'+ actorId + '/harbours/' + harbourId +  '/gross_tonnages/' + rowData.id)
                }
            )}
            editable={{
                onRowDelete: oldData =>{
                    return new Promise((resolve, reject) => {
                        gross_tonnagesServices.grossTonnageDelete({gross_tonnage_id: oldData.id, actor_id: oldData.actor_id})
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                        })
                    })
                },
            }}
            />
        </div>
    )
}
let GrossTonnagesTableConnected = connect(state => ({userData: state.user}))(GrossTonnagesTable)
export default (GrossTonnagesTableConnected)