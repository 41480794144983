import { actors_customersServices } from '../../_services'
import { customersConstants } from '../../_constants'

export function createCustomersList(actorId) {
  return function(dispatch) {
    return new Promise((resolve) => {
      actors_customersServices.customersFetchAll({actor_type: "pilots", ...actorId && {actor_id: actorId}})
        .then(resp => {
          if (resp.header.result === 'ok') {
            dispatch({
              type: customersConstants.CREATE_CUSTOMERS_LIST,
              list: [...resp.response.details]
            }) 
          } else {
            // error
            console.error(resp)
          }
        })
        resolve();
    })
  }
}

export function initCustomersList(){
  return function(dispatch) {
    dispatch({
      type: customersConstants.INIT_CUSTOMERS_LIST,
      list: []
    })
  }
}

export function addNewCustomer(data) {
  return function(dispatch) {
    dispatch({
      type: customersConstants.ADD_NEW_CUSTOMER,
      data: data.customer_id?data: null
    })
  }
}

export function updateCustomer(data) {
  return function(dispatch) {
    dispatch({
      type: customersConstants.UPDATE_CUSTOMER,
      data
    })
  }
}

export function deleteCustomer(data) {
  return function(dispatch) {
    dispatch({
      type: customersConstants.DELETE_CUSTOMER,
      data
    })
  }
}