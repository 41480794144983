import React, { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';

// Material UI components
import { Grid, Tooltip, Slider, AppBar, Toolbar, Dialog, Slide, IconButton, DialogContent, } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// Material UI Icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';

import { dic } from '../../_constants/dictionary.constants';

// Personal components
import Loading from '../utils/Loading'

// Personal functions
import { statementsServices } from '../../_services/statements.services'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const StatementPdf = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const { data, open, setOpen  } = props
  const keys = data
  const [loading, setLoading] = useState(false)
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.5);
  const [pdf, setPdf] = useState(null)
  
  
  const onDocumentLoadSuccess = ({ numPages }) => {
    setLoading(false)
    setNumPages(numPages);
  }

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1);
  }

  const nextPage = () => {
    changePage(1);
  }

  const downloadPdf = () => {
    let link = document.createElement('a');
    link.href = pdf;
    link.download= data.corporate_name+" "+data.month+"-"+data.year+".pdf";
    link.click();
  }

  const goToEdit = () => {
    props.history.push('/accounting_statements/' + keys.id + "/" + keys.year + "/" + keys.month + "/edit")
  }
  const handleScaleChange = (event, newValue) => {
    setScale(newValue);
  };
  useEffect(() => {
    const getPdf = () => {
      setLoading(true)
      statementsServices.statementsPdf(keys.id, keys.year, keys.month)
      .then(resp => {
        const fileURL = URL.createObjectURL(new Blob([resp], {type: "application/pdf"}))
  
        setPdf(fileURL)
        setLoading(false)
      })
    }
    if (keys && open)
      getPdf()
  }, [keys, open]);

  const handleClose = () => {
    setPdf(null)
    setLoading(false)
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="xl"
      >
      {
        loading ?
        null
        : (
          <AppBar position="fixed" color="primary" style={{top: 0}}>
            <Toolbar style={{backgroundColor: "grey"}}>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item style={{width: '100px'}}>
                  <Slider 
                    value={scale}
                    onChange={handleScaleChange}
                    step={0.5}
                    min={1}
                    max={2.5}
                    aria-labelledby="continuous-slider" 
                  />
                </Grid>
                <Grid item>
                  <IconButton disabled={pageNumber <= 1} onClick={previousPage} style={{color: "white"}}>
                    <ChevronLeftIcon />
                  </IconButton>
                </Grid>
                <Grid item style={{color: "white"}}>
                  Page {pageNumber || (numPages ? 1 : '0')} of {numPages || '0'}
                </Grid>
                <Grid item>
                  <IconButton disabled={pageNumber >= numPages} onClick={nextPage} style={{color: "white"}}>
                    <ChevronRightIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Tooltip title={dic.UPDATE_STATEMENT} placement="top" arrow>
                    <IconButton onClick={() => goToEdit()} style={{color: "white"}}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={dic.PDF} placement="top" arrow>
                    <IconButton onClick={downloadPdf} style={{color: "white"}}>
                        <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
        )
      }
      <div style={{width: "100%"}}>
        <Loading open={loading} message={dic.LOADING} />
        <DialogContent>
          {keys ?  
            <Document
              renderMode="svg"
              file={pdf}
              onLoadSuccess={onDocumentLoadSuccess}
              
            >
              <Page pageNumber={pageNumber} scale={scale} />
            </Document>
            : null}
        </DialogContent>
      </div>
      
    </Dialog>
  )
}

export default StatementPdf