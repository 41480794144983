import React, { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import itLocale from "date-fns/locale/it";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// Material UI components
import { Card, CardHeader, CardContent, Grid, Button, CardActions } from '@material-ui/core';

import { GridOn } from '@material-ui/icons';

import { dic } from '../../_constants'
import XLSXExportViewer from './XLSXExportViewer'

const TurnoverBySemesters = () => {
  const defaultDateParams = new Date();
  const defaultYear = defaultDateParams.getFullYear();
  
  const [dates, setDates] = useState({
      from: (parseInt(defaultYear)-1)+'-07-01',
      to: defaultYear+'-06-30'
  });

  const [searchParams, setSearchParams] = useState({
      from: {year: (parseInt(defaultYear)-1), month: '07'},
      to: {year: defaultYear, month: '06'}
  })

  const [openExportViewer, setOpenExportViewer] = useState(null)


  const setSelectedDate = (value) => {
      const newFromDate = value.getFullYear()+"-"+String(value.getMonth()+1).padStart(2, '0')+'-01';
      const newToDate = (value.getFullYear()+1)+'-06-30'
      
      setSearchParams({
        from: {year: value.getFullYear(), month: '07'},
        to: {year: (value.getFullYear()+1), month: '06'}
      })
      setDates({from: newFromDate, to: newToDate})
  }
  
  return (
    <Card>
      <CardHeader title="Fatturato Per Semestri"></CardHeader>
        <CardContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                  <DatePicker
                    label={dic.FROM+" II Sem."}
                    variant="inline"
                    inputVariant="outlined"
                    openTo="year"
                    views={["year"]}
                    value={dates.from}
                    onChange={setSelectedDate}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <DatePicker
                  label={dic.TO+" I Sem."}
                  variant="inline"
                  inputVariant="outlined"
                  openTo="year"
                  views={["year"]}
                  value={dates.to}
                  autoOk
                  disabled
                />
              </MuiPickersUtilsProvider>
            </Grid>
        </Grid>
      </CardContent>
      <CardActions>
          <Grid container spacing={2} justifyContent="center" style={{padding: 30}}>
            <Grid item xs={12} sm={12}>
              <Button startIcon={<GridOn />} onClick={() => setOpenExportViewer(true)} variant="outlined" color="primary">
                {dic.XLSX}
              </Button>
              <XLSXExportViewer 
                  searchParams={searchParams}
                  title={"Fatturato per Semestri "+searchParams.from.year+" - "+searchParams.to.year}
                  open={openExportViewer}
                  setOpen={setOpenExportViewer}
                  functionRequired='getTurnoverBySemesters'
              />
            </Grid>
          </Grid>
      </CardActions>
    </Card>
  )
}

export default TurnoverBySemesters