import { history } from '../_helpers';

// funzione che controlla se si ha accesso o meno in funzione di una qualunque risposta della api
export function accessDenied(data){
    if (data.header.result === 'denied')
        return true
    return false
}

export function errorsDectected(data){
    if (data.header.result === 'error')
        return true
    return false
}

export function datetimeToDate(datetime){
    var splitted = datetime.split(" "); 
    return splitted[0];
}

export function datetimeToTime(datetime){
    var splitted = datetime.split(" "); 
    return splitted[1].substring(0,5);
}

export function timeNow(){
    var now = new Date()
	var hours = now.getHours()
	var minutes = now.getMinutes()
	var timeStr = ((hours < 10) ? "0" : "") + hours
	timeStr += ((minutes < 10) ? ":0" : ":") + minutes
    return timeStr;
}

export function dateNow(){
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export function formatTemperature(num){
    return (Math.round(num * 100) / 100).toFixed(1);
}

// funzione che controlla se una stringa è json o meno
export function isJSON(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

// funzione che gestisce tutte le risposte delle call all'API
export function handleResponse(response, errorsCallback, type = null) {
    if (response.status === 200){
        if (type !== null) {
            return response.blob()
        }
        return response.text().then(text => {
            if (isJSON(text)){
                const data = text && JSON.parse(text);
                if(data.php_errors && data.php_errors !== '')
                    console.log(data.php_errors)
                
                if (accessDenied(data)){
                    // console.log('ho rilevato un accesso negato...')
                    history.push('/logout');
                }
                
                if (errorsDectected(data)){
                    if (errorsCallback) {
                        errorsCallback(data)
                    } else {
                        return data;
                    }
                }
                else
                    return data;
            }
            else{
                console.log('We have a connection problem');
                return {header: {result: 'connection'}, response: {details: {}}};
            }
        });
    }
    else{
        return response.text().then(text => {
            console.log('TEXT: '+ text);
        })
        .catch(error => {
            console.log('CATCH: '+error);
        });
    }
}

export function setCall(req_library, req_function, req_query, req_type = null){
    let initStruct = {
        header: {
            library: req_library,
            function: req_function
        },
        query: req_query
    }

    if (req_type) initStruct.header.type = req_type

    return JSON.stringify(initStruct);
}