import React, { forwardRef, useEffect, useState }  from 'react';

import MaterialTable from 'material-table'

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
}  from '@material-ui/icons';

import { sheetItemsServices } from '../../_services/sheet_items.services'
import { sheetHeadersServices } from '../../_services/sheet_headers.services'
import { dic, dicTypes } from '../../_constants/dictionary.constants'

import { useSnackbar  } from 'notistack';



function SheetItemsTable() {
    const { enqueueSnackbar } = useSnackbar();
    const [fetchingData, setFetchingData] = useState(false)
    const [sheetItemsList, setsheetItemsList] = useState([])
    const [sheetHeadersList, setsheetHeadersList] = useState({})

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };
      
    const parameters = [
        { title: dic.ID, field: 'id', editable: "never"},
        { title: dic.NAME, field: 'expense_sheet_header_id', lookup: sheetHeadersList, validate: rowData => rowData.expense_sheet_header_id !== '' && rowData.expense_sheet_header_id !== undefined},
        //{ title: "", field: 'subheader_order_field', validate: rowData => rowData.subheader_order_field !== '' && rowData.subheader_order_field !== undefined},
        { title: dic.ORDER_FIELD, field: 'order_field', validate: rowData => rowData.order_field !== '' && rowData.order_field !== undefined, cellStyle: {color: "#1E7DA5"}},
        { title: dic.NAME, field: 'name', validate: rowData => rowData.name !== '' && rowData.name !== undefined, cellStyle: {color: "#1E7DA5", width: 1024, minWidth: 450}, headerStyle: {width:1024, minWidth: 450}}
      ]

    const responseNotification = (data) => {
        if (data.header.result === 'ok'){
            enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
            getsheetItems();
        }
        else{
            for (var i in data.response.details)
                enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
            setFetchingData(false)
        }
    }

    const responseTextNotification = (response) => {
        switch (response.header.function){
            case 'expense_sheet_item_insert':
                return dic.SHEET_ITEM_INSERTED
            case 'expense_sheet_item_update':
                return dic.SHEET_ITEM_UPDATED
            case 'expense_sheet_item_remove':
                return dic.SHEET_ITEM_DELETED
            default:
                return
        }
    }

    const sheetHeadersLookup = (sheetHeaders) => {
        var transit = {};
        for (var i in sheetHeaders)
          transit[sheetHeaders[i]["id"]] = sheetHeaders[i]["order_field"]+". "+sheetHeaders[i]["name"];
        
        return transit;
      }

    const getsheetItems = () => {
        setFetchingData(true)
        sheetItemsServices.sheetItemsFetchAll()
            .then(resp => {
                setsheetItemsList(resp.response.details)
                setFetchingData(false)
            })
    }

    const getsheetHeadersAndItems = () => {
        setFetchingData(true)
        sheetHeadersServices.sheetHeadersFetchAll()
            .then(resp => {
                setsheetHeadersList(sheetHeadersLookup(resp.response.details))
                getsheetItems();
        })
    }

    useEffect(() => {
        getsheetHeadersAndItems();
    }, [])

    return (
            <MaterialTable
            title={dic.SHEET_ITEMS}
            icons={tableIcons}
            columns={parameters}
            isLoading={fetchingData}
            data={sheetItemsList}  
            options={{actionsColumnIndex: -1, exportButton: true, exportAllData: true, filtering: true}}
            localization={dicTypes._TABLES_LOCALIZATION_}
            editable={{
                onRowAdd: newData => {
                    return new Promise((resolve, reject) => {
                        if (!newData.name || !newData.order_field|| !newData.expense_sheet_header_id)
                            return reject();
                        
                        sheetItemsServices.sheetItemInsert(newData)
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                            })
                    })
                },
                onRowUpdate: (newData, oldData) => {
                    return new Promise((resolve, reject) => {
                        if (!newData.name || !newData.order_field)
                            return reject();
                        newData.expense_sheet_item_id = oldData.id
                        sheetItemsServices.sheetItemUpdate(newData)
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                        })
                    })
                },
                onRowDelete: oldData =>{
                    return new Promise((resolve, reject) => {
                        sheetItemsServices.sheetItemDelete({expense_sheet_item_id: oldData.id})
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                        })
                    })
                },
            }}
            />
    )
}
export default (SheetItemsTable)