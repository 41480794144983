import { setCall, handleResponse } from '../_helpers';

export const actorServices = {
  actorsFetchAll, actorFetch, actorInsert, actorUpdate, actorDelete
}

async function actorsFetchAll(searchParams) {
  const requestOptions = {
    method: 'POST',
    body: setCall('actors', 'actors_search', !searchParams ? {} : searchParams),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function actorFetch(data) {
  const requestOptions = {
      method: 'POST',
      body: setCall('actors', 'get_actor_data', data),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function actorInsert(data) {
  const requestOptions = {
      method: 'POST',
      body: setCall('actors', 'actor_insert', data),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)
  
  return json;
}

async function actorUpdate(data) {
  delete data.id
  
  const requestOptions = {
      method: 'POST',
      body: setCall('actors', 'actor_update', data),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function actorDelete(data) {
  const requestOptions = {
      method: 'POST',
      body: setCall('actors', 'actor_remove', { actor_id: data}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)
  
  return json;
}
