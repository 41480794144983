import { setCall, handleResponse } from '../_helpers';

export const exportsServices = {
    getTurnoverBySemesters
}


async function getTurnoverBySemesters(searchParams) {
  let requestOptions = {
      method: 'POST',
      body: setCall('exports', 'get_turnover_by_semesters', searchParams, "application/xlsx"),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"xlsx")

  return json;
}
