import { setCall, handleResponse } from '../_helpers';

export const surchargesServices = { surchargesFetchAll, surchargeInsert, surchargeUpdate, surchargeDelete }

async function surchargesFetchAll() {
  let requestOptions = {
    method: 'POST',
    body: setCall('surcharges', 'get_surcharges', {}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function surchargeInsert(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('surcharges', 'surcharge_insert', data),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function surchargeUpdate(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('surcharges', 'surcharge_update', data),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function surchargeDelete(keys) {
  const requestOptions = {
    method: 'POST',
    body: setCall('surcharges', 'surcharge_remove', keys),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}