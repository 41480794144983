import React from 'react';
import { Grid } from '@material-ui/core';

import PartnersTable from './partners/Table'

export default function Partners(props) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          <PartnersTable actorId={props.match.params.id ? props.match.params.id : null}/>
        </Grid>
      </Grid>
    )
}