import React, { Fragment, useEffect, useState } from 'react'
/*eslint eqeqeq: "off"*/

import { connect } from 'react-redux'

// Material UI components
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CardActions
} from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useSnackbar } from 'notistack';

// Personal components
import LoadingDialog from '../utils/Loading'
import UserDataForm from './formComponents/UserDataForm'
import ShipDataForm from './formComponents/ShipDataForm'

import { checkLocalStorage, getPreviousMonth, handleExcelTurnover, resetStorage } from '../../_helpers';
import { history } from '../../_helpers/history'
import { addNewCustomer, updateCustomer, createCustomersList } from '../../_redux/_actions';

// Dictionary
import { dic } from '../../_constants'
import { turnoversServices, actors_customersServices } from '../../_services';

const TurnoversForm = (props) => {
  const [turnoverData, setTurnoverData] = useState({})
  const [shipData, setShipData] = useState([])
  const [creditData, setCreditData] = useState([])

  const [sortedCustomerList, setSortedCustomerList] = useState([])

  // Excel
  const [excelDialog, setExcelDialog] = useState(false)
  const [isExcel, setIsExcel] = useState(false)
  const [excelData, setExcelData] = useState(null)

   // Loading hook
   const [isLoading, setIsLoading] = useState({
    global: false,
    components: {
      turnover: false,
      ships: false,
      credits: false
    }
  })

  // Misc
  const [hasSelectedCustomer, setHasSelectedCustomer] = useState(false)
  const [init, setInit] = useState(true)
  const [prevParam, setPrevParam] = useState(null)
  const [renderFlag, setRenderFlag] = useState(0)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  /**
   * Constants outside of hooks
   */
  // Change of text depending on the type of action
  const subtitle = props.match.params.id==="new"?dic.CREATE_TURNOVER:dic.UPDATE_TURNOVER
  const actionButton = props.match.params.id==="new"?dic.CREATE_TURNOVER:dic.UPDATE_TURNOVER

  const createTurnoverFormData = (type = "init", data = null) => {
    let transitForm = {
      year: getPreviousMonth().year,
      month: (getPreviousMonth().month + 1) < 10 ? "0" + (getPreviousMonth().month + 1) : (getPreviousMonth().month + 1),
      actor_id: props.match.params.id==="new" ?  props.userData.actor.actor_type === "admin" ? 0 : props.userData.actor.id : props.match.params.id
    }

    switch (type) {
      case "init":
        break
      case "edit":
        if (data) {
          transitForm = {
            year: Number(data.year),
            month: Number(data.month) < 10 ? "0" + Number(data.month) : Number(data.month),
            actor_id: Number(data.actor_id)
          }
        }
        break
      default:
        break
    }

    setTurnoverData(transitForm)
  }
  const createShipFormdata = (type = "init", data = null) => {
    let transitForm = []

    for (let i = 0 ; i < props.ships.list.length ; i++) {
      transitForm.push({
        id: props.ships.list[i].id,
        entries: []
      })
    }

    switch (type) {
      case "init":
        for (let i = 0 ; i < transitForm.length ; i++) {
          transitForm[i].entries.push({
            ship_type_id: props.ships.list[i].id,
            customer_id: 0,
            corporate_name: "",
            amount: 0,
            discount_amount: 0,
            second_pilot_amount: 0,
            miles_amount: 0,
            services: 0,
            second_pilot_services: 0
          })
        }
        break
      case "edit":
        for (let i = 0 ; i < data.length ; i ++) {
          for (let j = 0 ; j < transitForm.length ; j++) {
            if (transitForm[j].id == data[i].ship_type_id) {
              transitForm[j].entries.push({
                ship_type_id: Number(data[i].ship_type_id),
                customer_id:  data[i].customer_id,
                corporate_name: data[i].corporate_name,
                amount:  Number(data[i].amount),
                discount_amount:  Number(data[i].discount_amount),
                second_pilot_amount:  Number(data[i].second_pilot_amount),
                miles_amount:  Number(data[i].miles_amount),
                services:  Number(data[i].services),
                second_pilot_services:  Number(data[i].second_pilot_services)
              })
            }
          }
        }

        for (let i = 0 ; i < transitForm.length ; i++) {
          if (transitForm[i].entries.length === 0) {
            transitForm[i].entries.push({
              ship_type_id: props.ships.list[i].id,
              customer_id: 0,
              corporate_name: "",
              amount: 0,
              discount_amount: 0,
              second_pilot_amount: 0,
              miles_amount: 0,
              services: 0,
              second_pilot_services: 0
            })
          }
        }
        break
      default:
        break
    }
    
    setShipData(transitForm)
  }
  const createCreditFormdata = (type = "init", data = null) => {
    let transitForm = []

    switch (type) {
      case "init":
        transitForm = [
          {
            customer_id: 0,
            corporate_name: "",
            amount: 0
          }
        ]
        break
      case "edit":
        for (let i = 0 ; i < data.length ; i++) {
          transitForm.push({
            customer_id: data[i].customer_id,
            corporate_name: data[i].corporate_name,
            amount: Number(data[i].amount)
          })
        }
        break
      default:
        break
    }

    setCreditData(transitForm)
  }

  useEffect(() => {
    const checkPathParameters = () => {
      if (props.userData.actor.actor_type !== "admin" && props.match.params.id !== "new" && props.match.params.id !== props.userData.actor.id) {
        history.push("/turnover/all")
        return false
      }

      return true
    }
    const checkInit = () => {
      if (props.match.params !== prevParam) {
        setPrevParam(props.match.params)
        return true
      } 
      return init
    }

    const filterCustomers = () => {
      if (turnoverData.actor_id === 0) {
        setSortedCustomerList([{customer_id: 0, corporate_name: dic.ACTOR_SELECT}])
      } else {
        const result = [...props.customers].filter(customer => customer.actor_id == turnoverData.actor_id)
        setSortedCustomerList(result)
      }
    }

    const initUpdate = async () => {
      await turnoversServices.turnoversSearch(
        props.match.params.id,
        props.match.params.month,
        props.match.params.year,
      ).then(resp => {
        if (resp.header.result === 'ok') {
          const data = resp.response.details

          createTurnoverFormData("edit", data)
          createShipFormdata("edit", data.turnover_details)
          createCreditFormdata("edit", data.credit_notes)
        } else {
          responseNotification(resp)
        }
      })
    }
    const updateCustomers = async (list = []) => {
      return await actors_customersServices.customersUpdate(list)
        .then(resp => {
          const sorted = {
            insert: {
              success: [],
              error: []
            },
            update: {
              success: [],
              error: []
            },
          }

          if (resp.header.result === 'ok') {
            const data = resp.response.details

            for (let entry in data.insert) {
              if (data.insert[entry][0]==="ok") {
                sorted.insert.success.push({data: list.find(elem => elem.id === entry)})
              } else {
                sorted.insert.error.push({data: list.find(elem => elem.id === entry)})
              }
            }
            for (let entry in data.update) {
              if (data.update[entry][0]==="ok") {
                sorted.update.success.push({data: list.find(elem => elem.id === entry)})
              } else {
                sorted.update.error.push({data: list.find(elem => elem.id === entry)})
              }
            }

            return sorted
          } else {
            responseNotification(resp)
            return false
          }
        })
    }

    if (checkPathParameters() && checkInit()) {
      setIsExcel(false)

      if (props.match.params.id === "new") {
        const localStorageData = checkLocalStorage("turnover")

        localStorageData.turnoverData ? setTurnoverData(localStorageData.turnoverData) : createTurnoverFormData()
        localStorageData.shipsData ? setShipData(localStorageData.shipsData) : createShipFormdata()
        localStorageData.creditsData ? setCreditData(localStorageData.creditsData) : createCreditFormdata()

        filterCustomers()
      } else {
        initUpdate()
        filterCustomers()
      }

      setPrevParam(props.match.params)
      setInit(false)
    } else {
      // Update what needs to be updated
      if (isExcel && excelData) {
        updateCustomers(excelData.update_customers).then(resp => {
          if (!resp) {
            console.error("error!")
          } else if (resp.insert.error.length > 0 ||resp.update.error.length > 0) {
            enqueueSnackbar(dic.EXCEL_ERROR, { variant: 'error', persist: false, })
            enqueueSnackbar(resp.insert.success.length + " " + dic.CUSTOMER_CREATION_SUCCESS, { variant: 'info', persist: false, })
            enqueueSnackbar(resp.update.success.length + " " + dic.CUSTOMER_UPDATE_SUCCESS, { variant: 'info', persist: false, })
            enqueueSnackbar(resp.insert.error.length + " " + dic.CUSTOMER_CREATION_FAIL, { variant: 'error', persist: false, })
            enqueueSnackbar(resp.update.error.length + " " + dic.CUSTOMER_UPDATE_SUCCESS, { variant: 'error', persist: false, })
          } else {
            for (let i = 0 ; i < resp.insert.success ; i++) {
              props.addNewCustomer(resp.inster.success[i])
            }

            for (let i = 0 ; i < resp.update.success ; i++) {
              props.updateCustomer(resp.update.success[i])
            }

            createTurnoverFormData("edit", excelData)
            createShipFormdata("edit", excelData.turnover_details)
            createCreditFormdata("edit", excelData.credit_notes)
          }
        })
      } else {
        filterCustomers()
      }
    }
  }, [turnoverData.actor_id, props.customers, isExcel, excelData])


  useEffect(() => {
    if (turnoverData.actor_id && props.userData.actor.actor_type === 'admin')
      props.createCustomersList(turnoverData.actor_id)
    console.log(turnoverData.actor_id)
  }, [turnoverData.actor_id, props.userData])

  const hanldeUserDataForm = (val) => {
    let transit = {...turnoverData}
    if (val.id === "date") {
      transit.month = (val.value.getMonth() + 1) < 10 ? "0" + (val.value.getMonth() + 1) : (val.value.getMonth() + 1)
      transit.year = val.value.getFullYear()
    } else {
      transit[val.id] = val.value
    }
    setTurnoverData(transit)
    if (props.match.params.id==="new") {
      localStorage.setItem('turnover_form', JSON.stringify(transit))
    }
  }

  const handleShipDataForm = (type, val = 0, shipKey = 0, entryKey = 0, key = '') => {
    let transit = shipData
    switch (type) {
      case "add":
        transit[shipKey].entries.push({
          ship_type_id: transit[shipKey].id,
          customer_id: 0,
          corporate_name: "",
          amount: 0,
          discount_amount: 0,
          second_pilot_amount: 0,
          miles_amount: 0,
          services: 0,
          second_pilot_services: 0
        })
        break
      case "delete":
        transit[shipKey].entries.splice(entryKey, 1)
        break
      case "update":
        if (!hasSelectedCustomer && key === "customer_id") {
          setHasSelectedCustomer(true)
        }

        transit[shipKey].entries[entryKey][key] = val
        break
      default:
        break
    }

    setShipData(transit)
    setRenderFlag(renderFlag + 1)

    if (props.match.params.id==="new") {
      localStorage.setItem('ships_form', JSON.stringify(transit))
    }
  }

  const handleCreditForm = (type, val = 0, index = 0, key = 0) => {
    let transit = creditData

    switch(type) {
      case "add":
        transit.push({
          customer_id: 0,
          corporate_name: "",
          amount: 0
        })
        break
      case "delete":
        transit.splice(index, 1)
        break
      case "update":
        if (!hasSelectedCustomer && key === "customer_id") {
          setHasSelectedCustomer(true)
        }
        transit[index][key] = val
        break
      case "new":
        transit.push({
          customer_id: val.id,
          corporate_name: val.name,
          amount: 0
        })
        break
      default:
        break
    }

    setCreditData(transit)
    setRenderFlag(renderFlag + 1)

    if (props.match.params.id==="new") {
      localStorage.setItem('credits_form', JSON.stringify(transit))
    }
  }

  const handleExcelUpload = async (file) => {
    await handleExcelTurnover(file, props.userData.actor.id)
      .then(resp => {
        if (resp.errors) {
          enqueueSnackbar(dic.EXCEL_ERROR, { variant: 'error', persist: false, })
        } else {
          setIsExcel(true)
          setExcelData(resp)
        }

        setExcelDialog(false)
      })
  }

  const resetForm = () => {
    createShipFormdata()
    createCreditFormdata()
    setHasSelectedCustomer(false)
    setTurnoverData({
      year: getPreviousMonth().year,
      month: (getPreviousMonth().month + 1) < 10 ? "0" + (getPreviousMonth().month + 1) : (getPreviousMonth().month + 1),
      actor_id: props.match.params.id==="new" ?  props.userData.actor.actor_type === "admin" ? 0 : props.userData.actor.id : props.match.params.id
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
  }
  const sendDataToServer = () => {
    let shipDataFlat = []

    for (let i = 0 ; i < shipData.length ; i++) {
      for (let j = 0 ; j < shipData[i].entries.length ; j++) {
        if (shipData[i].entries[j].customer_id !== 0) {
          let dataToReturn = shipData[i].entries[j]
          delete dataToReturn.corporate_name
          shipDataFlat.push(dataToReturn)
        }
      }
    }

    let creditDataFlat = []

    for (let i = 0 ; i < creditData.length ; i++) {
      if (creditData[i].customer_id !== 0) {
        let dataCreditToReturn = creditData[i]
        delete dataCreditToReturn.corporate_name
        creditDataFlat.push(dataCreditToReturn)
      }
    }

    let dataToServer = {
      ...turnoverData,
      turnover_details: shipDataFlat,
      credit_notes: creditDataFlat
    }

    if (props.match.params.id === "new") {
      turnoversServices.turnoversInsert(dataToServer)
      .then(resp => {
        if (resp.header.result === 'ok') {
          setIsLoading({...isLoading, global: false})
          resetStorage('turnover')
          enqueueSnackbar(dic.TURNOVER_UPDATED, { variant: 'success', persist: false, })
          props.history.push('/turnover/all')
        } else {
          responseNotification(resp)
          setIsLoading({...isLoading, global: false})
        }
      })
    } else {
      turnoversServices.turnoversUpdate(dataToServer.actor_id, dataToServer.month, dataToServer.year, dataToServer)
      .then(resp => {
        if (resp.header.result === 'ok') {
          setIsLoading({...isLoading, global: false})
          resetStorage('turnover')
          enqueueSnackbar(dic.TURNOVER_UPDATED, { variant: 'success', persist: false, })
          props.history.push('/turnover/all')
        } else {
          responseNotification(resp)
          setIsLoading({...isLoading, global: false})
        }
      })
    }
  }

  const responseNotification = (data) => {
    if (data.header.result === 'ok')
      enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
    else
      for (var i in data.response.details)
        enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
  }
  const responseTextNotification = (response) => {
    switch (response.header.function){
      case 'turnover_insert':
        return dic.TURNOVER_INSERTED
      case 'turnover_update':
        return dic.TURNOVER_UPDATED
      case 'turnover_remove':
        return dic.TURNOVER_DELETED
      default:
        return
    }
  }

  return (
    <Fragment>
      <LoadingDialog open={isLoading.global} />

      <DropzoneDialog
        open={excelDialog}
        onSave={handleExcelUpload}
        acceptedFiles={['.xlsx']}
        onClose={() => setExcelDialog(false)}
        filesLimit={1}
        dropzoneText={dic.EXCEL_DROPZONE}
        previewText={dic.PREVIEW_EXCEL}
        cancelButtonText={dic.CANCEL}
        submitButtonText={dic.SUBMIT}
      />

      <Dialog open={confirmDialog} onClose={() => {setConfirmDialog(false)}}>
        <DialogTitle>
          {dic.RESET_FORM_QUESTION}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
          {dic.RESET_FORM_TEXT}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => {setConfirmDialog(false)}}>{dic.CANCEL}</Button>
          <Button
            onClick={() => {
              resetForm()
              setConfirmDialog(false)
            }} 
            autoFocus
          >{dic.RESET_FORM}</Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardHeader
          subheader={subtitle}
          title={dic.TURNOVERS}
        />

        <Divider />

        <CardContent>
          {
            props.userData.actor.actor_type !== "admin" && props.match.params.id === "new"
            ? (
              <Grid container justifyContent="center">
                <Grid item xs={11}>
                  <Button color="primary" onClick={() => setExcelDialog(true)}>{ dic.UPLOAD_EXCEL }</Button>
                </Grid>
              </Grid>
            )
            : null
          }

          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <UserDataForm
                  formData = { turnoverData }
                  setFormData = { hanldeUserDataForm }
                  actor_type = {props.userData.actor.actor_type}
                  actors = { props.actorsData }
                  hasSelectedCustomer = { hasSelectedCustomer }
                  {...props}
                />
              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => {setConfirmDialog(true)}}>{dic.RESET_FORM}</Button>
              </Grid>
              <Grid item xs={8}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ShipDataForm
                  formData = { shipData }
                  ships = { props.ships.list }
                  customers = { sortedCustomerList }
                  setFormData = { handleShipDataForm }
                  action = {renderFlag}
                  creditFormData = { creditData }
                  setCreditFormData = { handleCreditForm }
                  actorId = { turnoverData.actor_id }
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>

        <CardActions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={12}>
              <Button color="primary" onClick={() => props.history.push('/turnover/all')}>{dic.BACK_TABLE}</Button>
              <Button
                color="secondary"
                disabled={turnoverData.actor_id === 0}
                onClick={() => sendDataToServer()}
              >{actionButton}</Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Fragment>
  )
}

let TurnoversFormConnected = connect(
  state => ({
    userData: state.user,
    turnoversData: state.turnovers,
    actorsData: state.actors,
    ships: state.ships,
    customers: state.customers
  }),
  {
    addNewCustomer,
    updateCustomer,
    createCustomersList
  }
)(TurnoversForm)
export default TurnoversFormConnected