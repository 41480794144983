import React, { useEffect, useState, Fragment } from 'react'

// Material UI components
import {
  Grid, 
  Paper, 
  Button, 
  makeStyles,
  TableCell,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TextField,
  TableContainer,
  IconButton,
  Select,
  MenuItem
} from '@material-ui/core'

// Material Ui icons
import BackspaceIcon from '@material-ui/icons/Backspace';

// Dictionary
import { dic } from '../../../_constants'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: 500
  }
})

const GrossTonnageForm = (props) => {
  const { formData = [], setFormData, headers, maxGrossTonnage } = props
  
  const classes = useStyles()

  const [tonnagesSteps, setTonnagesSteps] = useState([])

  useEffect(() => {
    if (maxGrossTonnage.steps !== 0) {
      let stepsTonnages = []
      for (let i = maxGrossTonnage.max + 1 ; i < 410000 ; i+= maxGrossTonnage.steps) {
        stepsTonnages.push({from : i, to: i + maxGrossTonnage.steps - 1})
      }
      setTonnagesSteps(stepsTonnages)
    }
  }, [maxGrossTonnage])

  const addTonnageRow = () => {
    let newRow = {}

    for (let header in headers) {
      if (headers[header].value) {
        newRow[headers[header].value] = 0
      } else {
        for (let subHeader in headers[header].subheaders) {
          if (headers[header].subheaders[subHeader].value === "from_gross_tonnage") {
            newRow[headers[header].subheaders[subHeader].value] = Number(formData[formData.length - 1].from_gross_tonnage) + Number(maxGrossTonnage.steps)
          } else if (headers[header].subheaders[subHeader].value === "to_gross_tonnage") {
            newRow[headers[header].subheaders[subHeader].value] = Number(formData[formData.length - 1].to_gross_tonnage) + Number(maxGrossTonnage.steps)
          } else {
            newRow[headers[header].subheaders[subHeader].value] = 0
          }
        }
      }
    }

    setFormData(newRow, "addRow")
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.root}>
        {
          maxGrossTonnage.steps === 0
          ? dic.NO_TONNAGE_AVAILABLE
          : (
            <TableContainer component={Paper} className={classes.container}>
              <Table stickyHeader className={classes.table} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      {dic.GROSS_TONNAGE}
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      {dic.PILOT}
                    </TableCell>
                    <TableCell align="center" colSpan={4}>
                      {dic.VHF}
                    </TableCell>
                    <TableCell align="center">
                      {dic.OTHER}
                    </TableCell>
                    <TableCell align="center">
                      {dic.TOTAL}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                  <TableRow>
                    {
                      headers.map(header => 
                        header.subheaders.length > 0
                        ? ( header.subheaders.map(subheader => ( 
                            <Fragment>
                              <TableCell align="left">
                                {subheader.title.toUpperCase()}
                              </TableCell>
                              {(subheader.value === "second_pilot_services" || subheader.value === "extra_vhf_services") &&
                                  <TableCell style={{minWidth: "100px"}}>
                                      {dic.TOTAL.toUpperCase()}
                                  </TableCell>
                              }
                            </Fragment>
                           )) )
                        : ( <TableCell align="left"></TableCell> )
                      )
                    }
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    formData.map((row, key) => (
                      <TableRow key={key}>
                        {
                          headers.map(header => 
                            header.subheaders.length > 0
                            ? ( header.subheaders.map(subheaders => (
                              (subheaders.value === "to_gross_tonnage" || subheaders.value === "from_gross_tonnage") && row[subheaders.value] <= maxGrossTonnage.max
                              ? <TableCell style={{minWidth: "100px"}}>{row[subheaders.value]}</TableCell>
                              : subheaders.value === "to_gross_tonnage"
                                ? <TableCell style={{minWidth: "100px"}}>{row[subheaders.value]}</TableCell>
                                : subheaders.value === "from_gross_tonnage"
                                      ? key < formData.length - 1
                                        ? <TableCell style={{minWidth: "100px"}}>{row[subheaders.value]}</TableCell>
                                        : (
                                          <TableCell style={{minWidth: "100px"}}>
                                            <Select
                                              value={row[subheaders.value]}
                                              onChange={(e) => {
                                                setFormData({value: e.target.value, key, item: "from_gross_tonnage"}, "update")
                                                setFormData({value: tonnagesSteps.find(x => x.from === e.target.value).to, key, item: "to_gross_tonnage"}, "update")
                                              }}
                                            >
                                              {tonnagesSteps.map((elem, key) => <MenuItem value={elem.from} id={key}>{elem.from}</MenuItem>)}
                                            </Select>
                                          </TableCell>
                                        )
                                      : (
                                        <Fragment>
                                          <TableCell style={{minWidth: "100px"}}>
                                            <TextField
                                              type="number"
                                              onChange={(e) => {setFormData({value: e.target.value, key, item: subheaders.value}, "update")}}
                                              value={row[subheaders.value]}
                                            />
                                          </TableCell>
                                          {subheaders.value === "second_pilot_services" &&
                                            <TableCell style={{minWidth: "60px", fontWeight: 'bold', textAlign: "right"}}>
                                                {(row["second_pilot_services"] + row["services"])}
                                            </TableCell>
                                          }
                                          {subheaders.value === "extra_vhf_services" &&
                                            <TableCell style={{minWidth: "60px", fontWeight: 'bold', textAlign: "right"}}>
                                                {(row["vhf_services"] + row["vhf_services_daily"] + row["extra_vhf_services"])}
                                            </TableCell>
                                          }
                                        </Fragment>
                                      )
                            )) )
                            : (
                              header.value
                              ? (
                                <TableCell style={{minWidth: "100px"}}>
                                  <TextField
                                    type="number"
                                    onChange={(e) => {setFormData({value: e.target.value, key, item: header.value}, "update")}}
                                    value={row[header.value]}
                                  />
                                </TableCell>
                              ) : null
                            )
                          )
                        }
                        <TableCell style={{minWidth: "60px", fontWeight: 'bold', textAlign: "right"}}>{Object.keys(row).reduce((acc, val) => acc + ((val !== 'from_gross_tonnage' && val !== 'to_gross_tonnage') ? Number(row[val]) : 0), 0)}</TableCell>
                        <TableCell>
                          {
                            row.from_gross_tonnage > maxGrossTonnage.max && key === formData.length - 1
                            ? (
                              <IconButton onClick={() => {setFormData({ id: key }, "delete")}} >
                                <BackspaceIcon />
                              </IconButton>
                            ) : null
                          }
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>

                <Button onClick={()=>addTonnageRow()} style={{width: "100%"}}>{dic.ADD_TONNAGES}</Button>
            </TableContainer>
          )
        }
      </Grid>
    </Grid>
  )
}

export default GrossTonnageForm