import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createStore , applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import thunkMiddleware from 'redux-thunk';

import { Provider } from 'react-redux'
import rootReducer from './_redux/_reducers'
/*
import { createMuiTheme } from '@material-ui/styles';
import { ThemeProvider } from '@material-ui/styles';*/
import { createTheme, ThemeProvider } from '@material-ui/core/styles';


//import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';
//import { dic } from './_constants'
const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)))
const persistor = persistStore(store)

const theme = createTheme({
  palette: {
    primary: {
      /*dark: '#a73a38',
      main: '#ef5350',
      light: '#f27573',*/
      dark: '#155773',
      main: '#1E7DA5',
      light: '#4b97b7',
    },
    secondary: {
      /*dark: '#47824a',
      main: '#66bb6a',
      light: '#84c887',*/
      dark: '#15731d',
      main: '#1ea52a',
      light: '#4bb754',
    },
    error: {
      dark: '#73151d',
      main: '#a51e2a',
      light: '#b74b54',
    },
  },
});

/*
<ClearBrowserCacheBoundary auto fallback={dic.LOADING} duration={60000}>
</ClearBrowserCacheBoundary>,
*/

ReactDOM.render(
  
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          
            <App />
        </PersistGate>
      </Provider>
    </ThemeProvider>,
  
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
