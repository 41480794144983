import { setCall, handleResponse } from '../_helpers';

export const statisticsServices = {
  statisticsParamSearch,
  statisticsActorSearch,
  statisticsSearch,
  statisticsInsert,
  statisticsUpdate,
  statisticsDelete,
  statisticsPdf,
  statisticsXlsx,
  statisticsOverview,
  statisticsVersions
}

async function statisticsParamSearch(id, from, to) {
  let requestOptions = {}
  if (id === 0 || id === undefined) {
    requestOptions = {
      method: 'POST',
      body: setCall('statistics', 'statistics_search', {from: from, to: to}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  } else {
    requestOptions = {
      method: 'POST',
      body: setCall('statistics', 'statistics_search', {actor_id: id, from: from, to: to}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statisticsActorSearch(id = '') {
  let requestOptions = {}
  if (id === '') { // if admin, search all, if not, get just the one from the current actor
    requestOptions = {
      method: 'POST',
      body: setCall('statistics', 'statistics_search', {}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  } else { // The user is an admin
    requestOptions = {
      method: 'POST',
      body: setCall('statistics', 'statistics_search', {actor_id: id}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statisticsSearch(id, month, year, harbour_id) {
  let requestOptions = {}
  if (id==="") {
    requestOptions = {
      method: 'POST',
      body: setCall('statistics', 'get_statistic_data', {month, year, harbour_id}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  } else {
    requestOptions = {
      method: 'POST',
      body: setCall('statistics', 'get_statistic_data', {actor_id: id, month, year, harbour_id}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statisticsInsert(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('statistics', 'statistic_insert', {...data}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

/**
 * 
 * @param {number} id 
 * @param {number} month 
 * @param {number} year 
 * @param {number} harbour_id 
 * @param {object} data -> {expenses: [...], shares: [...]}
 */
async function statisticsUpdate(id, month, year, harbour_id, data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('statistics', 'statistic_update', {statistic: data, keys: {actor_id: id, month, year, harbour_id}}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statisticsDelete(id, month, year, harbour_id) {
  const requestOptions = {
    method: 'POST',
    body: setCall('statistics', 'statistic_remove', {actor_id: id, harbour_id, month, year}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statisticsXlsx(id, year, month, harbour_id) {
  const requestOptions = {
    method: 'POST',
    body: setCall('statistics', 'get_statistic_data', {actor_id: id, month, year, harbour_id, xlsx: true}, "application/xlsx"),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"xlsx")

  return json;
}

async function statisticsPdf(id, year, month) {
  const requestOptions = {
    method: 'POST',
    body: setCall('statistics', 'get_statistic_pdf', {actor_id: id, month, year}, "application/pdf"),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"pdf")

  return json;
}

async function statisticsOverview(from = {month: 1, year: 2020}, to = {month: 1, year:2021}) {
  const requestOptions = {
    method: 'POST',
    body: setCall('statistics', 'statistics_overview', {from, to}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statisticsVersions(searchParams) {
  const requestOptions = {
    method: 'POST',
    body: setCall('statistics', 'statistics_versions', searchParams),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}