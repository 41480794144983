/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
/*eslint eqeqeq: "off"*/

// Material UI components
import {
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stepper,
  Step,
  StepLabel
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { DropzoneDialog } from 'material-ui-dropzone';

// Personal components
import LoadingDialog from '../utils/Loading'
import RevenueForm from './formComponents/RevenueForm'
import GrossTonnageForm from './formComponents/GrossTonnageForm'
import SurchargesForm from './formComponents/SurchargesForm'
import DiscountsForm from './formComponents/DiscountsForm'

// Dictionary
import { dic } from '../../_constants'

// Personal libraries
import { history } from '../../_helpers/history'
import { statisticsServices, harbourServices, gross_tonnagesServices } from '../../_services';
import { checkLocalStorage, resetStorage, handleExcelStatistics, getPreviousMonth } from '../../_helpers';

const headers = [
  {
    title: "Strazza Lorda",
    size: 2,
    subheaders: [
      { title: dic.FROM, value: "from_gross_tonnage" },
      { title: dic.TO, value: "to_gross_tonnage" }
    ]
  },
  {
    title: "Pilotate",
    size: 2,
    subheaders: [
      { title: dic.NORMAL, value: "services" },
      { title: dic.SECOND_PILOT, value: "second_pilot_services" }
    ]
  },
  {
    title: dic.VHF,
    size: 3,
    subheaders: [
      { title: dic.NORMAL, value: "vhf_services" },
      { title: dic.DAILY, value: "vhf_services_daily" },
      { title: dic.EXTRA, value: "extra_vhf_services" }
    ]
  },
  {
    title: "Altro",
    size: 1,
    value: "extra_services",
    subheaders: []
  },
  {
    title: "Totale",
    size: 1,
    subheaders: []
  },
]

function getSteps() {
  return [
    dic.REVENUE,
    dic.GROSS_TONNAGE,
    dic.SURCHARGES,
    dic.DISCOUNTS
  ]
}

const StatementsForm = (props) => {
  // Form hooks
  const [revenueForm, setRevenueForm] = useState({})
  const [grossTonnageForm, setGrossTonnageForm] = useState([])
  const [surchargesForm, setSurchargesForm] = useState([])
  const [discountsForm, setDiscountsForm] = useState([])

  const [actors, setActors] = useState([])
  const [harbours, setHarbours] = useState([])
  const [surchargesList, setSurchargesList] = useState([])
  const [grossTonnagesData, setGrossTonnagesData] = useState({max: 0, steps: 0})

  // Used to know what to show on screen
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  // Loading hook
  const [isLoading, setIsLoading] = useState({
    global: false,
    components: {
      revenue: false,
      grossTonnage: false,
      surcharges: false,
      discounts: false
    }
  })

  // Errors hook
  const [errors, setErrors] = useState({
    revenue: false,
    grossTonnage: false,
    surcharges: false,
    discounts: false
  })
  const { enqueueSnackbar } = useSnackbar()

  // Excel
  const [excelDialog, setExcelDialog] = useState(false)
  const [isExcel, setIsExcel] = useState(false)

  // Misc hooks
  const [init, setInit] = useState(true)
  const [prevParam, setPrevParam] = useState(null)

  /**
   * Constants outside of hooks
   */
  // Change of text depending on the type of action
  const subtitle = props.match.params.id==="new"?dic.CREATE_STATISTICS:dic.UPDATE_STATISTICS
  const actionButton = props.match.params.id==="new"?dic.CREATE_STATISTICS:dic.UPDATE_STATISTICS
  const stepslabel = {
    [dic.REVENUE]: 'revenue',
    [dic.GROSS_TONNAGE]: 'grossTonnage',
    [dic.SURCHARGES]: 'surcharges',
    [dic.DISCOUNTS]: 'discounts'
  }

  useEffect(() => {
    const checkPathParameters = () => {
      if (props.userData.actor.actor_type !== "admin" && props.match.params.id !== "new" && props.match.params.id !== props.userData.actor.id) {
        history.push("/statistics/all")
        return false
      }

      return true
    }
    const checkInit = () => {
      if (props.match.params !== prevParam) {
        setPrevParam(props.match.params)
        return true
      } 
      return init
    }

    // Funtions used to do the async fetching from the DB
    const fetchActors = () => { setActors(props.actorsData) }
    const fetchHarbours = async (type = "edit") => {
      setLoading('revenue', true)
      switch (type) {
        case "init":
          if (props.userData.actor.actor_type === "admin") {
            setHarbours([{id: 0, name: dic.ACTOR_SELECT}])
          } else {
            await harbourServices.harboursFetchAll().then(resp => {
              if (resp.header.result === 'ok') {
                let transit = [{id: 0, name: dic.HARBOUR_SELECT}, ...resp.response.details]
                setHarbours(transit)
              } else {
                responseNotification(resp)
              }
            })
          }
          break
        default:
          if (props.userData.actor.actor_type === "admin") {
            if (revenueForm.actor_id !== 0) {
              await harbourServices.harboursFetchAll(
                props.match.params.id === 'new'
                ? {actor_id: revenueForm.actor_id}
                : {actor_id: props.userData.actor.actor_id}
              ).then(resp => {
                if (resp.header.result === 'ok') {
                  let transit = [{id: 0, name: dic.HARBOUR_SELECT}, ...resp.response.details]
                  setHarbours(transit)
                } else {
                  responseNotification(resp)
                }
              })
            } else {
              setHarbours([{id: 0, name: dic.ACTOR_SELECT}])
            }
          }
          break
      }

      setLoading('revenue', false)
    }
    const fetchSurcharges = async (returnData = false, type = "init", data = {}) => {
      const formPattern = [...props.statisticsData.formPattern]
      let dataToReturn = []

      switch (type) {
        case "init":
          if (!returnData) setSurchargesForm(formPattern)

          dataToReturn = formPattern
          break
        case "excel":
          let newSurcharges = []
          for (let i = 0 ; i < formPattern.length ; i++) {
            newSurcharges.push({surcharge_id: formPattern[i].id, number: surchargesForm[i].number, amount: surchargesForm[i].amount, name: formPattern[i].name})
          }
          if (!returnData) setSurchargesForm(newSurcharges)

          dataToReturn = newSurcharges
          break
        default:
          break
      }
      if (returnData) return dataToReturn
    }
    const fetchGrossTonnages = async (type = "init", data = {}) => {
      setLoading('grossTonnage', true)
      let dataToReturn = []

      switch (type) {
        case "init":
          if (revenueForm.actor_id && revenueForm.harbour_id) {
            await gross_tonnagesServices.grossTonnageSearch(
              revenueForm.actor_id,
              revenueForm.harbour_id,
              revenueForm.year + "-" + revenueForm.month + "-01"
            ).then(resp => {
              if (resp.header.result === 'ok') {
                const lastEntry = resp.response.details[resp.response.details.length - 1]
                getGrossTonnageMax_Step(lastEntry)

                let newGrossTonnages = []
                let template = {}
                for (let header in headers) {
                  if (headers[header].value) {
                    template[headers[header].value] = 0
                  } else {
                    for (let subHeader in headers[header].subheaders) {
                      template[headers[header].subheaders[subHeader].value] = 0
                    }
                  }
                }
        
                if (lastEntry) {
                  for (let entry in lastEntry.gross_tonnages) {
                    newGrossTonnages.push({...template, to_gross_tonnage: Number(entry), from_gross_tonnage: Number(lastEntry.gross_tonnages[entry])})
                  }
          
                  for (let i = Number(newGrossTonnages[newGrossTonnages.length - 1].to_gross_tonnage) + 1 ; i < 130000 ; i += Number(lastEntry.every)) {
                    newGrossTonnages.push({...template, to_gross_tonnage: i + Number(lastEntry.every) - 1, from_gross_tonnage: i})
                  }
                }
        
                setGrossTonnageForm(newGrossTonnages)
              } else {
                responseNotification(resp)
              }
            
              setLoading('grossTonnage', false)
            })
          } else {
            getGrossTonnageMax_Step()
            setGrossTonnageForm([])
          }
          break
        case "edit":
          await gross_tonnagesServices.grossTonnageSearch(
            data.actor_id,
            data.harbour_id,
            data.year + "-" + data.month + "-01"
          ).then(resp => {
            let newGrossTonnages = []

            if (resp.header.result === 'ok') {
              const lastEntry = resp.response.details[resp.response.details.length - 1]
              
              getGrossTonnageMax_Step(lastEntry)

              let template = {}
              for (let header in headers) {
                if (headers[header].value) {
                  template[headers[header].value] = 0
                } else {
                  for (let subHeader in headers[header].subheaders) {
                    template[headers[header].subheaders[subHeader].value] = 0
                  }
                }
              }
      
              if (lastEntry) {
                for (let entry in lastEntry.gross_tonnages) {
                  newGrossTonnages.push({...template, to_gross_tonnage: Number(entry), from_gross_tonnage: Number(lastEntry.gross_tonnages[entry])})
                }
        
                for (let i = Number(newGrossTonnages[newGrossTonnages.length - 1].to_gross_tonnage) + 1 ; i < 130000 ; i += Number(lastEntry.every)) {
                  newGrossTonnages.push({...template, to_gross_tonnage: i + Number(lastEntry.every) - 1, from_gross_tonnage: i})
                }
              }
            } else {
              responseNotification(resp)
            }
            dataToReturn = newGrossTonnages
          })
          break
        case "excel":
          await gross_tonnagesServices.grossTonnageSearch(
            revenueForm.actor_id,
            revenueForm.harbour_id,
            revenueForm.year + "-" + revenueForm.month + "-01"
          ).then(resp => {
            if (resp.header.result === 'ok') {
              if (resp.response.details.length === 0) {
                getGrossTonnageMax_Step()
              } else {
                const lastEntry = resp.response.details[resp.response.details.length - 1]
                getGrossTonnageMax_Step(lastEntry)
              }
            } else {
              responseNotification(resp)
            }
          })
          break
        default:
          break
      }

      setLoading('grossTonnage', false)
      
      if (type === "edit") return dataToReturn
    }

    // Functions used for form initialisation and form reset
    const initRevenue = (type = "new", data = {}) => {
      const date = getPreviousMonth()

      let revenue_transit = {
        year: date.year,
        month: (date.month + 1) < 10 ? "0" + (date.month + 1) : (date.month + 1),
        actor_id: props.match.params.id==="new" ? props.userData.actor.actor_type === "admin" ? 0 : props.userData.actor.id : props.match.params.id,
        harbour_id: 0,
        italian_amount: 0,
        foreign_amount: 0
      }

      switch (type) {
        case "new":
          setRevenueForm(revenue_transit)
          break
        case "edit":
          revenue_transit = {
            year: Number(data.year),
            month: Number(data.month) < 10 ? "0" + Number(data.month) : Number(data.month),
            actor_id: Number(data.actor_id),
            harbour_id: Number(data.harbour_id),
            italian_amount: Number(data.italian_amount),
            foreign_amount: Number(data.foreign_amount)
          }
          setRevenueForm(revenue_transit)
          break
        default:
          break
      }
    }
    const initGrossTonnage = async (type = "new", data = []) => {
      switch(type) {
        case "new":
          setGrossTonnageForm([])
          break
        case "edit":
          setLoading('grossTonnage', true)
          await fetchGrossTonnages("edit", data)
          .then(resp => {
            const grossTonnageFormData = resp

            for (let i = 0 ; i < grossTonnageFormData.length ; i++) {
              for (let j = 0 ; j < data.gross_tonnages.length ; j++) {
                if (grossTonnageFormData[i].from_gross_tonnage == data.gross_tonnages[j].from_gross_tonnage) {
                  grossTonnageFormData[i] = {
                    from_gross_tonnage: Number([data.gross_tonnages[j].from_gross_tonnage]),
                    to_gross_tonnage: Number([data.gross_tonnages[j].to_gross_tonnage]),
                    extra_services: Number([data.gross_tonnages[j].extra_services]),
                    extra_vhf_services: Number([data.gross_tonnages[j].extra_vhf_services]),
                    second_pilot_services: Number([data.gross_tonnages[j].second_pilot_services]),
                    services: Number([data.gross_tonnages[j].services]),
                    vhf_services: Number([data.gross_tonnages[j].vhf_services]),
                    vhf_services_daily: Number([data.gross_tonnages[j].vhf_services_daily])
                  }
                }
              }
            }
  
            setGrossTonnageForm(grossTonnageFormData)
            setLoading('grossTonnage', false)
          })
          break
        default:
          break
      }
    }
    const initSurcharges = async (type = "new", data = {}) => {
      switch (type) {
        case "new":
          setSurchargesForm([...props.statisticsData.formPattern])
          break
        case "edit":
          let surcharges_data = await fetchSurcharges(true)
          for (let i = 0 ; i < data.surcharges.length ; i++) {
            for (let j = 0 ; j < surcharges_data.length ; j++) {
              if (data.surcharges[i].surcharge_id === surcharges_data[j].surcharge_id) {
                surcharges_data[j].number = Number(data.surcharges[i].number)
                surcharges_data[j].amount = Number(data.surcharges[i].amount)

                j = surcharges_data.length
              }
            }
          }
          setSurchargesForm(surcharges_data)
          break
        default:
          break
      }
    }
    const initDiscounts = (type = "new", data = {}) => {
      switch (type) {
        case "new":
          setDiscountsForm([{percentage: 0, amount: 0}])
          break
        case "edit":
          let discounts_transit = []
          for (let i = 0 ; i < data.discounts.length ; i++) {
            discounts_transit.push({
              percentage: Number(data.discounts[i].percentage),
              amount: Number(data.discounts[i].amount)
            })
          }
          setDiscountsForm(discounts_transit)
          break
        default:
          break
      }
    }
    const initUpdate = async () => {
      await statisticsServices.statisticsSearch(
        props.match.params.id,
        props.match.params.month,
        props.match.params.year,
        props.match.params.harbourId
      ).then(resp => {
        if (resp.header.result === 'ok') {
          const statisticsData = resp.response.details

          initRevenue('edit', statisticsData)
          initGrossTonnage('edit', statisticsData)
          initSurcharges('edit', statisticsData)
          initDiscounts('edit', statisticsData)
        } else {
          responseNotification(resp)
        }
      })
    }

    const getGrossTonnageMax_Step = (data) => {
      if (data) {
        const to_tonnages = Object.keys(data.gross_tonnages).map(elem => Number(elem))
        const max_to_tonnage = Math.max(...to_tonnages)
        setGrossTonnagesData({
          max: max_to_tonnage,
          steps: Number(data.every)
        })
      } else {
        setGrossTonnagesData({
          max: 0,
          steps: 0
        })
      }
    }

    if (checkPathParameters() && checkInit()) {
      fetchActors()
      fetchHarbours("init")
      fetchSurcharges() 
      fetchGrossTonnages()
      setActiveStep(0)
      setIsExcel(false)

      if (props.match.params.id==="new") {
        const localStorageData = checkLocalStorage('statistics')

        localStorageData.revenueData?setRevenueForm(localStorageData.revenueData):initRevenue()
        localStorageData.grossTonnageData?setGrossTonnageForm(localStorageData.grossTonnageData):initGrossTonnage()
        localStorageData.surchargesData?setSurchargesForm(localStorageData.surchargesData):initSurcharges()
        localStorageData.discountsData?setDiscountsForm(localStorageData.discountsData):initDiscounts()
      } else {
        initUpdate()
      }

      setPrevParam(props.match.params)
      setInit(false)
    } else {
      // Update what needs to be updated
      fetchHarbours()

      if (isExcel) {
        fetchGrossTonnages("excel")
        fetchSurcharges(false, "excel")
      } else {
        fetchGrossTonnages()
      }
    }
  }, [revenueForm.actor_id, revenueForm.harbour_id, props.match.params, isExcel])

  const handleSubmit = (e) => {
    e.preventDefault()
  }
  const handleNavigation = (direction) => {
    if (direction === "back") {
      if (activeStep > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      } else {
        history.push('/statistics/all')
      } 
    } else {
      if (activeStep < steps.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        sendFormToDatabase()
      }
    }
  }

  const setRevenue = (data) => {
    let transit_revenue = {...revenueForm}

    if (data.id === "date") {
      let month = (data.value.getMonth() + 1) < 10 ? "0" + (data.value.getMonth() + 1) : (data.value.getMonth() + 1)
      let year = data.value.getFullYear()

      if (year < 2020) {
        year = 2020
      }

      transit_revenue.month = month
      transit_revenue.year = year
    } else {
      if (data.id === "actor_id") {
        transit_revenue.harbour_id = 0
      }
      transit_revenue[data.id] = data.value
    }

    setRevenueForm(transit_revenue)

    if (props.match.params.id==="new") {
      localStorage.setItem('revenue_form', JSON.stringify(transit_revenue))
    }
  }
  const setGrossTonnage = (data, type = "update") => {
    let gross_tonnage_transit = [...grossTonnageForm]

    switch (type) {
      case "update":
        if (data.value) {
          if (data.value < 0) {
            gross_tonnage_transit[data.key][data.item] = 0
          } else {
            gross_tonnage_transit[data.key][data.item] = parseInt(Number(data.value))
          }
        } else {
          gross_tonnage_transit[data.key][data.item] = 0
        }
        setGrossTonnageForm(gross_tonnage_transit)
        break
      case "addRow":
        gross_tonnage_transit.push(data)
        setGrossTonnageForm(gross_tonnage_transit)
        break
      case "delete":
        gross_tonnage_transit.splice(data.id, 1)
        setGrossTonnageForm(gross_tonnage_transit)
        break
      default:
        break
    }

    if (props.match.params.id==="new") {
      localStorage.setItem('grossTonnage_form', JSON.stringify(gross_tonnage_transit))
    }
  }
  const setSurcharges = (type, data = {}) => {
    let surcharges_transit = [...surchargesForm]
    switch (type) {
      case "update":
        if (data.type === "number") {
          surcharges_transit[data.key][data.type] = parseInt(data.value)
        } else {
          surcharges_transit[data.key][data.type] = data.value
        }
        setSurchargesForm(surcharges_transit)
        break
      case "addSurcharges":
        surcharges_transit.push({surcharge_id: 0, number: null, amount: null})
        setSurchargesForm(surcharges_transit)
        break
      case "delete":
        surcharges_transit.splice(data.id, 1)
        setSurchargesForm(surcharges_transit)
        break
      default:
        break
    }

    if (props.match.params.id==="new") {
      localStorage.setItem('surcharges_form', JSON.stringify(surcharges_transit))
    }
  }
  const setDiscounts = (type, data = {}) => {
    let discounts_transit = [...discountsForm]
    switch (type) {
      case "update":
        if (data.type === "percentage" && data.value > 100) {
          discounts_transit[data.key][data.type] = 100
        } else if (data.type === "percentage" && data.value < 0) {
          discounts_transit[data.key][data.type] = 0
        } else {
          discounts_transit[data.key][data.type] = data.value
        }
        setDiscountsForm(discounts_transit)
        break
      case "addDiscount":
        discounts_transit.push({percentage: 0, amount: 0})
        setDiscountsForm(discounts_transit)
        break
      case "delete":
        discounts_transit.splice(data.id, 1)
        setDiscountsForm(discounts_transit)
        break
      default:
        break
    }

    if (props.match.params.id==="new") {
      localStorage.setItem('discounts_form', JSON.stringify(discounts_transit))
    }
  }

  const setLoading = (componentName = "", value = false) => {
    let loading_transit = {...isLoading}

    if (componentName === "") {
      console.error('You need to register the name of the compoenent (accounting, expenses or shares)')
    } else {
      loading_transit.global = false
      loading_transit.components[componentName] = value

      for (let entry in isLoading.components) {
        if (isLoading.components[entry]) {
          loading_transit.global = true
        }
      }

      setIsLoading(loading_transit)
    }
  }
  const responseNotification = (data) => {
    if (data.header.result === 'ok')
      enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
    else
      for (var i in data.response.details)
        enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
  }
  const responseTextNotification = (response) => {
    switch (response.header.function){
      case 'statistic_insert':
        return dic.STATISTIC_INSERTED
      case 'statistic_update':
        return dic.STATISTIC_UPDATED
      case 'statistic_remove':
        return dic.STATISTIC_DELETED
      default:
        return
    }
  }

  const handleExcelUpload = async (file) => {
    await handleExcelStatistics(file, revenueForm.actor_id)
      .then(resp => {
        if (
          resp.revenue.error
          || resp.grossTonnage.error
          || resp.surcharges.error
          || resp.discounts.error
        ) {
          enqueueSnackbar(dic.EXCEL_ERROR, { variant: 'error', persist: false, })
        } else {
          setIsExcel(true)
          setRevenueForm({...resp.revenue.data, month: (resp.revenue.data.month) < 10 ? "0" + resp.revenue.data.month : resp.revenue.data.month})
          setGrossTonnageForm(resp.grossTonnage.data)
          for (let i = 0 ; i < resp.surcharges.data.length ; i++) {
            for (let j = 0 ; j < surchargesForm.length ; j++) {
              if (resp.surcharges.data[i].surcharge_id === Number(surchargesForm[j].surcharge_id)) {
                resp.surcharges.data[i].name = surchargesForm[j].name
                j = surchargesForm.length
              }
            }
          }

          setSurchargesForm(resp.surcharges.data)
          setDiscountsForm(resp.discounts.data)
        }

        setExcelDialog(false)
      })
  }

  const sendFormToDatabase = () => {
    setIsLoading({...isLoading, global: true})

    let newSurcharges = []
    for (let i = 0 ; i < surchargesForm.length ; i++) {
      if (surchargesForm[i].number !== 0 || surchargesForm[i].amount !== 0) {
        newSurcharges.push({
          surcharge_id: surchargesForm[i].surcharge_id,
          number: surchargesForm[i].number===0?null:surchargesForm[i].number,
          amount: surchargesForm[i].amount===0?null:surchargesForm[i].amount
        })
      }
    }

    let newDiscounts = []
    for (let i = 0 ; i < discountsForm.length ; i++) {
      if (discountsForm[i].percentage !== 0 || discountsForm[i].amount !== 0) {
        newDiscounts.push({
          percentage: discountsForm[i].percentage===0?null:discountsForm[i].percentage,
          amount: discountsForm[i].amount===0?null:discountsForm[i].amount
        })
      }
    }

    let newGrossTonnages = []
    for (let i = 0 ; i < grossTonnageForm.length ; i++) {
      let hasValue = false
      for (let elem in grossTonnageForm[i]) {
        if (elem !== "from_gross_tonnage" && elem !== "to_gross_tonnage" && grossTonnageForm[i][elem] !== 0) {
          hasValue = true
        }
      }

      if (hasValue) {
        newGrossTonnages.push(grossTonnageForm[i])
      }
    }

    const dataToServer = {
      ...revenueForm,
      gross_tonnages: newGrossTonnages,
      discounts: newDiscounts,
      surcharges: newSurcharges
    }

    if (props.match.params.id === "new") {
      // Need to create a new accounting statement
      statisticsServices.statisticsInsert(dataToServer)
      .then(resp => {
        if (resp.header.result === 'ok') {
          setIsLoading({...isLoading, global: false})
          resetStorage('statistics')
          enqueueSnackbar(dic.STATISTICS_INSERT, { variant: 'success', persist: false, })
          history.push('/statistics/all')
        } else {
          setIsLoading({...isLoading, global: false})
          responseNotification(resp)
        }
      })
    } else {
      // Need to update an existing accounting statement
      statisticsServices.statisticsUpdate(revenueForm.actor_id, revenueForm.month, revenueForm.year, revenueForm.harbour_id, dataToServer)
      .then(resp => {
        if (resp.header.result === 'ok') {
          setIsLoading({...isLoading, global: false})
          resetStorage('statistics')
          enqueueSnackbar(dic.STATISTICS_UPDATED, { variant: 'success', persist: false, })
          history.push('/statistics/all')
        } else {
          setIsLoading({...isLoading, global: false})
          responseNotification(resp)
        }
      })
    }
  }

  return (
    <Fragment>
      <DropzoneDialog
        open={excelDialog}
        onSave={handleExcelUpload}
        acceptedFiles={['.xlsx']}
        onClose={() => setExcelDialog(false)}
        filesLimit={1}
        dropzoneText={dic.EXCEL_DROPZONE}
        previewText={dic.PREVIEW_EXCEL}
        cancelButtonText={dic.CANCEL}
        submitButtonText={dic.SUBMIT}
      />

      <Card>
        <CardHeader
          subheader={subtitle}
          title={dic.STATISTICS}
        />

        <Divider />

        <CardContent>
          {
            props.userData.actor.actor_type !== "admin" && props.match.params.id === "new"
            ? (
              <Grid container>
                <Grid item xs={11}>
                  <Button color="primary" onClick={() => setExcelDialog(true)}>{ dic.UPLOAD_EXCEL }</Button>
                </Grid>
              </Grid>
            )
            : null
          }

          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => {
                    const labelProps = {}
                    if (errors[stepslabel[label]]) {
                      labelProps.error = true;
                    }
                    return (
                      <Step key={label}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    )
                  })}
                </Stepper>
                  {
                    activeStep === 0
                    ? (
                      <RevenueForm
                        actor_type = { props.userData.actor.actor_type }
                        formData = { revenueForm }
                        actors = { actors }
                        harbours = { harbours }
                        setFormData = { setRevenue }
                        isExcel = { isExcel }
                        {...props}
                      />
                    )
                    : activeStep === 1
                      ? (
                        <GrossTonnageForm
                          formData = { grossTonnageForm }
                          setFormData = { setGrossTonnage }
                          headers = { headers }
                          maxGrossTonnage = { grossTonnagesData }
                        />
                      )
                      : activeStep === 2
                        ? (
                          <SurchargesForm
                            formData = { surchargesForm }
                            setFormData = { setSurcharges }
                            surcharges = { surchargesList }
                          />
                        )
                        : (
                        <DiscountsForm
                          formData = { discountsForm }
                          setFormData = { setDiscounts }
                        />
                        )
                  }
                </Grid>
              </Grid>
          </form>
        </CardContent>

        <CardActions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={12}>
              <Button
                color="primary"
                onClick={() => handleNavigation('back')}
              >
                { activeStep === 0 ? dic.BACK_TABLE : dic.BACK }
              </Button>
              <Button disabled={revenueForm.actor_id === 0 || revenueForm.harbour_id === 0 || grossTonnagesData.steps === 0} size="large" color="secondary" onClick={() => handleNavigation('next')}>
                { activeStep === steps.length - 1 ? actionButton : dic.NEXT }
              </Button>
            </Grid>
            {
              revenueForm.actor_id === 0 || revenueForm.harbour_id === 0
              ? (
                <Grid item xs={12}>
                  {dic.ACTOR_HARBOUR_SELECT}
                </Grid>
              )
              : null
            }
            {
              grossTonnagesData.steps === 0
              ? (
                <Grid item xs={12}>
                  {dic.NO_GROSS_TONNAGES}
                </Grid>
              )
              : null
            }
          </Grid>
        </CardActions>
      </Card>

      <LoadingDialog open={isLoading.global} />
    </Fragment>
  )
}

let StatementsFormConnected = connect(
  state => ({
    userData: state.user,
    statisticsData: state.statistics,
    actorsData: state.actors
  })
)(StatementsForm)
export default StatementsFormConnected