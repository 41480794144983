import React, { Fragment } from 'react';
import { 
    Dialog, Button, DialogTitle, 
    DialogContent, DialogActions,
    Grid, Typography, Divider,
} from '@material-ui/core';

import {
    Timeline, TimelineItem, TimelineSeparator, TimelineConnector, 
    TimelineContent, TimelineDot
} from '@material-ui/lab';

import { dic } from '../../_constants';


const Differences = (props) => {
    const { differences } = props;

    const getDiffStyle = (diff) => {
        if (diff.operationType === "update") {
            return {backgroundColor: ""}
        } else if (diff.operationType === "insert") {
            return {backgroundColor: "rgba(30, 165, 42, 0.2)"}
        } else if (diff.operationType === "delete") {
            return {backgroundColor: "rgba(165, 42, 30, 0.2)"}
        }
        return {}
    }

    return (
        <Grid container direction="row" spacing={1} style={{ marginTop: 5}}>

            {differences.map((diff, index) => {
                let s = getDiffStyle(diff);
                
                return (
                    <Grid item xs={12} key={index}>
                        <Grid container direction="row" spacing={1} style={{ borderBottom: "1px dotted #1E7DA5",}}>
                            <Grid item xs={5}>
                                <Typography variant="subtitle2">
                                    {dic[diff.field.toUpperCase()] ? dic[diff.field.toUpperCase()] : diff.field}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} style={{textAlign: diff.fieldType === 'number' ? 'right' : 'left', backgroundColor: "rgba(30, 125, 165, 0.2)"}}>
                                {
                                    // se fieldType è number allora lo formatto nnn.nnn,nn
                                    diff.fieldType === 'number' ?
                                        (
                                            diff.version1 === "" || diff.version1 === null ? "-" :
                                            parseFloat(diff.version1).toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                        )
                                    :
                                        diff.version1
                                }
                            </Grid>
                            <Grid item xs={3} style={{textAlign: diff.fieldType === 'number' ? 'right' : 'left', backgroundColor: 'rgba(30, 165, 42, 0.20)'}}>
                                {
                                    // se fieldType è number allora lo formatto nnn.nnn,nn
                                    diff.fieldType === 'number' ?
                                        // controlla se è numero altrimenti metti -
                                        (
                                            diff.version2 === "" || diff.version2 === null ? "-" :
                                            parseFloat(diff.version2).toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                        )
                                    :
                                        diff.version2
                                }
                            </Grid>
                            <Grid item xs={1} style={s}>
                                <Typography variant="subtitle2">
                                    {diff.operationType === "update" ?
                                        <span>Aggiornato</span> :
                                        diff.operationType === "insert" ?
                                            <span>Aggiunto</span> :
                                            <span>Rimosso</span>
                                    }
                                    
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
        
    )
}

export default function StatementsVersionsDialog(props) {
    const { 
        versions, open, setOpen, searchParams, actor,
        selectedVersions, addNewSelection, differences,
        expensesDiffs, sharesDiffs, notesDiffs
    } = props;
    const handleClose = () => setOpen(false);


    return (
        <Fragment>
            {actor &&
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                    fullWidth={true}
                    maxWidth="lg"
                >
                    <DialogTitle id="max-width-dialog-title">
                        {actor.corporate_name}: {searchParams.month} / {searchParams.year}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" justifyContent="space-between" alignItems="stretch">
                            <Grid item xs={2}>
                                <Timeline align="right">
                                    {versions.map((version, index) => (
                                    <TimelineItem key={index} onClick={() => addNewSelection(version)}>
                                        <TimelineSeparator>
                                        <TimelineDot color={version.operation_type === 'update' ? "primary" : (version.operation_type === 'insert' ? "secondary" : "grey")} />
                                        <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent
                                            style={
                                                selectedVersions.find((item) => item.timestamp === version.timestamp) ? {
                                                    backgroundColor: selectedVersions.findIndex((item) => item.timestamp === version.timestamp) === 0 ? "rgba(30, 125, 165, 0.2)" : 'rgba(30, 165, 42, 0.2)',
                                                    border: "1px dotted #1E7DA5",
                                                    margin: 2,
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                } : {
                                                    cursor: "pointer",
                                                    margin: 2,
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                    borderBottom: "1px dotted #1E7DA5",
                                                    
                                                }
                                            }
                                        >
                                            <Typography variant="subtitle2">
                                                {
                                                    version.operation_type === 'insert' ?
                                                        'Versione corrente'
                                                    :
                                                    // version timestamp dd/mm/yy hh:mm no seconds
                                                    new Date(version.timestamp).toLocaleString('it-IT', {
                                                        day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'
                                                    })
                                                }
                                            </Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                    ))}
                                </Timeline>
                            </Grid>
                            <Grid item xs={10} style={{ marginTop: 20}}>
                                {selectedVersions.length > 0 &&
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {dic.ACCOUNTING_STATEMENT}
                                        </Typography>
                                        {(selectedVersions.length === 2 && differences.length > 0) ?
                                                <Differences differences={differences} />
                                            :
                                                <Typography variant="subtitle2">
                                                    {selectedVersions.length === 2 &&
                                                        <span>Non sono state trovate differenze</span>
                                                    }
                                                </Typography>
                                        }
                                        {(differences.length === 0) && <Divider />}
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: 10}}>
                                        <Typography variant="h6">
                                            {dic.EXPENSES_SHEET}
                                        </Typography>
                                        {(selectedVersions.length === 2 && expensesDiffs.length > 0) ?
                                                <Differences differences={expensesDiffs} />
                                            :
                                                <Typography variant="subtitle2">
                                                    {selectedVersions.length === 2 &&
                                                        <span>Non sono state trovate differenze</span>
                                                    }
                                                </Typography>
                                        }
                                        {(expensesDiffs.length === 0) && <Divider />}
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: 10}}>
                                        <Typography variant="h6">
                                            {dic.SHARES}
                                        </Typography>
                                        {(selectedVersions.length === 2 && sharesDiffs.length > 0) ?
                                                <Differences differences={sharesDiffs} />
                                            :
                                                <Typography variant="subtitle2">
                                                    {selectedVersions.length === 2 &&
                                                        <span>Non sono state trovate differenze</span>
                                                    }
                                                </Typography>
                                        }
                                        {(sharesDiffs.length === 0) && <Divider />}
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: 10}}>
                                        
                                        <Typography variant="h6">
                                            {dic.NOTES} Spese Varie
                                        </Typography>
                                        {(selectedVersions.length === 2 && notesDiffs.length > 0) ?
                                                <Differences differences={notesDiffs} />
                                            :
                                                <Typography variant="subtitle2">
                                                    {selectedVersions.length === 2 &&
                                                        <span>Non sono state trovate differenze</span>
                                                    }
                                                </Typography>
                                        }
                                        {(notesDiffs.length === 0) && <Divider />}
                                    </Grid>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Grid container direction="row" justifyContent="space-between">
                            {/* crea una legenda, sviluppata in orizzontale per la timeline usando gli stessi colori / logica */}
                            <Grid item>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item>
                                        <Typography variant="subtitle2" style={{color: "grey"}}>
                                            <TimelineDot color="secondary" style={{width: 5, height: 5}} />
                                            Ultima versione
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2" style={{color: "grey"}}>
                                            <TimelineDot color="primary" style={{width: 5, height: 5}} />
                                            Aggiornamento
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2" style={{color: "grey"}}>
                                            <TimelineDot color="grey" style={{width: 5, height: 5}} />
                                            Rimozione
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2" style={{color: "grey"}}>
                                            <div style={{backgroundColor: "rgba(30, 125, 165, 0.2)", border: "1px dotted #1E7DA5" , padding: 10}}>Versione 1</div>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2" style={{color: "grey"}}>
                                            <div style={{backgroundColor: "rgba(30, 165, 42, 0.2)", border: "1px dotted #1E7DA5", padding: 10}}>Versione 2</div>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            { /* crea un'altra legenda col colore prima versione selezionata e seconda versione selezionata */}
                            <Grid item>
                                <Grid container direction="row" spacing={1}>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        <Button onClick={handleClose} color="primary" variant="outlined">
                            {dic.CLOSE}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </Fragment>
    );
}