import readXlsxFile from 'read-excel-file'
/*eslint eqeqeq: "off"*/

export const handleExcel = async (file, expensesForm, partnersList) => {
  let data = {
    accounting: {
      error: false,
      data: {}
    },
    expenses: {
      error: false,
      data: [...expensesForm]
    },
    shares: {
      error: false,
      data: []
    }
  }

  await readXlsxFile(file[0], { sheet: 1 }).then(rows => {
    for (let i = 1 ; i < rows.length ; i++) {
      data.accounting.data[rows[i][0]] = rows[i][2]
    }
  })
  await readXlsxFile(file[0], { sheet: 2 }).then(rows => {
    for (let i = 0 ; i < expensesForm.length ; i++) {
      for (let row = 1 ; row < rows.length ; row++) {
        if (rows[row][0] == data.expenses.data[i].expense_sheet_item_id) {
          data.expenses.data[i].amount = rows[row][4]
        }
      }
    }
    for (let i in data.expenses.data) {
      for (let j in data.expenses.data[i]) {
        for (let row = 1 ; row < rows.length ; row++) {
          if (rows[row][0] == data.expenses.data[i][j].expense_sheet_item_id) {
            data.expenses.data[i][j].amount = rows[row][4]
          }
        }
      }
    }
  })
  await readXlsxFile(file[0], { sheet: 3 }).then(rows => {
    for (let i = 1 ; i < rows.length ; i++) {
      if (partnersList.find(x => x.id === rows[i][0].toString())) {
        data.shares.data.push({
          position: rows[i][2]?parseInt(rows[i][2].split('.')[0]):1,
          share: rows[i][3]?rows[i][3]:0,
          amount: rows[i][4]?rows[i][4]:0,
          partner_id: rows[i][0].toString() // Maybe need an error manager for that one
        })
      } else {
        data.shares.error = true
      }
    }
  })
  try{
    await readXlsxFile(file[0], { sheet: 4 }).then(rows => {
      data.accounting.data.expenses_notes = new Object()
      for (let i = 1 ; i < rows.length ; i++) {
        if (!data.accounting.data.expenses_notes[rows[i][0]])
          data.accounting.data.expenses_notes[rows[i][0]] = new Array()
        var tmpObj = {name: rows[i][2]}
        data.accounting.data.expenses_notes[rows[i][0]].push(tmpObj)
      }
    })
  }catch(e){console.log('non ci sono note')}
  return data
}

export const handleExcelStatistics = async (file, id) => {
  let data = {
    revenue: {
      error: false,
      data: {}
    },
    grossTonnage: {
      error: false,
      data: []
    },
    surcharges: {
      error: false,
      data: []
    },
    discounts: {
      error: false,
      data: []
    }
  }

  await readXlsxFile(file[0], { sheet: 1 }).then(rows => {
    for (let i = 1 ; i < rows.length ; i++) {
      if (rows[i][0] === "harbour_id") {
        data.revenue.data[rows[i][0]] = Number(rows[i][2].split('.')[0])
      } else {
        data.revenue.data[rows[i][0]] = rows[i][2]
      }
    }

    data.revenue.data.actor_id = id
  })

  await readXlsxFile(file[0], { sheet: 2 }).then(rows => {
    let headers = []
    for (let i = 0 ; i < rows[0].length ; i++) {
      headers.push(rows[0][i])
    }

    for (let i = 2 ; i < rows.length ; i++) {
      data.grossTonnage.data.push({})
      for (let j = 0 ; j < rows[i].length ; j++) {
        data.grossTonnage.data[data.grossTonnage.data.length -1][headers[j]] = rows[i][j]?rows[i][j]:0
      }
    }
  })

  await readXlsxFile(file[0], { sheet: 3 }).then(rows => {
    for (let i = 1 ; i < rows.length ; i++) {
      data.surcharges.data[i-1] = {
        surcharge_id: rows[i][0],
        number: rows[i][2]?rows[i][2]:0,
        amount: rows[i][3]?rows[i][3]:0
      }
    }
  })

  await readXlsxFile(file[0], { sheet: 4 }).then(rows => {
    let headers = []
    for (let i = 0 ; i < rows[0].length ; i++) {
      headers.push(rows[0][i])
    }

    for (let i = 2 ; i < rows.length ; i++) {
      data.discounts.data.push({})
      for (let j = 0 ; j < rows[i].length ; j++) {
        data.discounts.data[data.discounts.data.length -1][headers[j]] = rows[i][j]?rows[i][j]:0
      }
    }
  })

  return data
}

export const handleExcelTurnover = async (file, id) => {
  let data = {
    month: 0,
    year: 2020,
    actor_id: id,
    turnover_details: [],
    credit_notes: [],
    update_customers: [],
    errors: false
  }

  await readXlsxFile(file[0], { sheet: 1 }).then(rows => {
    for (let i = 1 ; i < rows.length ; i++) {
      data[rows[i][0]] = rows[i][2]
    }
  })

  await readXlsxFile(file[0], { sheet: 2 }).then(rows => {
    let headers = []
    for (let i = 0 ; i < rows[0].length ; i++) {
      headers.push(rows[0][i])
    }

    for (let i = 2 ; i < rows.length ; i++) {
      data.turnover_details.push({})
      for (let j = 0 ; j < rows[i].length ; j++) {
        if (headers[j] === "ship_type_id") {
          data.turnover_details[data.turnover_details.length - 1][headers[j]] = rows[i][j].split('.')[0]
        } else {
          data.turnover_details[data.turnover_details.length - 1][headers[j]] = rows[i][j]
        }
      }
    }
  })

  await readXlsxFile(file[0], { sheet: 3 }).then(rows => {
    let headers = []
    for (let i = 0 ; i < rows[0].length ; i++) {
      headers.push(rows[0][i])
    }

    for (let i = 2 ; i < rows.length ; i++) {
      data.credit_notes.push({})
      for (let j = 0 ; j < rows[i].length ; j++) {
        data.credit_notes[data.credit_notes.length - 1][headers[j]] = rows[i][j]
      }
    }
  })

  await readXlsxFile(file[0], { sheet: 4 }).then(rows => {
    let headers = []
    for (let i = 0 ; i < rows[0].length ; i++) {
      headers.push(rows[0][i])
    }

    for (let i = 2 ; i < rows.length ; i++) {
      data.update_customers.push({})
      for (let j = 0 ; j < rows[i].length ; j++) {
        data.update_customers[data.update_customers.length - 1][headers[j]] = rows[i][j]
      }
    }
    
  })
  
  return data
}