import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux'

// Material UI components
import {
  Grid,
  TextField,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';

import { actorServices } from '../../_services/actors.services'
import { dic } from '../../_constants/dictionary.constants'
import { history } from '../../_helpers/history'

// External components
import { useSnackbar  } from 'notistack';
import LoadingDialog from '../../_components/utils/Loading'
import { createActorsList } from '../../_redux/_actions/actors.actions';

function ActorForm(props) {
  const [data, setData] = useState({
    actorname: '',
    vat_number: '',
    corporate_name: '',
    address: '',
    zip_code: '',
    city: '',
    telephone: '',
    fax: '',
    mail: '',
    actor_type: 'pilots'
  })
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { actorId, userData } = props

  useEffect(() => {
    const fetchData = async () =>{
      setIsLoading(true)

      if (actorId !== "new") {
        let actorData = await actorServices.actorFetch({actor_id: actorId})
        actorData = actorData.response.details

        setData(actorData)
      }

      setIsLoading(false)
    }

    fetchData()
  }, [actorId]);

  const handleChange = (event) => {
    if (event.target.id === undefined) {
      setData({...data, actor_type: event.target.value })
    } else {
      setData({...data, [event.target.id]: event.target.value })
    }
  }

  const responseNotification = (data) => {
    if (data.header.result === 'ok')
      enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
    else
      for (var i in data.response.details)
        enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
  }

  const responseTextNotification = (response) => {
    switch (response.header.function){
      case 'actor_insert':
        return dic.ACTOR_INSERTED
      case 'actor_update':
        return dic.ACTOR_UPDATED
      case 'actor_remove':
        return dic.ACTOR_DELETED
      default:
        return
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    
    if (actorId === "new") {
      actorServices.actorInsert(data)
      .then((data) => {
        responseNotification(data)
        
        if (data.header.result === 'ok')
          props.createActorsList()
          history.push('/actors')
      })
    } else {
      data.actor_id = data.id
      actorServices.actorUpdate(data)
      .then((data) => {
        responseNotification(data)
        
        if (data.header.result === 'ok')
          props.createActorsList()
          if (userData.actor.actor_type === 'admin')
            history.push('/actors')
          else
            history.push('/actors/' + actorId)
      })
    }
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <LoadingDialog open={isLoading} />
      <Card>
        <CardHeader
          subheader={actorId === "new" ? dic.ACTOR_CREATE : dic.ACTOR_UPDATE}
          title={dic.ACTOR}
        />

        <Divider />

        <CardContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color="primary" variant="h6" style={{textAlign: 'left', marginTop: '20px'}}>
                   1. {dic.ACTOR_INFORMATION}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField required id="corporate_name" fullWidth label={dic.CORPORATE_NAME} variant="outlined" value={data.corporate_name} onChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                  <TextField disabled={data.id ? true : false} required id="actorname" fullWidth label={dic.ACTOR_NAME} variant="outlined" value={data.actorname} onChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="vat_number" fullWidth label={dic.VAT_NUMBER} variant="outlined" value={data.vat_number} onChange={handleChange} />
                </Grid>

                <Grid item xs={12}>
                  <Typography color="primary" variant="h6" style={{textAlign: 'left', marginTop: '20px'}}>
                   2. {dic.ADDRESS}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField required id="address" fullWidth label={dic.ADDRESS} variant="outlined" value={data.address} onChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                  <TextField required id="zip_code" fullWidth label={dic.ZIP_CODE} variant="outlined" value={data.zip_code} onChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                  <TextField required id="city" fullWidth label={dic.CITY} variant="outlined" value={data.city} onChange={handleChange} />
                </Grid>

                <Grid item xs={12}>
                  <Typography color="primary" variant="h6" style={{textAlign: 'left', marginTop: '20px'}}>
                   3. {dic.CONTACT_INFORMATION}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField required id="telephone" fullWidth label={dic.TELEPHONE} variant="outlined" value={data.telephone} onChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="fax" fullWidth label={dic.FAX} variant="outlined" value={data.fax} onChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                  <TextField required id="mail" fullWidth label={dic.MAIL} variant="outlined" value={data.mail} onChange={handleChange} />
                </Grid>
                {userData.actor.actor_type === 'admin' ?
                  <Grid item xs={12}>
                    <Typography color="primary" variant="h6" style={{textAlign: 'left', marginTop: '20px'}}>
                    4. {dic.ADMINISTRATION_INFORMATION}
                    </Typography>
                  </Grid>
                : null}
                {userData.actor.actor_type === 'admin' ?
                  <Grid item xs={6} style={{textAlign: 'left'}}>
                    <InputLabel id="actor_type_label">{dic.ACTOR_TYPE}</InputLabel>
                    <Select
                      required
                      labelId="actor_type_label"
                      id="actor_type"
                      value={data.actor_type}
                      onChange={handleChange}
                    >
                      <MenuItem value={"admin"}>{dic.ADMINISTRATOR}</MenuItem>
                      <MenuItem value={"pilots"}>{dic.PILOTS}</MenuItem>
                    </Select>
                  </Grid>
                : null}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        
        <CardActions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              {userData.actor.actor_type === "admin" ?
              <Button color="secondary" size="large" onClick={() => history.push('/actors')}>{dic.BACK_TABLE}</Button>
              : null}
              <Button color="primary" type="submit" size="large">{actorId === "new" ? dic.ACTOR_CREATE : dic.ACTOR_UPDATE}</Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </form>
  );
}
let ActorFormConnected = connect(
  state => ({
    userData: state.user
  }),
  {
    createActorsList
  })(ActorForm)
export default (ActorFormConnected)