import React from 'react';
import { Grid } from '@material-ui/core';

export default function Page404(props) {

  return (
    <Grid container className="root" justifyContent="center">
      <Grid xs={12}>
        Page not found
      </Grid>
    </Grid>
  );
}