export const getPreviousMonth = () => {
  const date = new Date()

  let returnedData = {
    year: 0,
    month: 0
  }

  returnedData.month = date.getMonth() - 1
  returnedData.year = date.getFullYear()

  if (returnedData.month === -1) {
    returnedData.year -= 1
    returnedData.month = 11
  }

  return returnedData
}

export const getPreviousDate = (monthsToSubstract = 0) => {
  const date = new Date()

  let returnedData = {
    year: 0,
    month: 0
  }

  const monthRest = monthsToSubstract % 12
  const yearToSubstract  = Math.floor(monthsToSubstract/12)

  returnedData.month = date.getMonth() - monthRest 
  returnedData.year = date.getFullYear() - yearToSubstract

  if (date.getMonth() - monthRest <= 0) {
    returnedData.month = 12 + (date.getMonth() - monthRest)
    returnedData.year -= 1
  }

  return returnedData
}