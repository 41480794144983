import React, { Fragment } from 'react'
/*eslint eqeqeq: "off"*/

// Material UI components
import { Grid, Typography, Select, MenuItem } from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import DateFnsUtils from '@date-io/date-fns';
import itLocale from "date-fns/locale/it";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// Dictionary
import { dic } from '../../../_constants'

const RevenueForm = (props) => {
  const {
    actor_type = "pilots",
    formData = {},
    actors = [],
    harbours = [],
    setFormData,
    isExcel = false
  } = props

  return (
    <Grid container justifyContentContentContent="center">
      <Grid item xs={12} sm={10} md={8}>
        <Typography color="primary" variant="h6" style={{textAlign: 'left', marginTop: '20px'}}>
          { dic.REVENUE_DATA }
        </Typography>
      </Grid>

      {
        actor_type === "admin"
        ? (
          <Fragment>
            <Grid item xs={9}>
              <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px'}}>
                {dic.ACTOR_ID}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Select
                id="actor_id"
                value={formData.actor_id?formData.actor_id:0}
                onChange={(e) => {setFormData({
                  id: "actor_id",
                  value: e.target.value
                })}}
                disabled={props.match.params.id !== "new"}
              >
                {actors.map(elem => (
                  <MenuItem id={"actor_id-" + elem.id} value={elem.id} key={elem.actorname}>{elem.corporate_name}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Fragment>
        )
        : null
      }

      <Grid item xs={9}>
        <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px'}}>
          {dic.HARBOUR_ID}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        {
          isExcel
          ? harbours.find(elem => elem.id == formData.harbour_id).name
          : (
            <Select
              id="harbour_id"
              value={formData.harbour_id?formData.harbour_id:0}
              onChange={(e) => {setFormData({
                id: "harbour_id",
                value: e.target.value
              })}}
              disabled={props.match.params.id !== "new"}
            >
              {harbours.map(elem => (
                <MenuItem id={"harbour_id-" + elem.id} value={elem.id} key={elem.name}>{elem.name}</MenuItem>
              ))}
            </Select>
          )
        }
      </Grid>

      <Grid item xs={9}>
        <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px'}}>
          {dic.DATE_PICKER}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            value={new Date(Date.parse(formData.year + "-" + formData.month + "-01"))}
            onChange={(val) => {
              setFormData({ id: "date", value: val})
            }}
            disabled={props.match.params.id !== "new"}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      {/**
       * Automatic form creation for the currency related fields
       */}
      { Object.keys(formData).map(key =>
        (key !== 'actor_id' && key !== 'harbour_id' && key !== 'month' && key !== 'year')
        ? (
          <Fragment>
            <Grid item xs={9}>
              <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px'}}>
                {dic[key.toUpperCase()]}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <CurrencyTextField
                id={key}
                value={formData[key]}
                currencySymbol="€"
                decimalCharacter=","
                decimalCharacterAlternative="."
                digitGroupSeparator="."
                modifyValueOnWheel={false}
                onBlur={(event, val) => {setFormData({
                  id: key,
                  value: val
                })}}
              />
            </Grid>
          </Fragment>
        )
        : null
      )

      }
    </Grid>
  )
}

export default RevenueForm 