import React, { useEffect, useState } from 'react'
import FileViewer from 'react-file-viewer';

// Material UI components
import { Grid, Tooltip, AppBar, Toolbar, Dialog, Slide, IconButton, DialogContent } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import GetAppIcon from '@material-ui/icons/GetApp';

import { dic } from '../../_constants/dictionary.constants';

// Personal functions
import { statementsServices } from '../../_services';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const StatementsExport = (props) => {

  const { data, open, setOpen, actors, exportType, setExportType } = props
  const keys = data
  
  const [loading, setLoading] = useState(true)
  const [file, setFile] = useState(null)

  const downloadXlsx = () => {
    let link = document.createElement('a');
    link.href = file;
    link.download= (data.actor_id ? actors[data.actor_id].corporate_name : dic.ALL)+" "+dic.FROM+" "+data.month.from+" "+data.year.from+" "+dic.TO+" "+data.month.to+" "+data.year.to+".xlsx";
    link.click();
  }

  useEffect(() => {
    const getStatementsExportXlsx = () => {
        statementsServices.statementsExport(
                data.actor_id ? actors[data.actor_id].id : null,
            {
                month: data.month.from,
                year: data.year.from
            },
            {
                month: data.month.to,
                year: data.year.to
            }
        )
        .then(resp => {
          const fileURL = URL.createObjectURL(new Blob([resp], {type: "application/xlsx"}))
          
          setFile(fileURL)
          setLoading(false);
        })
    }

    const getExpensesExportXlsx = () => {
      statementsServices.expensesExport(
              data.actor_id ? actors[data.actor_id].id : null,
          {
              month: data.month.from,
              year: data.year.from
          },
          {
              month: data.month.to,
              year: data.year.to
          }
      )
      .then(resp => {
        const fileURL = URL.createObjectURL(new Blob([resp], {type: "application/xlsx"}))
        setFile(fileURL)
        setLoading(false);
      })
  }

    if (keys && open)
      exportType === 'expenses' ? getExpensesExportXlsx() : getStatementsExportXlsx()
  }, [keys, open]);

  const handleClose = () => {
    setFile(null)
    setLoading(true)
    setOpen(false);
    setExportType(null)
  };

  return (
    <Dialog
      fullScreen={true}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="false"
    >
      <AppBar position="fixed" color="primary" style={{top: 0}}>
            <Toolbar style={{backgroundColor: "grey"}}>
              
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item>
                {(data.actor_id ? actors[data.actor_id].corporate_name : dic.ALL)+" "+dic.FROM+" "+data.month.from+" "+data.year.from+" "+dic.TO+" "+data.month.to+" "+data.year.to}
                </Grid>
              </Grid>
              {!loading &&
                <Tooltip title={dic.DOWNLOAD} placement="top" arrow>
                  <IconButton onClick={downloadXlsx} style={{color: "white"}}>
                      <GetAppIcon />
                  </IconButton>
                </Tooltip>
              }
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Close />
              </IconButton>
            </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{marginTop: "120px", width: "100%", textAlign: "center"}}>
            {loading && <span>{dic.LOADING}...</span>}
          </div>
          {file ?  
              <FileViewer
                fileType="xlsx"
                filePath={file}
              />
          : null}
          
        </DialogContent>
      
    </Dialog>
  )
}

export default StatementsExport