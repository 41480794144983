import React from 'react'

// Material UI components
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core'
import { Divider, IconButton } from '@material-ui/core';

// Material UI Icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { history } from '../../_helpers';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    fontSize: "1.25em",
    display: "flex",
    alignItems: "center",
    textAlign: "center"
  },
  mainInfo: {
    fontWeight: "bold",
    fontSize: "1.7em"
  },
  centeredIcon: {
    display: "flex",
    alignItems: "center",
    textAlign: "center"
  },
  subInfo: {
    fontSize: "1em",
    fontWeight: "bold",
    color: "#888"
  },
  other: {
    fontSize: "1em",
    fontWeight: "bold",
    color: "#888"
  }
}))

const Tile = (props) => {
  const {
    color = "gold",
    title = "Title",
    info = "Main info",
    subInfo = "Sub info",
    other = "Something else",
    to = ""
  } = props
  const classes = useStyles()

  const handleClick = () => {
    if (to !== "") history.push(to)
  }

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="center" style={{textAlign: "left"}}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={1} className={classes.centeredIcon}>
                <FiberManualRecordIcon fontSize="small" style={{color: color}}/>
              </Grid>
              <Grid item xs={9} className={classes.title}>
                {title}
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={handleClick}>
                  <ArrowForwardIcon fontSize="small" style={{color: "grey"}}/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.mainInfo}> 
                {info}
              </Grid>
              <Grid item xs={12} className={classes.subInfo}>
                {subInfo}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} className={classes.other}>
                {other}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Tile