// crea un component che gestisce le richieste di versioni di Statistic
import React, { useCallback, useEffect, useState } from "react";
import StatisticsVersionsDialog from '../_components/statistics/Versions'

import { statisticsServices } from '../_services'
import { dic } from "../_constants";

export default function StatisticsVersions(props) {
    const { open, setOpen, searchParams, actors, surcharges } = props;
    const [versions, setVersions] = useState([]);
    const [selectedVersions, setSelectedVersions] = useState([]);
    const [differences, setDifferences] = useState([]);
    const [grossTonnagesDiffs, setGrossTonnagesDiff] = useState([]);
    const [surchargesDiffs, setSurchargesDiffs] = useState([]);
    const [discountsDiffs, setDiscountsDiffs] = useState([]);

    // searching all the versions of the Statistic
    useEffect(() => {
        if (open)
            statisticsServices.statisticsVersions(searchParams).then((resp) => {
                setVersions(resp.response.details);
            })
        else{
            setVersions([]);
            setSelectedVersions([]);
            setDifferences([]);
        }
    }, [searchParams, open])

    // manage the selection of the versions
    const addNewSelection = (version) => {
        if (selectedVersions.length < 2) {
            setSelectedVersions([...selectedVersions, version]);
        } else {
            setSelectedVersions([version]);
        }
    }

    const compareVersions = useCallback(() => {
        
        const fieldsToCheck = [ 
            "italian_amount", "foreign_amount"
        ];
        
        // check if the selected versions are two
        const diffs = fieldsToCheck.map((field) => {
            if (selectedVersions.length === 2) {
              if (selectedVersions[0][field] !== selectedVersions[1][field] && 
                  !(selectedVersions[0][field] === null && selectedVersions[1][field] === null) &&
                  !(selectedVersions[0][field] === 0 && selectedVersions[1][field] === 0) &&
                  !(selectedVersions[0][field] === "" && selectedVersions[1][field] === "")
              ) {
                  return {
                      field: field,
                      version1: selectedVersions[0][field],
                      version2: selectedVersions[1][field],
                      fieldType: "number",
                      operationType: "update"
                  }
              }
            }
            return null;
        }).filter(item => item !== null);

        /* il campo gross_tonnages è un array di oggetti. Gli oggetti sono identificati univocamente dai campi: to_gross_tonnage, year, month, actor_id, harbour_id
        voglio un array (gTDS) di oggetti che rappresentano le differenze tra i due array di oggetti.
        i campi da controllare, per ogni oggetto sono: services, second_pilot_services, vhf_services, vhf_services_daily, extra_vhf_services, extra_services.
        Ogni oggetto di gTDS ha i seguenti campi:
        field: rappresenta il campo che è diverso tra le due versioni, crea usando from_gross_tonnage e to_gross_tonnage + il campo che è diverso
        version1: valore del campo diverso nella versione 1
        version2: valore del campo diverso nella versione 2
        fieldType: number
        operationType è insert se l'oggetto (rappresentato dalle chiavi to_gross_tonnage, year, month, actor_id, harbour_id) è presente solo in version 2:  mostra in version2 tutti i valori dei campi services, second_pilot_services, vhf_services, vhf_services_daily, extra_vhf_services, extra_services.
        operationType è delete se l'oggetto (rappresentato dalle chiavi to_gross_tonnage, year, month, actor_id, harbour_id) è presente solo in version 1:  mostra in version1 tutti i valori dei campi services, second_pilot_services, vhf_services, vhf_services_daily, extra_vhf_services, extra_services.
        operationType è update se l'oggetto è presente in entrambe le versioni e ha almeno un campo diverso. Se più campi sono diversi, l'oggetto viene ripetuto per ogni campo diverso.
         */
        const gTDS = [];
        if (selectedVersions.length === 2) {
            selectedVersions[0].gross_tonnages.forEach((gt1) => {
                const gt2 = selectedVersions[1].gross_tonnages.find((gt) => 
                    gt.to_gross_tonnage === gt1.to_gross_tonnage && 
                    gt.year === gt1.year && 
                    gt.month === gt1.month && 
                    gt.actor_id === gt1.actor_id && 
                    gt.harbour_id === gt1.harbour_id
                );
                if (gt2 === undefined) {
                    gTDS.push({
                        field: gt1.from_gross_tonnage+" "+gt1.to_gross_tonnage,
                        version1: gt1.services+" "+gt1.second_pilot_services+" "+gt1.vhf_services+" "+gt1.vhf_services_daily+" "+gt1.extra_vhf_services+" "+gt1.extra_services,
                        version2: "",
                        fieldType: "object",
                        operationType: "delete"
                    });
                } else {
                    const fieldsToCheck = ["services", "second_pilot_services", "vhf_services", "vhf_services_daily", "extra_vhf_services", "extra_services"];
                    const fieldsLabels = {
                        "services": "Pilotati normali",
                        "second_pilot_services": "Secondo pilota",
                        "vhf_services": "Servizi VHF normali",
                        "vhf_services_daily": "Servizi VHF plurigiornalieri",
                        "extra_vhf_services": "Servizi VHF non obbligatori",
                        "extra_services": "Altro"
                    }
                    fieldsToCheck.forEach((field) => {
                        if (gt1[field] !== gt2[field] && 
                            !(gt1[field] === null && gt2[field] === null) &&
                            !(gt1[field] === 0 && gt2[field] === 0) &&
                            !(gt1[field] === "" && gt2[field] === "")
                        ) {
                            gTDS.push({
                                field: gt2.from_gross_tonnage+" "+gt2.to_gross_tonnage+": "+fieldsLabels[field],
                                version1: gt1[field],
                                version2: gt2[field],
                                fieldType: "number",
                                operationType: "update"
                            });
                        }
                    });
                }
            });
            selectedVersions[1].gross_tonnages.forEach((gt2) => {
                const gt1 = selectedVersions[0].gross_tonnages.find((gt) => 
                    gt.to_gross_tonnage === gt2.to_gross_tonnage && 
                    gt.year === gt2.year && 
                    gt.month === gt2.month && 
                    gt.actor_id === gt2.actor_id && 
                    gt.harbour_id === gt2.harbour_id
                );
                if (gt1 === undefined) {
                    gTDS.push({
                        field: gt2.from_gross_tonnage+" "+gt2.to_gross_tonnage,
                        version1: "",
                        version2: gt2.services+" "+gt2.second_pilot_services+" "+gt2.vhf_services+" "+gt2.vhf_services_daily+" "+gt2.extra_vhf_services+" "+gt2.extra_services,
                        fieldType: "object",
                        operationType: "insert"
                    });
                }
            });
        }
        
        /* il campo surcharges è un array di oggetti. Gli oggetti sono identificati univocamente dai campi: surcharge_id, year, month, actor_id, harbour_id
        voglio un array (gSD) di oggetti che rappresentano le differenze tra i due array di oggetti.
        i campi da controllare, per ogni oggetto sono: number, amount
        Ogni oggetto di gSD ha i seguenti campi:
        field: rappresenta il campo che è diverso tra le due versioni, crea usando surcharge_id + il campo che è diverso
        version1: valore del campo diverso nella versione 1
        version2: valore del campo diverso nella versione 2
        fieldType: number
        operationType è insert se l'oggetto (rappresentato dalle chiavi surcharge_id, year, month, actor_id, harbour_id) è presente solo in version 2:  mostra in version2 tutti i valori dei campi number, amount
        operationType è delete se l'oggetto (rappresentato dalle chiavi surcharge_id, year, month, actor_id, harbour_id) è presente solo in version 1:  mostra in version1 tutti i valori dei campi number, amount
        operationType è update se l'oggetto è presente in entrambe le versioni e ha almeno un campo diverso. Se più campi sono diversi, l'oggetto viene ripetuto per ogni campo diverso.
         */
        const gSD = [];
        if (selectedVersions.length === 2) {
            selectedVersions[0].surcharges.forEach((s1) => {
                const s2 = selectedVersions[1].surcharges.find((s) => 
                    s.surcharge_id === s1.surcharge_id && 
                    s.year === s1.year && 
                    s.month === s1.month && 
                    s.actor_id === s1.actor_id && 
                    s.harbour_id === s1.harbour_id
                );
                if (s2 === undefined) {
                    gSD.push({
                        field: surcharges.find((surcharge) => surcharge.surcharge_id === s1.surcharge_id).name,
                        version1: (s1.number ? s1.number : "0")+" - "+(s1.amount ? s1.amount : "0"),
                        version2: "",
                        fieldType: "object",
                        operationType: "delete"
                    });
                } else {
                    const fieldsToCheck = ["number", "amount"];
                    fieldsToCheck.forEach((field) => {
                        if (s1[field] !== s2[field] && 
                            !(s1[field] === null && s2[field] === null) &&
                            !(s1[field] === 0 && s2[field] === 0) &&
                            !(s1[field] === "" && s2[field] === "")
                        ) {
                            gSD.push({
                                field: surcharges.find((surcharge) => surcharge.surcharge_id === s2.surcharge_id).name+": "+dic[field.toLocaleUpperCase()],
                                version1: s1[field],
                                version2: s2[field],
                                fieldType: "number",
                                operationType: "update"
                            });
                        }
                    });
                }
            });
            selectedVersions[1].surcharges.forEach((s2) => {
                const s1 = selectedVersions[0].surcharges.find((s) => 
                    s.surcharge_id === s2.surcharge_id && 
                    s.year === s2.year && 
                    s.month === s2.month && 
                    s.actor_id === s2.actor_id && 
                    s.harbour_id === s2.harbour_id
                );
                if (s1 === undefined) {
                    gSD.push({
                        field: surcharges.find((surcharge) => surcharge.surcharge_id === s2.surcharge_id).name,
                        version1: "",
                        version2: (s2.number ? s2.number : "0")+" - "+(s2.amount ? s2.amount : "0"),
                        fieldType: "object",
                        operationType: "insert"
                    });
                }
            });
        }
        /* il campo discounts è un array di oggetti. Gli oggetti sono identificati univocamente dai campi: percentage, year, month, actor_id, harbour_id
        voglio un array (gDD) di oggetti che rappresentano le differenze tra i due array di oggetti.
        i campi da controllare, per ogni oggetto sono: amount
        Ogni oggetto di gDD ha i seguenti campi:
        field: rappresenta il campo che è diverso tra le due versioni, crea usando percentage + il campo che è diverso
        version1: valore del campo diverso nella versione 1
        version2: valore del campo diverso nella versione 2
        fieldType: number
        operationType è insert se l'oggetto (rappresentato dalle chiavi percentage, year, month, actor_id, harbour_id) è presente solo in version 2:  mostra in version2 tutti i valori dei campi amount
        operationType è delete se l'oggetto (rappresentato dalle chiavi percentage, year, month, actor_id, harbour_id) è presente solo in version 1:  mostra in version1 tutti i valori dei campi amount
        operationType è update se l'oggetto è presente in entrambe le versioni e ha almeno un campo diverso. Se più campi sono diversi, l'oggetto viene ripetuto per ogni campo diverso.
         */
        const gDD = [];
        if (selectedVersions.length === 2) {
            selectedVersions[0].discounts.forEach((d1) => {
                const d2 = selectedVersions[1].discounts.find((d) => 
                    d.percentage === d1.percentage && 
                    d.year === d1.year && 
                    d.month === d1.month && 
                    d.actor_id === d1.actor_id && 
                    d.harbour_id === d1.harbour_id
                );
                if (d2 === undefined) {
                    gDD.push({
                        field: d1.percentage+"%",
                        version1: d1.amount,
                        version2: "",
                        fieldType: "object",
                        operationType: "delete"
                    });
                } else {
                    if (d1.amount !== d2.amount && 
                        !(d1.amount === null && d2.amount === null) &&
                        !(d1.amount === 0 && d2.amount === 0) &&
                        !(d1.amount === "" && d2.amount === "")
                    ) {
                        gDD.push({
                            field: d1.percentage+"%",
                            version1: d1.amount,
                            version2: d2.amount,
                            fieldType: "number",
                            operationType: "update"
                        });
                    }
                }
            });
            selectedVersions[1].discounts.forEach((d2) => {
                const d1 = selectedVersions[0].discounts.find((d) => 
                    d.percentage === d2.percentage && 
                    d.year === d2.year && 
                    d.month === d2.month && 
                    d.actor_id === d2.actor_id && 
                    d.harbour_id === d2.harbour_id
                );
                if (d1 === undefined) {
                    gDD.push({
                        field: d2.percentage+"%",
                        version1: "",
                        version2: d2.amount,
                        fieldType: "object",
                        operationType: "insert"
                    });
                }
            });
        }

        setDifferences(diffs);
        setGrossTonnagesDiff(gTDS);
        setSurchargesDiffs(gSD);
        setDiscountsDiffs(gDD);
    }, [selectedVersions, surcharges])

    useEffect(() => compareVersions(), [selectedVersions, compareVersions])

    

    return (
        <StatisticsVersionsDialog
            versions={versions}
            open={open}
            setOpen={setOpen}
            searchParams={searchParams}
            actor={actors.find((actor) => actor.id === searchParams.actor_id)}
            selectedVersions={selectedVersions}
            addNewSelection={addNewSelection}
            differences={differences}
            grossTonnagesDiffs={grossTonnagesDiffs}
            discountsDiffs={discountsDiffs}
            surchargesDiffs={surchargesDiffs}
        />
    )
}