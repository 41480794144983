import React, { useEffect } from 'react';
import { Router, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux'

import './App.css';

import OfflineSnackBar from './_components/utils/OfflineSnackBar';
import RouteManager from './_components/utils/RouteManager';
import LoginForm from './_components/user/LoginForm';
import Logout from './_components/user/Logout';
import Header from './_components/header/Header';

import Home from './_components/Home';
import Actors from './_components/Actors';
import Partners from './_components/Partners';
import Harbours from './_components/Harbours';
import Customers from './_components/Customers';
import GrossTonnages from './_components/harbours/GrossTonnages';
import Users from './_components/Users';
import Password from './_components/users/Password';
import AccountingStatements from './_components/AccountingStatements';
import Statistics from './_components/Statistics';
import Turnover from './_components/Turnover';
import Settings from './_components/Settings';
import Documents from './_components/Documents';
import Page404 from './_components/Page404';
import Export from './_components/export/Export';

import { Offline } from "react-detect-offline";
import { history } from './_helpers';
import { checkSession } from './_services/users.services';

import { 
  createExpensesForms,
  createSurchargesForms,
  createActorsList,
  createShipsList,
  createCustomersList
} from './_redux/_actions/';

import { SnackbarProvider } from 'notistack';
import { Button, CssBaseline } from '@material-ui/core';
import { dic } from './_constants/dictionary.constants';

const App = (props) => {
  const { userData } = props;
  const notistackRef = React.createRef();

  const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key);
  }

  useEffect(() => {
    const sessionChecker = () => {
      if (userData.isLogged)
        checkSession()
    }
    window.addEventListener("focus", sessionChecker)
    return () => { window.removeEventListener("focus", sessionChecker) }
  }, [userData])

  return (
    <div className="App">
      <Router history={history}>
        <SnackbarProvider
          ref={notistackRef}
          action={(key) => (<Button onClick={ onClickDismiss(key) }>{dic.OK}</Button>)}
          maxSnack={3}
        >
          <Header userData={userData} />
          <CssBaseline />

          <Switch>
            <RouteManager exact path="/" component={Home} />
            <RouteManager exact path="/login" component={LoginForm} />
            <RouteManager exact path="/logout" component={Logout} />

            {/**
              * Accounting Statements management
              * 
              * Used by:
              * @Admin
              * @Pilots
              */}
            <RouteManager exact path="/accounting_statements" component={AccountingStatements} />
            <RouteManager exact path="/accounting_statements/:id" component={AccountingStatements} />
            <RouteManager exact path="/accounting_statements/:id/:year" component={AccountingStatements} />
            <RouteManager exact path="/accounting_statements/:id/:year/:month" component={AccountingStatements} />
            <RouteManager exact path="/accounting_statements/:id/:year/:month/edit" component={AccountingStatements} />
            
            {/**
             * Actors information
             * 
             * Used by:
             * @Admin
             */}
            <RouteManager exact path="/actors" component={Actors} />
            <RouteManager exact path="/actors/:id" component={Actors} />
            <RouteManager exact path="/actors/:id/partners" component={Partners} />
            <RouteManager exact path="/actors/:id/harbours" component={Harbours} />
            <RouteManager exact path="/actors/:id/customers" component={Customers} />
            <RouteManager exact path="/actors/:id/users" component={Users} />
            <RouteManager exact path="/actors/:id/users/:userId" component={Users} />
            <RouteManager exact path="/actors/:id/users/:userId/password" component={Password} />

            <RouteManager exact path="/actors/:id/harbours/:harbourId/gross_tonnages" component={GrossTonnages} />
            <RouteManager exact path="/actors/:id/harbours/:harbourId/gross_tonnages/:grossTonnageId" component={GrossTonnages} />

             {/**
              * User Profile
              * 
              * Used by:
              * @Admin
              * @Pilots
              */}
            <RouteManager exact path="/users" component={Actors} />
            <RouteManager exact path="/users/:id" component={Actors} />

            {/**
             * Statistics gross tonnages management
             * 
             * Used by:
             * @Admin
             * @Pilots
             */}
            <RouteManager exact path="/statistics" component={Statistics} />
            <RouteManager exact path="/statistics/:id" component={Statistics} />
            <RouteManager exact path="/statistics/:id/:year" component={Statistics} />
            <RouteManager exact path="/statistics/:id/:year/:month/:harbourId" component={Statistics} />
            <RouteManager exact path="/statistics/:id/:year/:month/:harbourId/edit" component={Statistics} />

            {/**
             * Turnover management
             * 
             * Used by:
             * @Admin
             * @Pilots
             */}
            <RouteManager exact path="/turnover" component={Turnover} />
            <RouteManager exact path="/turnover/:id" component={Turnover} />
            <RouteManager exact path="/turnover/:id/:year" component={Turnover} />
            <RouteManager exact path="/turnover/:id/:year/:month" component={Turnover} />
            <RouteManager exact path="/turnover/:id/:year/:month/edit" component={Turnover} />
            
            {/**
             * data export
             * 
             * Used by:
             * @Admin
             */}
            {userData.isLogged && userData.actor.actor_type === 'admin' ?
                <RouteManager exact path="/export" component={Export} />
                : null
            }

            {/**
             * Partners list
             * 
             * Used by:
             * @Admin
             * 
             * ! Might be changed later
             */}
            <RouteManager exact path="/partners" component={Partners} />
            <RouteManager exact path="/users" component={Users} />

            {/**
             * Company settings
             * 
             * Used by:
             * @Admin
             * @Pilots
             */}
            <RouteManager exact path="/settings/account" component={Home} />
            <RouteManager exact path="/settings/company" component={Home} />


            {userData.isLogged && userData.actor.actor_type === 'admin' ?
              <RouteManager exact path="/settings/sheet_headers" component={Settings} />
              : null
            }
            {userData.isLogged && userData.actor.actor_type === 'admin' ?
              <RouteManager exact path="/settings/sheet_items" component={Settings} />
              : null
            }
            {userData.isLogged && userData.actor.actor_type === 'admin' ?
              <RouteManager exact path="/settings/surcharges" component={Settings} />
              : null
            }
            {userData.isLogged && userData.actor.actor_type === 'admin' ?
              <RouteManager exact path="/settings/ship_types" component={Settings} />
              : null
            }

            {/**
             * data export
             * 
             * Used by:
             * @pilots
             * @admin
             */}
            <RouteManager exact path="/documents" component={Documents} />
            <RouteManager component={Page404} />
          </Switch>
          
          {
            userData.isLogged
            ? (history.location.pathname==='/login'
              ? (<Redirect to={{ pathname: '/' }} />)
              : (<Redirect to={{ pathname: history.location.pathname }} />))
            : (<LoginForm />)
          }
          <Offline><OfflineSnackBar /></Offline>
        </SnackbarProvider>
      </Router>
    </div>
  )
}

let AppConnected = connect(
  state => ({
    userData: state.user,
    actors: state.actors,
    statement: state.statements,
    statistics: state.statistics,
    ships: state.ships,
    customers: state.customers
  }),
  {
    createExpensesForms,
    createSurchargesForms,
    createActorsList,
    createShipsList,
    createCustomersList
  })(App)
export default (AppConnected)