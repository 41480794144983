import React, { forwardRef, useEffect, useState }  from 'react';
import {connect} from 'react-redux'
/*eslint eqeqeq: "off"*/

import MaterialTable from 'material-table'
import {
    Button,
    Grid,
    TextField,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    MenuItem,
    Select,
    InputLabel,
    FormControl
} from '@material-ui/core'

import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from '@material-ui/icons';

import { actors_customersServices } from '../../_services'
import { dic, dicTypes } from '../../_constants/dictionary.constants'
import countries from 'world_countries_lists/data/countries/it/countries.json';

import { useSnackbar  } from 'notistack';
import ActorsBreadcrumbs from '../../_components/utils/ActorsBreadcrumbs'
import { addNewCustomer, updateCustomer, deleteCustomer, createCustomersList } from '../../_redux/_actions';


function CustomersTable(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [fetchingData, setFetchingData] = useState(false)
    const [selectedActor, setSelectedActor] = useState(false);
    const [sortedCustomerList, setSortedCustomerList] = useState([])
    const [open, toggleOpen] = useState(false)
    const [actionType, setActionType] = useState("new")

    const { actorId, userData } = props;
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };
      
    const parameters = [
    { title: dic.ID, field: 'id'},
    { title: dic.NAME, field: 'corporate_name'},
    { title: dic.ADDRESS, field: 'address'},
    { title: dic.ZIP_CODE, field: 'zip_code'},
    { title: dic.CITY, field: 'city'},
    { title: dic.COUNTRY, field: 'country'},
    ]
      
    const [dialogValue, setDialogValue] = useState({
        actor_id: actorId,
        address: "",
        city: "",
        corporate_name: "",
        country: "",
        country_code: "",
        fiscal_code: "",
        province: "",
        vat_number_code: "",
        vat_number: "",
        zip_code: "",
        index: 0,
        id: ""
    })

    const responseNotification = (data) => {
        if (data.header.result === 'ok'){
            enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
        }
        else{
            for (var i in data.response.details)
                enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
            setFetchingData(false)
        }
    }

    const responseTextNotification = (response) => {
        switch (response.header.function){
            case 'actors_customer_insert':
                return dic.ACTORS_CUSTOMER_INSERTED
            case 'actors_customer_update':
                return dic.ACTORS_CUSTOMER_UPDATED
            case 'actors_customer_remove':
                return dic.ACTORS_CUSTOMER_DELETED
            default:
                return
        }
    }
    
    useEffect(() => {
        if (userData.actor.actor_type === 'admin'){
            props.createCustomersList(actorId)
        }
    }, [actorId])

    useEffect(() => {
        const filterCustomers = () => {
            const result = [...props.customers].filter(customer => customer.actor_id == actorId)
            setSortedCustomerList(result)
        }

        filterCustomers()
    }, [props.customers])

    const breadCrumbsList = {
        actors : {label: dic.ACTORS, url: '/actors'},
        customers : {label: dic.CUSTOMERS, url: '/actors/'+actorId+'/customers', selected: selectedActor.corporate_name, actual: true},
    }

    const handleClose = () => {
        setDialogValue({
          actor_id: actorId,
          address: "",
          city: "",
          corporate_name: "",
          country: "",
          country_code: "",
          fiscal_code: "",
          province: "",
          vat_number_code: "",
          vat_number: "",
          zip_code: "",
          index: 0,
          id: ""
        })
        toggleOpen(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        const customerData = {...dialogValue}
        delete customerData.index
        customerData.actor_id = actorId
        customerData.country_code = customerData.country_code.toUpperCase()

        if (customerData.id === "") {
            let unique = false
            do {
                customerData.id = Math.floor(Math.random()*100000000000)

                if (!sortedCustomerList.find(elem => elem.customer_id === customerData.id)) unique = true
            } while (!unique)
        }

        if (actionType === "new") {
            // Create new customer
            actors_customersServices.customersInsert(customerData)
            .then(resp => {
                if (resp.header.result === 'ok') {
                    customerData.customer_id = customerData.id
                    props.addNewCustomer(customerData)
                    responseNotification(resp)
                    handleClose()
                } else {
                    console.error("An error has occured")
                    responseNotification(resp)
                    handleClose()
                }
            })
        } else {
            // Update customer
            actors_customersServices.customerUpdate(customerData)
            .then(resp => {
                if (resp.header.result === 'ok') {
                    customerData.customer_id = customerData.id
                    props.updateCustomer(customerData)
                    responseNotification(resp)
                    handleClose()
                } else {
                    console.error("An error has occured")
                    responseNotification(resp)
                    handleClose()
                }
            })
        }
    }

    const newCustomer = () => {
        setActionType("new")
        toggleOpen(true)
    }
    const editCustomer = (customerInfo) => {
        setActionType("update")

        setDialogValue({
            actor_id: customerInfo.actor_id??"",
            address: customerInfo.address??"",
            city: customerInfo.city??"",
            corporate_name: customerInfo.corporate_name??"",
            country: customerInfo.country??"",
            country_code: customerInfo.country_code??"",
            fiscal_code: customerInfo.fiscal_code??"",
            province: customerInfo.province??"",
            vat_number_code: customerInfo.vat_number_code??"",
            vat_number: customerInfo.vat_number??"",
            zip_code: customerInfo.zip_code??"",
            index: 0,
            id: customerInfo.id??""
          })

        toggleOpen(true)
    }

    return (
        <div>
            <ValidationDialog
                open = { open }
                handleClose = { handleClose }
                handleSubmit = { handleSubmit }
                dialogValue = { dialogValue }
                setDialogValue = { setDialogValue }
                type = { actionType }
            />
            {userData.actor.actor_type === 'admin' &&
                <ActorsBreadcrumbs breadCrumbsList={breadCrumbsList} />
            }
            <MaterialTable
                title={selectedActor ? selectedActor.corporate_name+" - "+dic.CUSTOMERS : dic.CUSTOMERS}
                icons={tableIcons}
                columns={parameters}
                isLoading={fetchingData}
                data={sortedCustomerList}  
                options={{actionsColumnIndex: -1, exportButton: true, exportAllData: true}}
                localization={dicTypes._TABLES_LOCALIZATION_}
                actions={[
                    {
                        icon: AddBox,
                        tooltip: dic.CREATE,
                        isFreeAction: true,
                        onClick: () => newCustomer()
                    },
                    {
                        icon: Edit,
                        tooltip: dic.EDIT,
                        onClick: (event, rowData) => editCustomer(rowData)
                    }
                ]}
                editable={{
                    onRowDelete: oldData =>{
                        return new Promise((resolve, reject) => {
                            actors_customersServices.customersDelete(oldData.id, oldData.actor_id)
                            .then((data) => {
                                if (data.header.result === "ok") {
                                    responseNotification(data)
                                    props.deleteCustomer({id: oldData.id, actor_id: oldData.actor_id})
                                    return resolve()
                                } else {
                                    responseNotification(data)
                                    return resolve()
                                }
                            })
                        })
                    },
                }}
            />
        </div>
    )
}

const ValidationDialog = (props) => {
    const {
        open,
        handleClose,
        handleSubmit,
        dialogValue,
        setDialogValue,
        type
    } = props

    console.log(dialogValue)
  
    return (
      <Dialog open={open} aria-labelledby="new-customer">
            <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">{dic.ADD_NEW_CUSTOMER}</DialogTitle>
  
                    <DialogContent>
                        <DialogContentText>
                            {dic.REGISTER_CUSTOMER_INFO}
                        </DialogContentText>
            
                        <Grid container spacing={2} justifyContentContentContent="center">
                            <Grid item xs={12}>
                                <TextField
                                    disabled = { type === "update"}
                                    autoFocus
                                    margin="dense"
                                    id="id"
                                    style={{width: "100%"}}
                                    value={dialogValue.id}
                                    onChange={(event) => setDialogValue({ ...dialogValue, id: Math.max(0, parseInt(event.target.value) ).toString().slice(0,11) })}
                                    label={dic.ID}
                                    type="number"
                                    helperText={`${dialogValue.id.toString().length}/11`}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    style={{width: "100%"}}
                                    value={dialogValue.corporate_name}
                                    onChange={(event) => setDialogValue({ ...dialogValue, corporate_name: event.target.value })}
                                    label={dic.CORPORATE_NAME}
                                    type="text"
                                    inputProps={{
                                        maxlength: 100
                                    }}
                                    helperText={`${dialogValue.corporate_name.length}/100`}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    id="address"
                                    style={{width: "100%"}}
                                    value={dialogValue.address}
                                    onChange={(event) => setDialogValue({ ...dialogValue, address: event.target.value })}
                                    label={dic.ADDRESS}
                                    type="text"
                                    inputProps={{
                                        maxlength: 100
                                    }}
                                    helperText={`${dialogValue.address.length}/100`}
                                    required
                                />
                            </Grid>
            
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    id="zip-code"
                                    style={{width: "100%"}}
                                    value={dialogValue.zip_code}
                                    onChange={(event) => setDialogValue({ ...dialogValue, zip_code: event.target.value })}
                                    label={dic.ZIP_CODE}
                                    type="text"
                                    inputProps={{
                                        maxlength: 10
                                    }}
                                    helperText={`${dialogValue.zip_code.length}/10`}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    id="city"
                                    style={{width: "100%"}}
                                    value={dialogValue.city}
                                    onChange={(event) => setDialogValue({ ...dialogValue, city: event.target.value })}
                                    label={dic.CITY}
                                    type="text"
                                    inputProps={{
                                        maxlength: 200
                                    }}
                                    helperText={`${dialogValue.city.length}/200`}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    id="province"
                                    style={{width: "100%"}}
                                    value={dialogValue.province}
                                    onChange={(event) => setDialogValue({ ...dialogValue, province: event.target.value })}
                                    label={dic.PROVINCE}
                                    type="text"
                                    inputProps={{
                                        maxlength: 200
                                    }}
                                    helperText={`${dialogValue.province.length}/200`}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl required style={{width: "100%"}}>
                                    <InputLabel >{dic.COUNTRY}</InputLabel>
                                    <Select
                                        style={{width: "100%"}}
                                        value={dialogValue.country_code.toLowerCase()}
                                        onChange={(event) => {
                                            setDialogValue({ ...dialogValue, country: countries.find(elem => elem.alpha2 === event.target.value).name, country_code: event.target.value })
                                        }}
                                        >
                                        {
                                            countries.map(country => (
                                                <MenuItem value={country.alpha2}>{country.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                
                            <Grid item xs={8}>
                                <Divider />
                            </Grid>
                
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    id="vat-number-code"
                                    style={{width: "100%"}}
                                    value={dialogValue.vat_number_code}
                                    onChange={(event) => setDialogValue({ ...dialogValue, vat_number_code: event.target.value })}
                                    label={dic.VAT_NUMBER_CODE}
                                    type="text"
                                    inputProps={{
                                        maxlength: 2
                                    }}
                                    helperText={`${dialogValue.vat_number_code.length}/2`}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    id="vat-number"
                                    style={{width: "100%"}}
                                    value={dialogValue.vat_number}
                                    onChange={(event) => setDialogValue({ ...dialogValue, vat_number: event.target.value })}
                                    label={dic.VAT_NUMBER}
                                    type="text"
                                    inputProps={{
                                        maxlength: 25
                                    }}
                                    helperText={`${dialogValue.vat_number.length}/25`}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    id="fiscal-code"
                                    style={{width: "100%"}}
                                    value={dialogValue.fiscal_code}
                                    onChange={(event) => setDialogValue({ ...dialogValue, fiscal_code: event.target.value })}
                                    label={dic.FISCAL_CODE}
                                    type="text"
                                    inputProps={{
                                        maxlength: 25
                                    }}
                                    helperText={`${dialogValue.fiscal_code.length}/25`}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            {dic.CANCEL}
                        </Button>
                        <Button type="submit" color="primary">
                            { type === "new" ? dic.ADD : dic.UPDATE }
                        </Button>
                    </DialogActions>
                </form>
        </Dialog>
    )
}

let CustomersTableConnected = connect(
    state => ({
        userData: state.user,
        actorsData: state.actors,
        customers: state.customers
    }),
    {
      addNewCustomer,
      updateCustomer,
      deleteCustomer,
      createCustomersList
    }
)(CustomersTable)
export default (CustomersTableConnected)