import { combineReducers } from 'redux'
import { user } from './user.reducers';
import { statements } from './statements.reducer';
import { statistics } from './statistics.reducer';
import { actors } from './actors.reducer';
import { ships } from './ships.reducer';
import { customers } from './customers.reducer';

const rootReducer = combineReducers({
  user,
  statements,
  statistics,
  actors,
  ships,
  customers
})

export default rootReducer