import React from "react"

// Material UI components
import { Grid } from "@material-ui/core"

// Personal components
import Overview from "./turnover/Overview"
import TurnoversTable from "./turnover/Table"
import TurnoversForm from "./turnover/Form"

const Turnover = (props) => {
  // Overview page
  if (props.match.params.id === undefined) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={12} sm={11} md={10}>
          <Overview />
        </Grid>
      </Grid>
    )
  }

  // All items page
  if (props.match.params.id === "all") {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          <TurnoversTable {...props} />
        </Grid>
      </Grid>
    )
  }

  // New/Edit Turnover page
  if (props.match.params.id === "new" || props.match.url.search('/edit') !== -1) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          <TurnoversForm {...props} />
        </Grid>
      </Grid>
    )
  }

  // Table with preselected data if only id is sent, or with the year
  return (
    <Grid container className="root" justifyContent="center">
      <Grid item xs={12} sm={10} md={8}>
        <TurnoversTable {...props} />
      </Grid>
    </Grid>
  );
}

export default Turnover