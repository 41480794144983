import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import{
    Group,
    GroupWork,
    Map,
    Business,
    ViewList
}  from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    textDecoration: 'none', 
    color: "grey",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const iconSelector = (icon, classes) =>{
    switch (icon) {
        case 'actors':
            return <Business className={classes.icon} />
        case 'harbours':
            return <Map className={classes.icon} />
        case 'gross_tonnages':
            return <ViewList className={classes.icon} />
        case 'users':
            return <Group className={classes.icon} />
        case 'partners':
            return <GroupWork className={classes.icon} />
        default:
            return '';
    }
}

export default function ActorsBreadcrumbs(props) {
  const {breadCrumbsList} = props
  const classes = useStyles();
  // let { path, url } = useRouteMatch();
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {Object.keys(breadCrumbsList).map(key => {
            if (!breadCrumbsList[key].actual)
                return (
                    <Link key={key} variant="inherit" to={breadCrumbsList[key].url} className={classes.link}>
                        {iconSelector(key, classes)}
                        {breadCrumbsList[key].label}
                    </Link>
                )
            else
                return <Typography color="primary" key={key}>{breadCrumbsList[key].label}</Typography>
        }
      )}
    </Breadcrumbs>
  );
}