import { actorServices } from '../../_services'
import { actorsConstants } from '../../_constants'

import { dic } from '../../_constants'

export function createActorsList() {
  return function(dispatch) {
    actorServices.actorsFetchAll({actor_type: "pilots"})
      .then(resp => {
        if (resp.header.result === 'ok') {
          dispatch({
            type: actorsConstants.CREATE_ACTORS_LIST,
            list: [
              {
                id: 0,
                actorname: dic.ACTOR_SELECT, 
                corporate_name: dic.ACTOR_SELECT
              },
              ...resp.response.details
            ]
          }) 
        } else {
          // error
          console.error(resp)
        }
      })
  }
}
