import React, { forwardRef, useEffect, useState }  from 'react';
import {connect} from 'react-redux'
import { history } from '../../_helpers/history'

import MaterialTable from 'material-table'

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  ViewList
}  from '@material-ui/icons';

import { actorServices } from '../../_services/actors.services'
import { harbourServices } from '../../_services/harbours.services'
import { dic, dicTypes } from '../../_constants/dictionary.constants'

import { useSnackbar  } from 'notistack';
import ActorsBreadcrumbs from '../../_components/utils/ActorsBreadcrumbs'



function HarboursTable(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [fetchingData, setFetchingData] = useState(false)
    const [selectedActor, setSelectedActor] = useState(false);
    const [harboursList, setHarboursList] = useState([])

    const { actorId } = props;
    const { actor } = props.userData;
    
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };
      
      const parameters = [
        { title: dic.ID, field: 'id', editable: "never"},
        { title: dic.NAME, field: 'name', required: true, validate: rowData => rowData.name !== '' && rowData.name !== undefined, cellStyle: {color: "#1E7DA5"}}
      ]
      
    const responseNotification = (data) => {
        if (data.header.result === 'ok'){
            enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
            getHarbours();
        }
        else{
            for (var i in data.response.details)
                enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
            setFetchingData(false)
        }
    }

    const responseTextNotification = (response) => {
        switch (response.header.function){
            case 'harbour_insert':
                return dic.HARBOUR_INSERTED
            case 'harbour_update':
                return dic.HARBOUR_UPDATED
            case 'harbour_remove':
                return dic.HARBOUR_DELETED
            default:
                return
        }
    }

    const getHarbours = () => {
        setFetchingData(true)
        harbourServices.harboursFetchAll(actor.actor_type === 'admin' ? {actor_id: actorId} : {})
            .then(resp => {
                setHarboursList(resp.response.details)
                setFetchingData(false)
            })
    }

    const getActor = () => {
        actorServices.actorFetch({actor_id: actorId})
            .then(resp => {
            setSelectedActor(resp.response.details)
        })
    }
    
    useEffect(() => {
        if (actor.actor_type === 'admin' && !selectedActor)
            getActor();

        getHarbours();
    }, [])
    const breadCrumbsList = actor.actor_type === 'admin' ? {
        actors : {label: dic.ACTORS, url: '/actors'},
        harbours : {label: dic.HARBOURS, url: '/actors/'+actorId+'/harbours', selected: selectedActor.corporate_name, actual: true}
    } : {harbours : {label: dic.HARBOURS, url: '/actors/'+actorId+'/harbours', selected: selectedActor.corporate_name, actual: true}}

    return (
        <div>
            <ActorsBreadcrumbs breadCrumbsList={breadCrumbsList} />
            <MaterialTable
                title={selectedActor ? selectedActor.corporate_name+" - "+dic.HARBOURS : dic.HARBOURS}
                icons={tableIcons}
                columns={parameters}
                isLoading={fetchingData}
                data={harboursList}  
                options={{actionsColumnIndex: -1, exportButton: true, exportAllData: true}}
                localization={dicTypes._TABLES_LOCALIZATION_}
                actions={[
                    {
                        icon: ViewList,
                        tooltip: dic.GROSS_TONNAGES,
                        onClick: (event, rowData) => history.push('/actors/' + rowData.actor_id + '/harbours/' + rowData.id + '/gross_tonnages')
                    }
                ]}
                editable={actor.actor_type === 'admin' ? 
                    {
                    onRowAdd: newData => {
                        return new Promise((resolve, reject) => {
                            if (actor.actor_type === 'admin')
                                newData.actor_id = actorId
                            if (!newData.name)
                                return reject();
                            
                            harbourServices.harbourInsert(newData)
                            .then((data) => {
                                responseNotification(data)
                                return resolve()
                                })
                        })
                    },
                    onRowUpdate: (newData, oldData) => {
                        return new Promise((resolve, reject) => {
                            if (actor.actor_type === 'admin')
                                newData.actor_id = actorId
                            if (!newData.name)
                                return reject();
                            newData.id = oldData.id
                            harbourServices.harbourUpdate(newData)
                            .then((data) => {
                                responseNotification(data)
                                return resolve()
                            })
                        })
                    },
                    onRowDelete: oldData =>{
                        return new Promise((resolve, reject) => {
                            harbourServices.harbourDelete({id: oldData.id, actor_id: oldData.actor_id})
                            .then((data) => {
                                responseNotification(data)
                                return resolve()
                            })
                        })
                    }
                } : false
            }
            />
        </div>
    )
}
let HarboursTableConnected = connect(state => ({userData: state.user}))(HarboursTable)
export default (HarboursTableConnected)