import { setCall, handleResponse } from '../_helpers';

export const documentServices = {
  getDocument
}

async function getDocument(doc, fileType) {
  const  requestOptions = {
      method: 'POST',
      body: setCall('documents', 'get_document', {file: doc}, "application/"+fileType),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} , fileType)

  return json;
}