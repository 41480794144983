/*
 * action types
 */


export const userConstants = {
    LOGIN : 'LOGIN',
    LOGOUT: 'LOGOUT',
    RECEIVE_USER_DATA : 'RECEIVE_USER_DATA',
    UPDATE_CONFIGURATION: 'UPDATE_CONFIGURATION',
    DESTROY_USER_DATA : 'DESTROY_USER_DATA'
}