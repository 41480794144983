import React from 'react'

// Material UI components
import { Grid } from '@material-ui/core';

// Personal components
import StatisticsTable from './statistics/Table'
import StatisticsForm from './statistics/Form'
import Overview from './statistics/Overview'

const Statistics = (props) => {
  // Overview page
  if (props.match.params.id === undefined) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item  xs={11}>
          <Overview />
        </Grid>
      </Grid>
    );
  }

  // All items page
  if (props.match.params.id === "all") {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          <StatisticsTable {...props} />
        </Grid>
      </Grid>
    );
  }

  // New Statistics page
  if (props.match.params.id === "new") {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          <StatisticsForm {...props} />
        </Grid>
      </Grid>
    );
  }

  // Edit Statistics page
  if (props.match.url.search('/edit') !== -1) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          <StatisticsForm {...props} />
        </Grid>
      </Grid>
    );
  }

  /*if (props.match.params.month) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={12} sm={10} md={8}>
          Need to figure out how to do the download, was bugging in my previous attempt
        </Grid>
      </Grid>
    );
  }*/

  // Table with preselected data if only id is sent, or with the year
  return (
    <Grid container className="root" justifyContent="center">
      <Grid item xs={12} sm={10} md={8}>
        <StatisticsTable {...props} />
      </Grid>
    </Grid>
  );
}

export default Statistics