import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { logout } from '../../_redux/_actions/';
import {history} from '../../_helpers'

const Logout = (props) => {
    const { userData, logout } = props
    
    useEffect(() => {
        console.log('logoutMount')
        userData.isLogged ?
            logout().then(() => {
                console.log('logoutCall')
            })
        : history.push("/accounting_statements/all")
    },[]);
    
    return (
        <span>Arrivederci..</span>
    );
}


let connectedLogout = connect(state => ({userData: state.user}), {logout})(Logout)
export default (connectedLogout)