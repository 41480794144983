import React from 'react';
import { Grid } from '@material-ui/core';

import CustomersTable from './customers/Table'

export default function Customers(props) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          <CustomersTable actorId={props.match.params.id ? props.match.params.id : null}/>
        </Grid>
      </Grid>
    )
}