import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux'

// Material UI components
import {
  Grid,
  TextField,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';

import { userServices } from '../../_services/users.services'
import { dic } from '../../_constants/dictionary.constants'
import { history } from '../../_helpers/history'

// External components
import { useSnackbar  } from 'notistack';
import LoadingDialog from '../../_components/utils/Loading'

function UserForm(props) {
  const { userId, actorId, userData } = props
  const [data, setData] = useState({
    username: '',
    name: '',
    surname: '',
    mail: '',
    password: '',
    password_confirm: '',
    actor_id: actorId
  })
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    const fetchData = async () =>{
      setIsLoading(true)

      if (userId !== "new") {
        let userData = await userServices.userFetch({user_id: userId, actor_id: actorId})
        userData = userData.response.details

        setData(userData)
      }

      setIsLoading(false)
    }

    fetchData()
  }, [userId, actorId]);

  const handleChange = (event) => {
    if (event.target.id === undefined) {
      setData({...data, user_type: event.target.value })
    } else {
      setData({...data, [event.target.id]: event.target.value })
    }
  }

  const responseNotification = (data) => {
    if (data.header.result === 'ok')
        enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
    else
        for (var i in data.response.details)
            enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
}

  const responseTextNotification = (response) => {
      switch (response.header.function){
          case 'user_insert':
              return dic.USER_INSERTED
          case 'user_update':
              return dic.USER_UPDATED
          case 'user_remove':
              return dic.USER_DELETED
          default:
              return response.header.function
      }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    
    if (userId === "new"){
      userServices.userInsert(data)
      .then((data) => {
        responseNotification(data)
        
        if (data.header.result === 'ok')
          history.push('/actors/' + actorId + '/users')
      })
    } else {
      userServices.userUpdate(data)
      .then((data) => {
        responseNotification(data)
        
        if (data.header.result === 'ok'){
          if (userData.actor.actor_type === 'admin')
            history.push('/actors/' + actorId + '/users')
          else
            history.push('/actors/' + actorId + '/users/' + userId)
        }
      })
    }
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <LoadingDialog open={isLoading} />
      <Card>
        <CardHeader
          subheader={userId === "new" ? dic.USER_CREATE : dic.USER_UPDATE}
          title={dic.USER}
        />
        <Divider />

        <CardContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField disabled={data.id ? true : false} required id="username" fullWidth label={dic.USERNAME} variant="outlined" value={data.username} onChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                <TextField required id="name" fullWidth label={dic.NAME} variant="outlined" value={data.name} onChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                  <TextField required id="surname" fullWidth label={dic.SURNAME} variant="outlined" value={data.surname} onChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                  <TextField id="mail" fullWidth label={dic.MAIL} variant="outlined" value={data.mail} onChange={handleChange} />
                </Grid>
                {!data.id ?
                  <Grid item xs={6}>
                    <TextField required id="password" type="password" fullWidth label={dic.PASSWORD} variant="outlined" value={data.password} onChange={handleChange} />
                  </Grid>
                : null}
                {!data.id ?
                  <Grid item xs={6}>
                    <TextField required id="password_confirm" type="password" fullWidth label={dic.PASSWORD_CONFIRM} variant="outlined" value={data.password_confirm} onChange={handleChange} />
                  </Grid>
                : null}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        
        <CardActions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              {userData.actor.actor_type === "admin" ?
              <Button color="secondary" size="large" onClick={() => history.push('/actors/'+actorId+'/users')}>{dic.BACK_TABLE}</Button>
              : null}
              {data.id ?
              <Button color="secondary" size="large" onClick={() => history.push('/actors/'+actorId+'/users/'+userId+'/password')}>{dic.PASSWORD_UPDATE}</Button>
              : null}
              <Button color="primary" type="submit" size="large">{userId === "new" ? dic.USER_CREATE : dic.USER_UPDATE}</Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </form>
  );
}
let UserFormConnected = connect(state => ({userData: state.user}))(UserForm)
export default (UserFormConnected)