import React, { forwardRef, useEffect, useState }  from 'react';
import { connect } from 'react-redux'
import { history } from '../../_helpers/history'

import MaterialTable from 'material-table'
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Group,
  GroupWork,
  Map,
  Person
}  from '@material-ui/icons';

import { actorServices } from '../../_services/actors.services'
import { dic, dicTypes } from '../../_constants/dictionary.constants'

import { useSnackbar  } from 'notistack';
import { createActorsList } from '../../_redux/_actions/actors.actions';


const ActorsTable = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [actorsList, setActorsList] = useState([])
  const [fetchingData, setFetchingData] = useState(false)

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
  
  const parameters = [
    { title: dic.CORPORATE_NAME, field: 'corporate_name', validate: rowData => rowData.corporate_name !== '', cellStyle: {color: "#1E7DA5", width: 1024, minWidth: 450}, headerStyle: {width:1024, minWidth: 450}},
    { title: dic.TELEPHONE, field: 'telephone', validate: rowData => rowData.telephone !== '' },
    { title: dic.FAX, field: 'fax', validate: rowData => rowData.fax !== '' },
    { title: dic.MAIL, field: 'mail', validate: rowData => rowData.mail !== '' }
  ]
  
  useEffect(() => {
    getActors();
  }, [props.actors])

  const getActors = () => {
    let newList = [...props.actors]
    newList.shift()
    setActorsList(newList)
  }

  const responseNotification = (data) => {
    if (data.header.result === 'ok'){
      enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
      getActors();
    }
    else{
      for (var i in data.response.details)
        enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
      setFetchingData(false)
    }
  }

  const responseTextNotification = (response) => {
    switch (response.header.function){
      case 'actor_update':
        return dic.ACTOR_UPDATED
      case 'actor_remove':
        return dic.ACTOR_DELETED
      default:
        return
    }
  }

  return (
    <MaterialTable
      title={dic.ACTORS}
      icons={tableIcons}
      columns={parameters}
      data={actorsList}
      isLoading={fetchingData}
      options={{
        actionsColumnIndex: -1, exportButton: true, exportAllData: true
      }}
      actions={[
        {
          icon: AddBox,
          tooltip: dic.CREATE,
          isFreeAction: true,
          onClick: () => history.push('/actors/new')
        },
        {
          icon: Person,
          tooltip: dic.USERS,
          onClick: (event, rowData) => history.push('/actors/' + rowData.id + '/users')
        },
        {
          icon: GroupWork,
          tooltip: dic.PARTNERS,
          onClick: (event, rowData) => history.push('/actors/' + rowData.id + '/partners')
        },
        {
          icon: Group,
          tooltip: dic.CUSTOMERS,
          onClick: (event, rowData) => history.push('/actors/' + rowData.id + '/customers')
        },
        {
          icon: Map,
          tooltip: dic.HARBOURS,
          onClick: (event, rowData) => history.push('/actors/' + rowData.id + '/harbours')
        }
      ]}

      onRowClick={(
        (event, rowData) => {
          history.push('/actors/' + rowData.id)
        }
      )}
      localization={dicTypes._TABLES_LOCALIZATION_}
      editable={{
        onRowUpdate: (newData, oldData) => {
          return new Promise((resolve, reject) => {
            newData.actor_id = newData.id
            if (!newData.corporate_name || !newData.telephone || !newData.fax || !newData.mail)
                return reject();
            actorServices.actorUpdate(newData)
            .then((data) => {
                responseNotification(data)
                props.createActorsList()
                return resolve()
            })
          })
        },
        onRowDelete: oldData =>{
          return new Promise((resolve, reject) => {
            actorServices.actorDelete(oldData.id)
            .then((data) => {
              responseNotification(data)
              props.createActorsList()
              return resolve()
            })
          })
        },
      }}
    />
  )
}

let ActorsTableConnected = connect(
  state => ({
    actors: state.actors
  }),
  {
    createActorsList
  })(ActorsTable)
export default (ActorsTableConnected)