import { setCall, handleResponse } from '../_helpers';

export const harbourServices = {
  harboursFetchAll, harbourInsert, harbourUpdate, harbourDelete, harbourFetch
}

async function harboursFetchAll(searchParams) {
  const  requestOptions = {
      method: 'POST',
      body: setCall('harbours', 'harbours_search', !searchParams ? {} : searchParams),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function harbourFetch(data) {
  const requestOptions = {
      method: 'POST',
      body: setCall('harbours', 'get_harbour_data', data),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function harbourInsert(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('harbours', 'harbour_insert', data),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function harbourUpdate(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('harbours', 'harbour_update', data),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function harbourDelete(keys) {
  const requestOptions = {
    method: 'POST',
    body: setCall('harbours', 'harbour_remove', keys),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}