import React, { Fragment, useState, useEffect } from 'react'

// Material UI components
import {
  TextField,
} from '@material-ui/core'


// Dictionary
// import { dic } from '../../../_constants'


const ExpenseNoteForm = (props) => {
  const {
    accountingData = [],
    sections = [],
    setAccountingData,
    headerId,
    //others,
    //formData,
    setExpensensNotesErrors,
    itemValue
  } = props
  
  // const maxAmount = formData[(others.id -1)].amount
  // const localHeaderId = headerId;
  // const [totalAmount, setTotalAmount] = useState(0)
  const [required, setError] = useState(false)

  const notes = [0];
  useEffect(() => {
    let transit = [null]
    for (let i = 1 ; i <= sections.length ; i++) {
      transit.push(true)
    }
  }, [])


  const expensesNotesChange = (index, value, field) => {
        console.log(value)
        setAccountingData({
            id: "expenses_notes",
            value: {
                ...(accountingData.expenses_notes ? accountingData.expenses_notes : {}), 
                [headerId]:{
                    ...accountingData.expenses_notes[headerId],
                    [index]: {
                        ...(accountingData.expenses_notes[headerId] && accountingData.expenses_notes[headerId][index]) ? accountingData.expenses_notes[headerId][index] : {},
                        [field]: value,
                    }
                }
            }
        })
        
  }

  useEffect(() => {
    setExpensensNotesErrors(required)
  }, [required])
  
  useEffect(() => {
        if (
            // se è diverso da zero e diverso uguale a ""
            (itemValue !== 0 && accountingData.expenses_notes && accountingData.expenses_notes[headerId] && accountingData.expenses_notes[headerId][0] && accountingData.expenses_notes[headerId][0].name === "") || 
            (itemValue !== 0 && (!accountingData.expenses_notes || !accountingData.expenses_notes[headerId] || !accountingData.expenses_notes[headerId][0] || !accountingData.expenses_notes[headerId][0].name))
            )
            setError(true)
        else
            setError(false)
  }, [itemValue, accountingData.expenses_notes, setError])

  return (
    <Fragment>
      {sections.map(header => {
          if (headerId === header.id)
          return (
            <Fragment>
                {notes.map((index) => {
                    return (
                        <TextField
                            error={required}
                            inputProps={{ maxLength: 300 }}
                            id={header.id + "_" +index+"_name"}
                            //label={item.subheader_order_field+"."+item.order_field+" "+dic.NAME}
                            variant="standard"
                            style={{width: '100%'}}
                            size="small"
                            helperText={required ? "Il campo varie deve essere compilato qualora l'importo diverso da zero" : required}
                            value={(accountingData.expenses_notes && 
                              accountingData.expenses_notes[headerId] && 
                              accountingData.expenses_notes[headerId][index] && 
                              accountingData.expenses_notes[headerId][index].name) ? accountingData.expenses_notes[headerId][index].name : ""}
                            onChange={(event) => {expensesNotesChange(index, event.target.value, "name")}}
                        />
                    )
                })}
            </Fragment>
            )
            else
                return false;
        })
      }
    </Fragment>
  )
}

export default ExpenseNoteForm