import React from 'react'
import { connect } from 'react-redux'

import { 
  login
} from '../../_redux/_actions/';
import {history} from '../../_helpers'

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container
} from '@material-ui/core';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { withStyles } from '@material-ui/core/styles';
import { dic } from '../../_constants/dictionary.constants';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class LoginForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
        actorname: props.user.last_actor ? props.user.last_actor : '',
        username: '',
        password: '',
        submitted: false
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    if (props.user.isLogged) {
      history.push('/')
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    
    this.setState({ submitted: true });
    this.props.login(this.state).then(() => {
        history.push('/') 
    })
  }

  render() {
    const { classes, user } = this.props;
    /*const errorsList = Object.keys(user.errors).map((key) =>
        <Snackbar open={true} autoHideDuration={600}>
          <Alert severity="error">{dic[user.errors[key]]}</Alert>
        </Snackbar>
    );*/
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Autenticazione
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="actorname"
              label="Codice Corporazione"
              name="actorname"
              autoComplete="actorname"
              value={this.state.actorname}
              onChange={this.handleChange}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Nome Utente"
              name="username"
              autoComplete="username"
              value={this.state.username}
              onChange={this.handleChange}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={this.state.password}
              onChange={this.handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Accedi
            </Button>
          </form>
        </div>
        {user.errors ? 
          <span style={{fontWeight: "bold", color: "#a51e2a"}}>{user.errors.length > 1 ? dic.LOGIN_FAILED : dic.EXPIRED_SESSION}</span>
        : null}
      </Container>
    );
  }
}


let connectedLoginForm = connect(state => (
  {
    user: state.user
  }), 
  {login})(LoginForm)
export default withStyles(styles)(connectedLoginForm)