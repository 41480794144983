import React, { Fragment, useState } from 'react';
import {Link} from 'react-router-dom';

// Material Ui components
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Collapse
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material UI icons
import {
  MenuBook,
  TableChart,
  Home,
  Euro,
  Settings,
  Business,
  ExpandLess,
  ExpandMore,
  Visibility,
  AddBox,
  AccountCircle,
  ListAlt, 
  Group,
  GroupWork,
  DirectionsBoat,
  SortByAlpha,
  FormatListNumbered,
  Person,
  ImportExport,
  Map,
  FolderOpen
} from '@material-ui/icons/';
import MenuIcon from '@material-ui/icons/Menu';

// Personal libraries
import {dic} from '../../_constants/dictionary.constants';

const useStyles = makeStyles({
  list: {
    width: 320,
  },
  divider:{
    marginTop: 6
  },
  menuLink:{ 
    textDecoration: 'none', 
    color: "#000",
  }
});

const Menu = (props) => {
  const classes = useStyles();
  const [menuState, setState] = useState({ left: false });
  const [open, setOpen] = useState({
    accounting_statements: false,
    actors: false,
    statistics: false,
    turnover: false,
    administration: false
  })

  const { userData } = props;
  const {actor, user} = userData;

  const menuList = [
    { title: dic.HOME, key: "home", to: "/", icon: (<Home color="primary" />) },
    { title: "Divider", key: 1 },
    {
      title: dic.ACCOUNTING_STATEMENT,
      key: "accounting_statements",
      icon: (<MenuBook color="primary" />),
      rights: {admin: true, pilots: true},
      children: [
        { title: dic.OVERVIEW, key: "overview_accounting_statements", to: "/accounting_statements", icon: 'view', rights: {admin: true, pilots: false} },
        { title: dic.NEW_ACCOUNTING_STATEMENT, key: "new_accounting_statements", to: "/accounting_statements/new", icon: 'new' },
        { title: dic.ALL_ACCOUNTING_STATEMENTS, key: "all_accounting_statements", to: "/accounting_statements/all", icon: 'all' }
      ]
    },
    {
      title: dic.STATISTICS,
      key: "statistics",
      icon: (<TableChart color="primary" />),
      rights: {admin: true, pilots: true},
      children: [
        { title: dic.OVERVIEW, key:"overview_statistics", to: "/statistics", icon: 'view', rights: {admin: true, pilots: false} },
        { title: dic.NEW_STATISTIC, key:"new_statistics", to: "/statistics/new", icon: 'new' },
        { title: dic.ALL_STATISTICS, key:"all_statistics", to: "/statistics/all", icon: 'all' }
      ]
    },
    {
      title: dic.TURNOVERS,
      key: "turnover",
      icon: (<Euro color="primary" />),
      rights: {admin: true, pilots: true},
      children: [
        { title: dic.OVERVIEW, key:"overview_turnover", to: "/turnover", icon: 'view', rights: {admin: true, pilots: false} },
        { title: dic.NEW_TURNOVER, key:"new_turnover", to: "/turnover/new", icon: 'new' },
        { title: dic.ALL_TURNOVERS, key:"all_turnover", to: "/turnover/all", icon: 'all' }
      ]
    },
    {
      title: dic.ACTORS,
      key: "actors",
      rights: {admin: true, pilots: false},
      icon: (<Business color="primary" />),
      children: [
        { title: dic.NEW_ACTOR, key:"new_actors", to: "/actors/new", icon: 'new' },
        { title: dic.ALL_ACTORS, key:"all_actors", to: "/actors", icon: 'all' }
      ]
    },
    { title: dic.EXPORTS, key: "export", to: "/export", rights: {admin: true, pilots: false}, icon: (<ImportExport color="primary" />) },
    { title: "Divider", key: 2 },
    {
      title: dic.SYSTEM_MANAGER,
      key: "administration",
      icon: (<Settings color="primary" />),
      rights: {admin: true, pilots: true},
      children: [
        { title: dic.ACCOUNT_SETTINGS, key:"account", to: "/actors/"+actor.id+"/users/"+user.id, icon: 'profile' },
        { title: dic.COMPANY_SETTINGS, key:"company", to: "/actors/"+actor.id, icon: 'company' },
        { title: dic.USERS, key:"users", to: "/actors/"+actor.id+"/users/", icon: 'users', rights: {admin: true, pilots: false}},
        { title: dic.PARTNERS, key:"partners", to: "/partners", icon: 'partners', rights: {admin: false, pilots: true}},
        { title: dic.HARBOURS, key:"harbours", to: "/actors/"+actor.id+"/harbours/", icon: 'harbours', rights: {pilots: true}},
        { title: dic.CUSTOMERS, key:"customers", to: "/actors/"+actor.id+"/customers/", icon: 'group', rights: {admin: false, pilots: true}},
        { title: dic.SHEET_HEADERS, key:"sheet_headers", to: "/settings/sheet_headers", icon: 'sheet_headers', rights: {admin: true, pilots: false}},
        { title: dic.SHEET_ITEMS, key:"sheet_items", to: "/settings/sheet_items", icon: 'sheet_items', rights: {admin: true, pilots: false}},
        { title: dic.SURCHARGES, key:"surcharges", to: "/settings/surcharges", icon: 'surcharges', rights: {admin: true, pilots: false}},
        { title: dic.SHIP_TYPES, key:"ship_types", to: "/settings/ship_types", icon: 'ship_types', rights: {admin: true, pilots: false}}
      ]
    },
    { title: "Documentazione", key: "documents", to: "/documents", rights: {admin: true, pilots: true}, icon: (<FolderOpen color="primary" />) },
  ]

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...menuState, [side]: open });
  };

  const handleClick = (item) => {
    let newOpen = {...open}

    newOpen[item] = !newOpen[item]
    setOpen(newOpen)
  }

  const iconSelector = (icon) =>{
      switch (icon) {
        case 'view':
          return <Visibility fontSize="small" />;
        case 'new':
          return <AddBox fontSize="small" color="secondary" />;
        case 'all':
          return <ListAlt fontSize="small" />
        case 'profile':
            return <AccountCircle fontSize="small" />
        case 'company':
          return <Business fontSize="small" />
        case 'group':
          return <Group fontSize="small" />
        case 'partners':
          return <GroupWork fontSize="small" />
        case 'harbours':
          return <Map fontSize="small" />
        case 'sheet_headers':
          return <SortByAlpha fontSize="small" />
        case 'sheet_items':
          return <FormatListNumbered fontSize="small" />
        case 'surcharges':
          return <Euro fontSize="small" />
        case 'ship_types':
          return <DirectionsBoat fontSize="small" />
        case 'users':
          return <Person fontSize="small" />
        default:
          return '';
      }
  }

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer('left', true)}
        edge="start"
      >
        <MenuIcon />
      </IconButton>

      <Drawer open={menuState.left} onClose={toggleDrawer('left', false)}>
        <div
          className={classes.list}
          role="presentation"
        >
          <List>
            {menuList.map(elem => {
                if (elem.title === "Divider") 
                  return <Divider key={elem.key} className={classes.divider} />
                else if (elem.children) {
                  if ( elem.rights[actor.actor_type])
                    return (
                      
                      <Fragment key={elem.key}>
                        <ListItem button key={elem.key} onClick={() => {handleClick(elem.key)}}>
                          <ListItemIcon>
                            {elem.icon}
                          </ListItemIcon>
                          <ListItemText primary={elem.title} />
                          {open[elem.key] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open[elem.key]} unmountOnExit>
                          <List component="div">
                            {elem.children.map(child => {
                              if (!child.rights || child.rights[actor.actor_type])
                                return (
                                  <Link key={child.key} to={child.to} className={classes.menuLink}>
                                    <ListItem button key={child.key} style={{marginLeft: "15px", fontSize: "1em", width: '90%'}} onClick={() => {setState({ ...menuState, left: false })}}>
                                      <ListItemIcon>
                                        {iconSelector(child.icon)}
                                      </ListItemIcon>
                                      <ListItemText primary={child.title} />
                                    </ListItem>
                                  </Link>
                                )
                              else
                                  return null
                            })}
                          </List>
                        </Collapse>
                      </Fragment>
                    )
                  else
                    return null
              } else {
                if (!elem.rights || elem.rights[actor.actor_type])
                  return (
                    <Link key={elem.key} variant="inherit" to={elem.to} className={classes.menuLink}>
                      <ListItem button key={elem.key} onClick={() => {setState({ ...menuState, left: false })}}>
                        <ListItemIcon>
                          {elem.icon}
                        </ListItemIcon>
                        <ListItemText primary={elem.title} />
                      </ListItem>
                    </Link>
                  )
              }
            })}
          </List>
        </div>
      </Drawer>
    </div>
  );
}

export default Menu