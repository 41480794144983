import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress, DialogContent, Grid } from '@material-ui/core';

import { dic } from '../../_constants'

const LoadingDialog = (props) => {
  const { open, message } = props

  return (
    <Dialog open={open}>
      <DialogContent>
        <Grid container spacing={2} style={{padding: "15px"}}>
          <Grid item xs={12} align="center">
            <CircularProgress />
          </Grid>
          <Grid item xs={12} align="center">
            {
              message
              ? message
              : dic.LOADING
            }
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default LoadingDialog