import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'

// Material UI components
import { Button, Grid, makeStyles } from '@material-ui/core'

// Personal components
import { getPreviousDate, history } from '../../_helpers'
import { statementsServices } from '../../_services/statements.services'
import MissingForms from '../widgets/MissingForms'
import FormsYearOverview from '../widgets/FormsYearOverview'
import { dic } from '../../_constants'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    fontSize: "1.5em",
    textAlign: "left"
  }
}))

const OverviewAdmin = (props) => {
  const [missingForms, setMissingForms] = useState([])
  const [yearReview, setYearReview] = useState([])
  const [yearly, setYearly] = useState({
    next: false,
    previous: false,
    yearDiff: 0
  })
  const [biSemestrialReview, setBiSemestrialReview] = useState([])
  const [biSemestrial, setBiSemestrial] = useState({
    next: false,
    previous: false,
    yearDiff: 0
  })
  const [init, setInit] = useState(true)

  useEffect(() => {
    const formatMissingForms = (data) => {
      const previousMonths = [
        getPreviousDate(2),
        getPreviousDate(1),
        getPreviousDate(0)
      ]
      let dataMissingForms = []

      for (let i = 0 ; i < previousMonths.length ; i++) {
        dataMissingForms.push({
          month: previousMonths[i].month,
          year: previousMonths[i].year,
          actors_statements: []
        })

        for (let actor in data[previousMonths[i].year][previousMonths[i].month].actors_statements) {
          dataMissingForms[i].actors_statements.push({
            id: actor,
            hasForm: data[previousMonths[i].year][previousMonths[i].month].actors_statements[actor]
          })
        }
      }

      setMissingForms(dataMissingForms)
    }
    const formatYearReview = (data) => {
      const dataYearReview = []

      for (let year in data) {
        for (let month in data[year]) {
          dataYearReview.push({
            date: month + "-" + year,
            delivered: Object.keys(data[year][month].actors_statements).length,
            name: dic.TOTAL
          })
          dataYearReview.push({
            date: month + "-" + year,
            delivered: data[year][month].number_of_statements,
            name: dic.RECEIVED
          })
        }
      }

      setYearReview(dataYearReview)
    }
    const formatBiSemestrialReview = (data) => {
      const dataBiSemestrialReview = []

      for (let year in data) {
        for (let month in data[year]) {
          dataBiSemestrialReview.push({
            date: month + "-" + year,
            delivered: Object.keys(data[year][month].actors_statements).length,
            name: dic.TOTAL
          })
          dataBiSemestrialReview.push({
            date: month + "-" + year,
            delivered: data[year][month].number_of_statements,
            name: dic.RECEIVED
          })
        }
      }

      setBiSemestrialReview(dataBiSemestrialReview)
    }

    const getOverview = async () => {
      await statementsServices.statementsOverview(getPreviousDate(12), getPreviousDate(0))
      .then(resp => {
        if (resp.header.result === "ok") {
          const data = resp.response.details

          formatMissingForms(data)
          formatYearReview(data)
        }
      })
    }

    const getYear = async (action) => {
      let yearToSet = 0
      switch (action) {
        case "previous":
          yearToSet = yearly.yearDiff + 12

          await statementsServices.statementsOverview(getPreviousDate(yearToSet + 12), getPreviousDate(yearToSet))
            .then(resp => {
              if (resp.header.result === "ok") {
                const data = resp.response.details
                formatYearReview(data)
                setYearly({...yearly, yearDiff: yearToSet, previous: false})
              }
            })
          break
        case "next":
          yearToSet = yearly.yearDiff - 12

          await statementsServices.statementsOverview(getPreviousDate(yearToSet + 12), getPreviousDate(yearToSet))
            .then(resp => {
              if (resp.header.result === "ok") {
                const data = resp.response.details
                formatYearReview(data)
                setYearly({...yearly, yearDiff: yearToSet, next: false})
              }
            })
          break
        default:
          break
      }
    }
    const getBiSemester = async (action) => {
      let yearToSet = 0
      let query = {}
      switch (action) {
        case "init":
          query = {
            from: {
              month: 7,
              year: getPreviousDate(12).year
            },
            to: {
              month: 6,
              year: getPreviousDate(0).year
            }
          }

          await statementsServices.statementsOverview(query.from, query.to)
            .then(resp => {
              if (resp.header.result === "ok") {
                const data = resp.response.details
                formatBiSemestrialReview(data)
              }
            })
          break
        case "previous":
          yearToSet = biSemestrial.yearDiff + 12

          query = {
            from: {
              month: 7,
              year: getPreviousDate(yearToSet + 12).year
            },
            to: {
              month: 6,
              year: getPreviousDate(yearToSet).year
            }
          }

          await statementsServices.statementsOverview(query.from, query.to)
            .then(resp => {
              if (resp.header.result === "ok") {
                const data = resp.response.details
                formatBiSemestrialReview(data)
                setBiSemestrial({...biSemestrial, yearDiff: yearToSet, previous: false})
              }
            })
          break
        case "next":
          yearToSet = biSemestrial.yearDiff - 12

          query = {
            from: {
              month: 7,
              year: getPreviousDate(yearToSet + 12).year
            },
            to: {
              month: 6,
              year: getPreviousDate(yearToSet).year
            }
          }

          await statementsServices.statementsOverview(query.from, query.to)
            .then(resp => {
              if (resp.header.result === "ok") {
                const data = resp.response.details
                formatBiSemestrialReview(data)
                setBiSemestrial({...biSemestrial, yearDiff: yearToSet, next: false})
              }
            })
          break
        default:
          break
      }
    }

    if (yearly.next) {
      getYear("next")
    } else if (yearly.previous) {
      getYear("previous")
    } else if (biSemestrial.next) {
      getBiSemester("next")
    } else if(biSemestrial.previous) {
      getBiSemester("previous")
    } else if(init) {
      getOverview()
      getBiSemester("init")
      setInit(false)
    }
  }, [yearly, biSemestrial, init])

  const handleNextYear = (val) => {
    if (val === 0) {
      setYearly({...yearly, next: true})
    } else {
      setBiSemestrial({...biSemestrial, next: true})
    }
  }
  const handlePreviousYear = (val) => {
    if (val === 0) {
      setYearly({...yearly, previous: true})
    } else {
      setBiSemestrial({...biSemestrial, previous: true})
    }
  }


  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MissingForms
          data={missingForms}
          title={dic.MISSING_FORMS}
        />
      </Grid>

      <Grid item xs={12}>
        <FormsYearOverview
          data={yearReview}
          dataBis={biSemestrialReview}
          handlePrevious={(val) => handlePreviousYear(val)}
          handleNext={(val) => handleNextYear(val)}
          title={dic.OVERALL_OVERVIEW}
        />
      </Grid>
    </Grid>
  )
}
const OverviewPilot = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        Overview not available for pilots
      </Grid>
    </Grid>
  )
}

const Overview = (props) => {
  const { userData } = props
  const classes = useStyles()

  return  (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className={classes.title}>
          {dic.ACCOUNTING_STATEMENTS_OVERVIEW}
        </Grid>
        <Grid item xs={12} sm={2} style={{textAlign: "right"}}>
          <Button color="primary" onClick={() => {history.push('/accounting_statements/all')}}>
            {dic.SEE_ALL}
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      {
        userData.actor.actor_type==="admin"
        ? <OverviewAdmin {...props} />
        : <OverviewPilot {...props} />
      }
    </Fragment>
  )
}

let OverviewConnected = connect(state => ({userData: state.user}))(Overview)
export default OverviewConnected