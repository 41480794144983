import React from 'react'

// Material UI components
import {
  Grid,
  Paper,
  Button,
  IconButton,
  TableCell,
  TableHead,
  Table, 
  TableBody,
  TableRow,
  TextField,
  TableContainer,
  InputAdornment
} from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField'

// Material Ui icons
import BackspaceIcon from '@material-ui/icons/Backspace';
import { dic } from '../../../_constants';
const DiscountsForm = (props) => {
  const { formData, setFormData } = props

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {dic.DISCOUNTS_DETAILS}
                </TableCell>
                <TableCell align="center">
                  {dic.AMOUNT}
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {
                formData.map((row, key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Grid container>
                        <Grid item xs>
                          {dic.TOTAL_DISCOUNT}
                        </Grid>
                        <Grid item xs style={{textAlign: 'right'}}>
                          <TextField
                            type="number"
                            onChange={(e) => {setFormData("update", {value: e.target.value, key, type: "percentage"})}}
                            value={row.percentage}
                            inputProps={{min: 0, style: { textAlign: 'right' }}}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        id={"amount-" + key}
                        value={row.amount}
                        currencySymbol="€"
                        decimalCharacter=","
                        decimalCharacterAlternative="."
                        digitGroupSeparator="."
                        modifyValueOnWheel={false}
                        onBlur={(event, val) => {setFormData("update", {value: val, key, type: "amount"})}}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => {setFormData("delete", { id: key })}} >
                        <BackspaceIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>

            <Button onClick={()=>setFormData("addDiscount")} style={{width: "100%"}}>{dic.ADD_DISCOUNT}</Button>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default DiscountsForm