import { setCall, handleResponse } from '../_helpers';

export const sheetItemsServices = { sheetItemsFetchAll, sheetItemInsert, sheetItemUpdate, sheetItemDelete }

async function sheetItemsFetchAll() {
  let requestOptions = {
    method: 'POST',
    body: setCall('expense_sheet_items', 'get_expense_sheet_items', {}),
    items: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function sheetItemInsert(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('expense_sheet_items', 'expense_sheet_item_insert', data),
    items: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function sheetItemUpdate(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('expense_sheet_items', 'expense_sheet_item_update', data),
    items: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function sheetItemDelete(keys) {
  const requestOptions = {
    method: 'POST',
    body: setCall('expense_sheet_items', 'expense_sheet_item_remove', keys),
    items: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}