import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types';

import {
  Tab,
  IconButton,
  Button,
  Tabs,
  Box,
  Grid,
  Typography,
  makeStyles,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TextField,
  Tooltip,
  withStyles,
  useTheme,
  Divider,
  useMediaQuery,
  ListSubheader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Select,
  InputLabel
} from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { VariableSizeList } from 'react-window';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

// Material Ui icons
import BackspaceIcon from '@material-ui/icons/Backspace';

// Dictionary
import { dic } from '../../../_constants'
// importa le nazioni in italiano da world_countries_lists
import countries from 'world_countries_lists/data/countries/it/countries.json';

import { actors_customersServices } from '../../../_services/actors_customers.services';
import { connect } from 'react-redux';
import { addNewCustomer } from '../../../_redux/_actions';

const LISTBOX_PADDING = 8; // px

const filter = createFilterOptions();

export const currencyFormatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function renderRow(props) {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  })
}

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 500
  },
  table: {
    minWidth: "850px"
  }
}))

const tableOrder = [
  { val: "customer_id", name: dic.CUSTOMER },
  { val: "total_amount", name: dic.TURNOVER_BASE_RATE },
  { val: "discount_amount", name: dic.DISCOUNT_APPLIED },
  { val: "%", name: dic.PERCENTAGE_DISCOUNT_APPLIED },
  { val: "amount", name: dic.TOTAL_DISCOUNT_TURNOVER },
  { val: "services", name: dic.NUMBER_OF_BENEFITS },
  { val: "second_pilot_services", name: dic.SECOND_PILOT },
  { val: "second_pilot_amount", name: dic.TURNOVER_SECOND_PILOT },
  { val: "miles_amount", name: dic.TOTAL_TURNOVER },
]

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  };

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

ListboxComponent.propTypes = {
  children: PropTypes.node,
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const ValidationDialog = (props) => {
  const {
    open,
    handleClose,
    handleSubmit,
    dialogValue,
    setDialogValue
  } = props

  return (
    <Dialog open={open} aria-labelledby="new-customer">
      <form onSubmit={handleSubmit}>
    <DialogTitle id="form-dialog-title">{dic.ADD_NEW_CUSTOMER}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {dic.REGISTER_CUSTOMER_INFO}
          </DialogContentText>

          <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="id"
                style={{width: "100%"}}
                value={dialogValue.id}
                onChange={(event) => setDialogValue({ ...dialogValue, id: event.target.value })}
                label={dic.ID}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                style={{width: "100%"}}
                value={dialogValue.corporate_name}
                onChange={(event) => setDialogValue({ ...dialogValue, corporate_name: event.target.value })}
                label={dic.CORPORATE_NAME}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="address"
                style={{width: "100%"}}
                value={dialogValue.address}
                onChange={(event) => setDialogValue({ ...dialogValue, address: event.target.value })}
                label={dic.ADDRESS}
                type="text"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="zip-code"
                style={{width: "100%"}}
                value={dialogValue.zip_code}
                onChange={(event) => setDialogValue({ ...dialogValue, zip_code: event.target.value })}
                label={dic.ZIP_CODE}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="city"
                style={{width: "100%"}}
                value={dialogValue.city}
                onChange={(event) => setDialogValue({ ...dialogValue, city: event.target.value })}
                label={dic.CITY}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="province"
                style={{width: "100%"}}
                value={dialogValue.province}
                onChange={(event) => setDialogValue({ ...dialogValue, province: event.target.value })}
                label={dic.PROVINCE}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel >{dic.COUNTRY}</InputLabel>
              <Select
                style={{width: "100%"}}
                value={dialogValue.country_code}
                onChange={(event) => {
                  setDialogValue({ ...dialogValue, country: countries.find(elem => elem.alpha2 === event.target.value).name, country_code: event.target.value })
                }}
              >
                {
                  countries.map(country => (
                    <MenuItem value={country.alpha2}>{country.name}</MenuItem>
                  ))
                }
              </Select>
            </Grid>

            <Grid item xs={8}>
              <Divider />
            </Grid>

            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="vat-number-code"
                style={{width: "100%"}}
                value={dialogValue.vat_number_code}
                onChange={(event) => setDialogValue({ ...dialogValue, vat_number_code: event.target.value })}
                label={dic.VAT_NUMBER_CODE}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="vat-number"
                style={{width: "100%"}}
                value={dialogValue.vat_number}
                onChange={(event) => setDialogValue({ ...dialogValue, vat_number: event.target.value })}
                label={dic.VAT_NUMBER}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="fiscal-code"
                style={{width: "100%"}}
                value={dialogValue.fiscal_code}
                onChange={(event) => setDialogValue({ ...dialogValue, fiscal_code: event.target.value })}
                label={dic.FISCAL_CODE}
                type="text"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {dic.CANCEL}
          </Button>
          <Button type="submit" color="primary">
            {dic.ADD}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const ShipDataForm = (props) => {
  const classes = useStyles();
  const {
    ships = [],
    formData = [],
    setFormData,
    customers,
    // Credit data
    creditFormData = [],
    setCreditFormData,
    actorId = 0
  } = props
  
  const [tabValue, setTabValue] = useState(0)
  const [open, toggleOpen] = useState(false)
  const [dialogValue, setDialogValue] = useState({
    actor_id: actorId,
    address: "",
    city: "",
    corporate_name: "",
    country: "",
    country_code: "",
    fiscal_code: "",
    province: "",
    vat_number_code: "",
    vat_number: "",
    zip_code: "",
    index: 0,
    id: ""
  })

  const handleChange = (val) => {
    setFormData("update", val.value, val.shipIndex, val.id, val.entry)
  }

  const addShip = (index) => {
    setFormData("add", 0, index)
  }

  const handleClose = () => {
    setDialogValue({
      actor_id: actorId,
      address: "",
      city: "",
      corporate_name: "",
      country: "",
      country_code: "",
      fiscal_code: "",
      province: "",
      vat_number_code: "",
      vat_number: "",
      zip_code: "",
      index: 0,
      id: ""
    })
    toggleOpen(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const customerData = {...dialogValue}
    delete customerData.index
    customerData.actor_id = actorId
    customerData.country_code = customerData.country_code.toUpperCase()

    if (customerData.id === "") {
      let unique = false
      do {
        customerData.id = Math.floor(Math.random()*100000000000)

        if (!customers.find(elem => elem.customer_id === customerData.id)) unique = true
      } while (!unique)
    }

    actors_customersServices.customersInsert(customerData)
    .then(resp => {
      if (resp.header.result === 'ok') {
        setFormData("update", resp.response.details.id, tabValue, dialogValue.index, "customer_id")
        setFormData("update", customerData.corporate_name, tabValue, dialogValue.index, "corporate_name")
        customerData.customer_id = customerData.id
        props.addNewCustomer(customerData)
        handleClose()
      } else {
        console.error("An error has occured")
        handleClose()
      }
    })
  }

  return (
    <Fragment>
      <ValidationDialog
        open = { open }
        handleClose = { handleClose }
        handleSubmit = { handleSubmit }
        dialogValue = { dialogValue }
        setDialogValue = { setDialogValue }
      />
      <Tabs
        variant = "scrollable"
        scrollButtons="auto"
        value = { tabValue }
        onChange = {(e, val) => setTabValue(val) }
      >
        {
          ships.map(ship => <Tab label={ship.name} id={ship.id} key={ship.name} />)
        }
        <Tab label={<span style={{color: "red"}}>{dic.CREDIT_NOTE}</span>} />
      </Tabs>

      {
        ships.map((ship, index) => {
          const newEntry = formData.find(elem => elem.id === ship.id || elem.ship_type_id === ship.id)
          return (
            <TabPanel value={tabValue} index={index} key={"ship-id_" + ship.id}>
              <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {
                        tableOrder.map(header => (
                          <TableCell align="center" key={header.name}>
                            {header.name}
                          </TableCell>
                        ))
                      }
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      newEntry
                      ? newEntry.entries.map((entry, key) => (
                        <TableRow key={key}>
                          {
                            tableOrder.map(header => {
                              if (header.val === "total_amount") {
                                return (
                                  <TableCell align="center" key={header.val + "_" + key}>
                                    {currencyFormatter.format(Number(entry.amount + entry.discount_amount))}
                                  </TableCell>
                                )
                              }
                              if (header.val === "%") {
                                return (
                                  <TableCell align="center" key={header.val + "_" + key}>
                                    {entry.amount===0?0:Math.round(entry.discount_amount * 100 / (entry.amount + entry.discount_amount))} %
                                  </TableCell>
                                )
                              }
                              if (header.val === "customer_id") {
                                return (
                                  <TableCell align="center" key={header.val + "_" + key}>
                                    <Autocomplete
                                      value = {entry.corporate_name ? entry.corporate_name : dic.WRITE_CUSTOMER_NAME}
                                      disabled = {actorId === 0}
                                      freeSolo
                                      selectOnFocus
                                      clearOnBlur
                                      disableListWrap
                                      handleHomeEndKeys
                                      options = { customers }
                                      ListboxComponent={ListboxComponent}
                                      getOptionLabel = { (option) => {
                                        if (typeof option === 'string') {
                                          return option
                                        }
                                        if (option.inputValue) {
                                          return option.inputValue
                                        }
                                        return option.corporate_name?option.corporate_name:dic.WRITE_CUSTOMER_NAME
                                      }}
                                      style = {{ width: 300 }}
                                      renderInput = {(params) => <TextField {...params} />}
                                      renderOption={(option, { inputValue }) => {
                                        const matches = match(option.corporate_name, inputValue);
                                        const parts = parse(option.corporate_name, matches);

                                        return (
                                          <HtmlTooltip placement="right" interactive title={
                                            <React.Fragment>
                                              <Grid container spacing={2} justifyContent="center">
                                                <Grid item xs={12}>
                                                  <Grid container>
                                                    <Grid item xs={12}>
                                                      {dic.ADDRESS}:
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      {option.address}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      {option.zip_code}, {option.city}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      {option.country}
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                
                                                <Grid item xs={8}>
                                                  <Divider />
                                                </Grid>

                                                <Grid item xs={12}>
                                                  {dic.VAT}: {option.vat_number_code} {option.vat_number}
                                                </Grid>
                                              </Grid>
                                            </React.Fragment>
                                          }>
                                            <Typography noWrap>
                                              {parts.map((part, index) => (
                                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                  {part.text}
                                                </span>
                                              ))}
                                            </Typography>
                                          </HtmlTooltip>
                                        )
                                      }}
                                      filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                              
                                        if (params.inputValue !== '') {
                                          filtered.push({
                                            inputValue: params.inputValue,
                                            corporate_name: `${dic.NEW} "${params.inputValue}"`,
                                          });
                                        }
                              
                                        return filtered;
                                      }}
                                      onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                          // timeout to avoid instant validation of the dialog's form.
                                          setTimeout(() => {
                                            toggleOpen(true);
                                            setDialogValue({
                                              ...dialogValue,
                                              corporate_name: newValue,
                                              index: key
                                            })
                                          })
                                        } else if (newValue && newValue.inputValue) {
                                          toggleOpen(true)
                                          setDialogValue({
                                            ...dialogValue,
                                            corporate_name: newValue.inputValue,
                                            index: key
                                          })
                                        } else {
                                          handleChange({
                                            id: key,
                                            shipIndex: index,
                                            value: (newValue && newValue.id) ? newValue.id : '',
                                            entry: "customer_id"
                                          })
                                          handleChange({
                                            id: key,
                                            shipIndex: index,
                                            value: (newValue && newValue.corporate_name) ? newValue.corporate_name : '',
                                            entry: "corporate_name"
                                          })
                                        }
                                      }}
                                    />
                                  </TableCell>
                                )
                              }
                              if (entry[header.val] !== undefined) {
                                return (
                                  <TableCell align="center" key={header.val + "_" + key}>
                                    <CurrencyTextField
                                      id={header.name + "_" + key}
                                      value={entry[header.val]}
                                      currencySymbol={(header.val==="services"||header.val==="second_pilot_services")?"":"€"}
                                      decimalPlaces={(header.val==="services"||header.val==="second_pilot_services")?0:2}
                                      decimalCharacter=","
                                      decimalCharacterAlternative="."
                                      digitGroupSeparator="."
                                      modifyValueOnWheel={false}
                                      onBlur={(event, val) => {handleChange({
                                        id: key,
                                        shipIndex: index,
                                        value: val,
                                        entry: header.val
                                      })}}
                                    />
                                  </TableCell>
                                )
                              }

                              return (
                                <TableCell align="center" key={header.val + "_" + key}></TableCell>
                              )
                            })
                          }
                          <TableCell>
                          {
                            key > 0
                            ? (
                              <IconButton onClick={() => {setFormData("delete", 0, index, key)}} key={"backspace_" + key} >
                                <BackspaceIcon />
                              </IconButton>
                            ) : null
                          }
                        </TableCell>
                        </TableRow>
                      )) : null
                    }
                  </TableBody>
                </Table>

                <Button onClick={()=>addShip(index)} style={{width: "100%"}}>{dic.ADD_CUSTOMER}</Button>
              </TableContainer>
            </TabPanel>
          )
        })
      }
      <TabPanel value={tabValue} index={ships.length} key="credit">
        <CreditForm
          formData = { creditFormData }
          customers = { customers }
          setFormData = { setCreditFormData }
          actorId = { actorId }
          {...props}
        />
      </TabPanel>
    </Fragment>
  )
}

const CreditForm = (props) => {
  const classes = useStyles();

  const {
    creditFormData = [],
    setCreditFormData,
    customers = [],
    actorId
  } = props

  const [open, toggleOpen] = useState(false)
  const [dialogValue, setDialogValue] = useState({
    actor_id: actorId,
    address: "",
    city: "",
    corporate_name: "",
    country: "",
    country_code: "",
    fiscal_code: "",
    province: "",
    vat_number_code: "",
    vat_number: "",
    zip_code: "",
    index: 0,
    id: ""
  })

  const handleClose = () => {
    setDialogValue({
      actor_id: actorId,
      address: "",
      city: "",
      corporate_name: "",
      country: "",
      country_code: "",
      fiscal_code: "",
      province: "",
      vat_number_code: "",
      vat_number: "",
      zip_code: "",
      index: 0,
      id: ""
    })
    toggleOpen(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const customerData = {...dialogValue}
    delete customerData.index
    customerData.actor_id = actorId
    customerData.country_code = customerData.country_code.toUpperCase()

    if (customerData.id === "") {
      let unique = false
      do {
        customerData.id = Math.floor(Math.random()*100000000000)

        if (!customers.find(elem => elem.customer_id === customerData.id)) unique = true
      } while (!unique)
    }

    actors_customersServices.customersInsert(customerData)
    .then(resp => {
      if (resp.header.result === 'ok') {
        setCreditFormData("update", resp.response.details.id, dialogValue.index, "customer_id")
        setCreditFormData("update", customerData.corporate_name, dialogValue.index, "corporate_name")
        customerData.customer_id = customerData.id
        props.addNewCustomer(customerData)
        handleClose()
      } else {
        console.error("An error has occured:" + resp)
        handleClose()
      }
    })
  }

  const addCredit = () => {
    setCreditFormData("add")
  }
  const handleChange = (val) => {
    setCreditFormData("update", val.value, val.index, val.key)
  }

  return (
    <TableContainer component={Paper} className={classes.container}>
      <ValidationDialog
        open = { open }
        handleClose = { handleClose }
        handleSubmit = { handleSubmit }
        dialogValue = { dialogValue }
        setDialogValue = { setDialogValue }
      />

      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              {dic.CUSTOMER}
            </TableCell>
            <TableCell>
              {dic.CREDIT_NOTE}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {
            creditFormData.map((elem, key) => (
              <TableRow>
                <TableCell>
                  <Autocomplete
                    value = {elem.corporate_name?elem.corporate_name:dic.WRITE_CUSTOMER_NAME}
                    disabled = {actorId === 0}
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    disableListWrap
                    handleHomeEndKeys
                    options = { customers }
                    ListboxComponent={ListboxComponent}
                    getOptionLabel = { (option) => {
                      if (typeof option === 'string') {
                        return option
                      }
                      if (option.inputValue) {
                        return option.inputValue
                      }
                      return option.corporate_name?option.corporate_name:dic.WRITE_CUSTOMER_NAME
                    }}
                    style = {{ width: 300 }}
                    renderInput = {(params) => <TextField {...params} />}
                    renderOption={(option, { inputValue }) => {
                      const matches = match(option.corporate_name, inputValue);
                      const parts = parse(option.corporate_name, matches);

                      return (
                        <HtmlTooltip placement="right" interactive title={
                          <React.Fragment>
                            <Grid container spacing={2} justifyContent="center">
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    {dic.ADDRESS}:
                                  </Grid>
                                  <Grid item xs={12}>
                                    {option.address}
                                  </Grid>
                                  <Grid item xs={12}>
                                    {option.zip_code}, {option.city}
                                  </Grid>
                                  <Grid item xs={12}>
                                    {option.country}
                                  </Grid>
                                </Grid>
                              </Grid>
                              
                              <Grid item xs={8}>
                                <Divider />
                              </Grid>

                              <Grid item xs={12}>
                                {dic.VAT}: {option.vat_number_code} {option.vat_number}
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        }>
                          <Typography noWrap>
                            {parts.map((part, index) => (
                              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                              </span>
                            ))}
                          </Typography>
                        </HtmlTooltip>
                      )
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
            
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          corporate_name: dic.ADD + " " + params.inputValue,
                        });
                      }
            
                      return filtered;
                    }}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                          toggleOpen(true);
                          setDialogValue({
                            ...dialogValue,
                            corporate_name: newValue,
                            index: key
                          })
                        })
                      } else if (newValue && newValue.inputValue) {
                        toggleOpen(true)
                        setDialogValue({
                          ...dialogValue,
                          corporate_name: newValue.inputValue,
                          index: key
                        })
                      } else {
                        handleChange({
                          value: newValue.id,
                          index: key,
                          key: "customer_id"
                        })
                        handleChange({
                          value: newValue.corporate_name,
                          index: key,
                          key: "corporate_name"
                        })
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <CurrencyTextField
                    value={elem.amount}
                    currencySymbol="€"
                    decimalCharacter=","
                    decimalCharacterAlternative="."
                    digitGroupSeparator="."
                    modifyValueOnWheel={false}
                    onBlur={(event, val) => {
                      handleChange({
                        value: val,
                        index: key,
                        key: "amount"
                      })
                    }}
                  />
                </TableCell>
                { 
                  key > 0
                  ? (
                    <IconButton onClick={() => {setCreditFormData("delete", 0, key)}} key={"backspace_" + key} >
                      <BackspaceIcon />
                    </IconButton>
                  ) : null
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>

      <Button onClick={()=>addCredit()} style={{width: "100%"}}>{dic.ADD_CUSTOMER}</Button>
    </TableContainer>
  )
}

let ShipDataFormConnected = connect(
  state => ({}),
  {
    addNewCustomer
  }
)(ShipDataForm)

export default ShipDataFormConnected