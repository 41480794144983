import React from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { Menu, Button, CardHeader, CardContent, Chip, Tooltip, Grid, Typography, Avatar } from '@material-ui/core/';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { dic } from '../../_constants/';
import Business from '@material-ui/icons/Business';

import {logout} from '../../_redux/_actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    
  },
}));

export function User(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userData, logout } = props;
  
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  
  function handleLogout() {
    logout();
    handleClose()
  }

  function truncateDescription(str){
    return str.length > 20 ? str.substring(0, 17) + "..." : str;
  }
  
  return (
    // se l'utente è autenticato
    userData.isLogged
    ?
      <div>
        <Tooltip title={userData.user.name+' '+userData.user.surname}>
          <Chip 
            color="primary"
            label={truncateDescription(userData.user.name+' '+userData.user.surname)}
            onClick={handleClick}
            icon={<AccountCircle />}
          />
        </Tooltip>
        <Menu
          id="main-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
        <CardHeader
        classes={{root: classes.header}}
        avatar={<Avatar><Business /></Avatar>}
        title={<Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{userData.actor.corporate_name}</Typography>}
        subheader={dic.USER + ": " +userData.user.surname + " " + userData.user.name}
        />
        <CardContent>
            <Grid container spacing={2}>
              <Grid item={true}>
                <Link style={{ textDecoration: 'none' }} to={"/actors/" + userData.actor.id + "/users/" + userData.user.id}>
                  <Button type="button" variant="contained" color="primary" onClick={handleClose} >
                    {dic.USER}
                  </Button>
                </Link>
              </Grid>
              <Grid item={true}>
                <Link style={{ textDecoration: 'none' }} to={"/actors/" + userData.actor.id}>
                  <Button type="button" variant="contained" color="primary" onClick={handleClose} >
                    {dic.ACTOR}
                  </Button>
                </Link>
              </Grid>
              <Grid item={true}>
                <Link style={{ textDecoration: 'none' }} to="/">
                  <Button type="button" variant="contained" color="secondary" onClick={handleLogout} >
                    {dic.LOGOUT}
                  </Button>
                </Link>
              </Grid>
            </Grid>
        </CardContent>
      </Menu>
      </div>
    :<div></div>
  );
}

let UserConnected = connect(state => ({userData: state.user}), {logout})(User)
export default (UserConnected)