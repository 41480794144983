import React, { forwardRef, useEffect, useState }  from 'react';

import MaterialTable from 'material-table'

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
}  from '@material-ui/icons';

import { surchargesServices } from '../../_services/surcharges.services'
import { dic, dicTypes } from '../../_constants/dictionary.constants'

import { useSnackbar  } from 'notistack';



function SurchargesTable() {
    const { enqueueSnackbar } = useSnackbar();
    const [fetchingData, setFetchingData] = useState(false)
    const [surchargesList, setSurchargesList] = useState([])

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };
      
      const parameters = [
        { title: dic.ID, field: 'id', editable: "never"},
        { title: dic.NAME, field: 'name', validate: rowData => rowData.name !== '' && rowData.name !== undefined, cellStyle: {color: "#1E7DA5"}},
        {
          title: dic.SPECIFIC_RISK,
          field: 'is_specific_risk',
          lookup: { 0: dic.NO, 1: dic.YES },
        }
      ]
      
    const responseNotification = (data) => {
        if (data.header.result === 'ok'){
            enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
            getSurcharges();
        }
        else{
            for (var i in data.response.details)
                enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
            setFetchingData(false)
        }
    }

    const responseTextNotification = (response) => {
        switch (response.header.function){
            case 'surcharge_insert':
                return dic.SURCHARGE_INSERTED
            case 'surcharge_update':
                return dic.SURCHARGE_UPDATED
            case 'surcharge_remove':
                return dic.SURCHARGE_DELETED
            default:
                return
        }
    }

    const getSurcharges = () => {
        setFetchingData(true)
        surchargesServices.surchargesFetchAll()
            .then(resp => {
                setSurchargesList(resp.response.details)
                setFetchingData(false)
            })
    }

    useEffect(() => {
        getSurcharges();
    }, [])

    return (
        <MaterialTable
            title={dic.SURCHARGES}
            icons={tableIcons}
            columns={parameters}
            isLoading={fetchingData}
            data={surchargesList}  
            options={{actionsColumnIndex: -1, exportButton: true, exportAllData: true}}
            localization={dicTypes._TABLES_LOCALIZATION_}
            editable={{
                onRowAdd: newData => {
                    return new Promise((resolve, reject) => {
                        if (!newData.name)
                            return reject();
                        
                        surchargesServices.surchargeInsert(newData)
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                            })
                    })
                },
                onRowUpdate: (newData, oldData) => {
                    return new Promise((resolve, reject) => {
                        if (!newData.name)
                            return reject();
                        newData.surcharge_id = oldData.id
                        surchargesServices.surchargeUpdate(newData)
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                        })
                    })
                },
                onRowDelete: oldData =>{
                    return new Promise((resolve, reject) => {
                        surchargesServices.surchargeDelete({surcharge_id: oldData.id})
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                        })
                    })
                },
            }}
        />
    )
}
export default (SurchargesTable)