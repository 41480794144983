import React from 'react';
import { Grid } from '@material-ui/core';

import HarboursTable from './harbours/Table'

export default function Harbours(props) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          <HarboursTable actorId={props.match.params.id ? props.match.params.id : null}/>
        </Grid>
      </Grid>
    )
}