import React, { useState, Fragment, useEffect } from 'react'
import { connect } from 'react-redux'

// Material UI components
import {
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stepper,
  Step,
  StepLabel
} from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useSnackbar } from 'notistack';

// Personal components
import LoadingDialog from '../utils/Loading'
import AccountingForm from './formComponents/AccountingForm'
import ExpenseForm from './formComponents/ExpenseForm'
import SharesForm from './formComponents/SharesForm'

// Dictionary
import { dic } from '../../_constants'

// Personal libraries
import { history } from '../../_helpers/history'
import { statementsServices, partnerServices } from '../../_services';
import { checkLocalStorage, getPreviousMonth, handleExcel, resetStorage } from '../../_helpers';
import { isInteger } from '@antv/util';

function getSteps() {
  return [
    dic.ACCOUNTING_STATEMENT,
    dic.EXPENSES_SHEET,
    dic.SHARES
  ];
}

const StatementsForm = (props) => {
  // Form hooks
    // * Accounting
    const [accountingForm, setAccountingForm] = useState({})
    const [actors, setActors] = useState([])

    // * Expenses
    const [expensesForm, setExpensesForm] = useState([])
    const [sections, setSections] = useState(null)

    // * Shares
    const [sharesForm, setSharesForm] = useState([])
    const [partners, setPartners] = useState([])

  // Used to know what to show on screen
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  // Excel
  const [excelDialog, setExcelDialog] = useState(false)
  const [isExcel, setIsExcel] = useState(false)

  // Loading hook
  const [isLoading, setIsLoading] = useState({
    global: false,
    components: {
      accounting: false,
      expenses: false,
      shares: false
    }
  })

  // Errors hook
  const [errors, setErrors] = useState({accounting: false, expenses: false, shares: false})
  const { enqueueSnackbar } = useSnackbar()
  const [expensensNotesErrors, setExpensensNotesErrors] = useState(false)
  useEffect(() => {
    console.log(expensensNotesErrors)
  }, [expensensNotesErrors])
  
  // Misc hooks
  const [init, setInit] = useState(true)
  const [prevParam, setPrevParam] = useState(null)
  const [globalTotal, setGlobalTotal] = useState(0)
  const [globalTotalShares, setGlobalTotalShares] = useState(0)
  /**
   * Constants outside of hooks
   */
  // Change of text depending on the type of action
  const subtitle = props.match.params.id==="new"?dic.CREATE_STATEMENT:dic.UPDATE_STATEMENT
  const actionButton = props.match.params.id==="new"?dic.CREATE_STATEMENT:dic.UPDATE_STATEMENT
  const stepslabel = {
    [dic.ACCOUNTING_STATEMENT]: 'accounting',
    [dic.EXPENSES_SHEET]: 'expenses',
    [dic.SHARES]: 'shares'
  }

  useEffect(() => {
    const checkPathParameters = () => {
      if (props.userData.actor.actor_type !== "admin" && props.match.params.id !== "new" && props.match.params.id !== props.userData.actor.id) {
        history.push("/accounting_statements/all")
        return false
      }

      return true
    }
    const checkInit = () => {
      if (props.match.params !== prevParam) {
        setPrevParam(props.match.params)
        return true
      } 
      return init
    }

    // Funtions used to do the async fetching from the DB
    const fetchActors = () => { setActors(props.actorsData) }
    const fecthSections = (type = "new") => {
      const cat = props.statementsData.expenses.sections
      const formPattern = [...props.statementsData.expenses.formPattern]

      setSections(cat)
      if (type !== "localStorage") {
        setExpensesForm(formPattern)
      }

      if (type === "update") {
        return formPattern
      }
      return
    }
    
    const fetchPartners = async () => {
      setLoading('shares', true)
      await partnerServices.partnersFetchAll(props.userData.actor.actor_type==="admin" ? {actor_id: accountingForm.actor_id} : '')
        .then(resp => {
          if (resp.header.result === 'ok') {
            setPartners(resp.response.details)
          } else {
            responseNotification(resp)
          }
          setLoading('shares', false)
        })
    }

    // Functions used for form initialisation and form reset
    const initAccounting = async (type = "new") => {
      setLoading('accounting', true)
      const date = getPreviousMonth()

      //const default_expenses_notes = {0: {number: "", name: "", amount: ""}, 1: {number: "", name: "", amount: ""}, 2: {number: "", name: "", amount: ""}}
      let accountingData = {
        year: date.year,
        month: (date.month + 1) < 10 ? '0'+(date.month + 1) : date.month + 1,
        actor_id: props.match.params.id==="new" ?  props.userData.actor.actor_type === "admin" ? 0 : props.userData.actor.id : props.match.params.id,
        credits: 0,
        turnover_lt_2000: 0,
        turnover_gt_2000: 0,
        incomes_lt_2000: 0,
        incomes_gt_2000: 0,
        fund_18_perc: 0,
        fund_25_perc: 0,
        extra_incomes: 0,
        expenses_notes: {}
      }
      let dataToReturn = {}

      switch (type) {
        case "new":
          setAccountingForm(accountingData)
          setLoading('accounting', false)
          return
        case "update":
          await statementsServices.statementsSearch(
            props.userData.actor.actor_type==="admin"?props.match.params.id:"",
            props.match.params.month,
            props.match.params.year
          ).then(resp => {
            const data = resp.response.details

            if (resp.header.result === 'ok') {
              accountingData = {
                year: Number(data.year),
                month: Number(data.month) < 10 ? "0" + Number(data.month) : Number(data.month),
                actor_id: Number(data.actor_id),
                credits: Number(data.credits),
                turnover_lt_2000: Number(data.turnover_lt_2000),
                turnover_gt_2000: Number(data.turnover_gt_2000),
                incomes_lt_2000: Number(data.incomes_lt_2000),
                incomes_gt_2000: Number(data.incomes_gt_2000),
                fund_18_perc: Number(data.fund_18_perc),
                fund_25_perc: Number(data.fund_25_perc),
                extra_incomes: Number(data.extra_incomes),
                expenses_notes: data.expenses_notes
              }

              setAccountingForm(accountingData)
            } else {
              responseNotification(resp)
            }

            setLoading('accounting', false)
            dataToReturn = data
          })
          break
        default:
          setAccountingForm(accountingData)
          setLoading('accounting', false)
          return
      }

      if (type === "update") {
        return dataToReturn
      }
    }
    const initExpenses = async (type = "new", data = {}) => {
      setLoading('expenses', true)
      switch (type) {
        case "new":
          if (sections) {
            // console.log("exists")
          } else {
            fecthSections()
          }
          break
        case "update":
          let formPattern = [...expensesForm]
          if (formPattern.length > 0) {
            // console.log("exists")
          } else {
            formPattern = await fecthSections("update")

            for (let i = 0 ; i < data.expenses.length ; i++) {
              formPattern[i].amount = Number(data.expenses[i].amount)
            }
            
            setExpensesForm(formPattern)
          }
          break
        default:
          break
      }
      setLoading('expenses', false)
    }
    const initShares = async (type = "new", data = {}) => {
      setLoading('shares', true)
      let formattedShares = []

      switch (type) {
        case "new":
          if (props.userData.actor.actor_type === "admin") {
            setSharesForm([])
          } else {
            await statementsServices.lastStatementsSearch()
              .then(resp => {
                if (resp.header.result === 'ok') {
                  const shares_data = resp.response.details?resp.response.details.shares:[]
                  for (let i = 0 ; i < shares_data.length ; i ++) {
                    const shareData = shares_data[i]
                    formattedShares.push({...shareData, share: Number(shareData.share), amount: Number(shareData.amount)})
                  }
                  setSharesForm(formattedShares)
                } else {
                  responseNotification(resp)
                }
              })
          }
          break
        case "update":
          for (let i = 0 ; i < data.shares.length ; i ++) {
            const shareData = data.shares[i]
            formattedShares.push({...shareData, share: Number(shareData.share), amount: Number(shareData.amount)})
          }
          setSharesForm(formattedShares)
          break
        case "newActor":
          await statementsServices.lastStatementsSearch(accountingForm.actor_id)
          .then(resp => {
            const shares_data = resp.response.details?resp.response.details.shares:[]
            for (let i = 0 ; i < shares_data.length ; i ++) {
              const shareData = shares_data[i]
              formattedShares.push({...shareData, share: Number(shareData.share), amount: Number(shareData.amount)})
            }


            let totalShares = 0
            for (let i = 0 ; i < formattedShares.length ; i++) {
              totalShares += formattedShares[i].share
            }
  
            let pricePerShare = globalTotal / totalShares
  
            for (let i = 0 ; i < formattedShares.length ; i++) {
              formattedShares[i].amount = formattedShares[i].share * pricePerShare
            }

            setSharesForm(formattedShares)

            if (props.match.params.id==="new") {
              localStorage.setItem('shares_form', JSON.stringify(formattedShares))
            }
          })
          break
        default:
          break
      }
      setLoading('shares', false)
    }
    const initUpdate = async () => {
      const statementData = await initAccounting("update")

      initExpenses("update", statementData)
      initShares("update", statementData)
    }

    if (checkPathParameters() && checkInit()) {
      fetchActors()
      fetchPartners()
      setActiveStep(0)
      setIsExcel(false)

      if (props.match.params.id==="new") {
        const localStorageData = checkLocalStorage()

        localStorageData.accountingData?setAccountingForm(localStorageData.accountingData):initAccounting()
        if (localStorageData.expensesData) {
          fecthSections("localStorage")
          setExpensesForm(localStorageData.expensesData)
        } else {
          initExpenses()
        }
        localStorageData.sharesData?setSharesForm(localStorageData.sharesData):initShares()
      } else {
        initUpdate()
      }
      setInit(false)
    } else {
      fetchPartners()
      if (props.match.params.id==="new") {
        if (!isExcel) initShares("newActor")
      } else {
        initUpdate()
      }
    }
  }, [accountingForm.actor_id, props.match.params, isExcel])

  const handleSubmit = (e) => {
    e.preventDefault()
  }
  const handleNavigation = (direction) => {
    if (direction === "back") {
      if (activeStep > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      } else {
        history.push('/accounting_statements/all')
      } 
    } else {
      if (activeStep < steps.length - 1) {
        calculateTotal()
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        sendFormToDatabase()
      }
    }
  }

  const setAccountingData = (data) => {
    let  accounting_transit = {...accountingForm}
    
    if (data.id === "date") {
      accounting_transit.month = (data.value.getMonth()) + 1 < 10 ? '0'+(data.value.getMonth() + 1) : data.value.getMonth() + 1
      accounting_transit.year = data.value.getFullYear()
    } else {
      accounting_transit[data.id] = data.value
    }

    if (data.id === "incomes_lt_2000") {
      accounting_transit.fund_18_perc = Math.round((data.value * 0.18 + Number.EPSILON)*100) / 100
    }
    if (data.id === "incomes_gt_2000") {
      accounting_transit.fund_25_perc = Math.round((data.value * 0.25 + Number.EPSILON)*100) / 100
    }
    
    setAccountingForm(accounting_transit)

    if (props.match.params.id==="new") {
      localStorage.setItem('accounting_form', JSON.stringify(accounting_transit))
    }
  }
  const setExpensesData = (data) => {
    let expenses_transit = [...expensesForm]
    expenses_transit[data.id].amount = data.value
    setExpensesForm(expenses_transit)

    if (props.match.params.id==="new") {
      localStorage.setItem('expenses_form', JSON.stringify(expenses_transit))
    }
  }
  const setSharesData = (type, data) => {
    let shares_transit = [...sharesForm]
    let totalShares = 0
    let pricePerShare = 0

    switch(type) {
      case "addPartner":
        setSharesForm([...sharesForm, {
          position: 1,
          share: 0,
          amount: 0,
          partner_id: data.id
        }])
        break
      case "calculate":
        for (let i = 0 ; i < shares_transit.length ; i++) {
          totalShares += shares_transit[i].share
        }
        setGlobalTotalShares(totalShares)
        pricePerShare = globalTotal / totalShares

        for (let i = 0 ; i < shares_transit.length ; i++) {
          shares_transit[i].amount = shares_transit[i].share * pricePerShare
        }
        setSharesForm(shares_transit)
        break
      case "update":
        shares_transit[data.id][data.field] = data.value

        if (data.field === "share") {
          for (let i = 0 ; i < shares_transit.length ; i++) {
            totalShares += shares_transit[i].share
          }
          setGlobalTotalShares(totalShares)
          pricePerShare = globalTotal / totalShares

          for (let i = 0 ; i < shares_transit.length ; i++) {
            shares_transit[i].amount = shares_transit[i].share * pricePerShare
          }
        }
        setSharesForm(shares_transit)
        break
      case "delete":
        shares_transit.splice(data.id, 1)

        for (let i = 0 ; i < shares_transit.length ; i++) {
          totalShares += shares_transit[i].share
        }
        setGlobalTotalShares(totalShares)
        pricePerShare = globalTotal / totalShares

        for (let i = 0 ; i < shares_transit.length ; i++) {
          shares_transit[i].amount = shares_transit[i].share * pricePerShare
        }

        setSharesForm(shares_transit)
        break
      default:
        break
    }

    if (props.match.params.id==="new") {
      localStorage.setItem('shares_form', JSON.stringify(shares_transit))
    }
  }

  const setLoading = (componentName = "", value = false) => {
    let loading_transit = {...isLoading}

    if (componentName === "") {
      console.error('You need to register the name of the component (accounting, expenses or shares)')
    } else {
      loading_transit.global = false
      loading_transit.components[componentName] = value

      for (let entry in isLoading.components) {
        if (isLoading.components[entry]) {
          loading_transit.global = true
        }
      }

      setIsLoading(loading_transit)
    }
  }
  const calculateTotal = () => {
    let accountingTotal =
      accountingForm.incomes_lt_2000
      + accountingForm.incomes_gt_2000
      - accountingForm.fund_18_perc
      - accountingForm.fund_25_perc
      + accountingForm.extra_incomes
    let expensesTotal = expensesForm.reduce((acc, val) => acc + val.amount, 0)

    setGlobalTotal(accountingTotal - expensesTotal)
  }
  const createNewPartner = (data) => {
    setLoading('shares', true)
    partnerServices.partnerInsert({...data, actor_id: accountingForm.actor_id})
    .then(() => {
      setSharesData("addPartner", {id: data.id})
      setPartners([...partners, {...data, actor_id: accountingForm.actor_id}])
      setLoading('shares', false)
    })
  }
  const handleExcelUpload = async (file) => {
    await handleExcel(file, expensesForm, partners)
      .then(resp => {
        if (
          resp.accounting.error
          || resp.expenses.error
          || resp.shares.error
        ) {
          enqueueSnackbar(dic.EXCEL_ERROR, { variant: 'error', persist: false, })
        } else {
          setIsExcel(true)
          setAccountingForm({...resp.accounting.data, month: (resp.accounting.data.month) < 10 ? "0" + resp.accounting.data.month : resp.accounting.data.month})
          setExpensesForm(resp.expenses.data)
          setSharesForm(resp.shares.data)
        }

        setExcelDialog(false)
      })
  }
  const responseNotification = (data) => {
    if (data.header.result === 'ok')
      enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
    else
      for (var i in data.response.details)
        enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
  }
  const responseTextNotification = (response) => {
    switch (response.header.function){
      case 'accounting_statement_insert':
        return dic.ACCOUNTING_STATEMENT_INSERT
      case 'accounting_statement_update':
        return dic.ACCOUNTING_STATEMENT_UPDATED
      case 'accounting_statement_remove':
        return dic.ACCOUNTING_STATEMENT_DELETED
      default:
        return
    }
  }

  const sendFormToDatabase = () => {
    setIsLoading({...isLoading, global: true})
    let newShares = []
    for (let i = 0 ; i < sharesForm.length ; i++) {
      newShares.push({
        partner_id: sharesForm[i].partner_id,
        position: sharesForm[i].position,
        share: sharesForm[i].share,
        amount: Math.round((sharesForm[i].amount + Number.EPSILON) * 100) / 100,
      })
    }

    const dataToServer = {
      ...accountingForm,
      expenses: expensesForm,
      shares: newShares
    }

    if (props.match.params.id === "new") {
      // Need to create a new accounting statement
      statementsServices.statementsInsert(dataToServer)
      .then((resp) => {
        if (resp.header.result === 'ok') {
          setIsLoading({...isLoading, global: false})
          resetStorage('statements')
          enqueueSnackbar(dic.ACCOUNTING_STATEMENT_INSERTED, { variant: 'success', persist: false, })
          history.push('/accounting_statements/all')
        } else {
          responseNotification(resp)
          setIsLoading({...isLoading, global: false})
        }
      })
    } else {
      // Need to update an existing accounting statement
      statementsServices.statementsUpdate(accountingForm.actor_id, accountingForm.month, accountingForm.year, dataToServer)
      .then((resp) => {
        if (resp.header.result === 'ok') {
          setIsLoading({...isLoading, global: false})
          resetStorage('statements')
          enqueueSnackbar(dic.ACCOUNTING_STATEMENT_UPDATED, { variant: 'success', persist: false, })
          history.push('/accounting_statements/all')
        } else {
          responseNotification(resp)
          setIsLoading({...isLoading, global: false})
        }
      })
    }
  }

  
  return (
    <Fragment>
      <DropzoneDialog
        open={excelDialog}
        onSave={handleExcelUpload}
        acceptedFiles={['.xlsx']}
        onClose={() => setExcelDialog(false)}
        filesLimit={1}
        dropzoneText={dic.EXCEL_DROPZONE}
        previewText={dic.PREVIEW_EXCEL}
        cancelButtonText={dic.CANCEL}
        submitButtonText={dic.SUBMIT}
      />
      
      <Card>
        <CardHeader
          subheader={subtitle}
          title={dic.ACCOUNTING_STATEMENT}
        />

        <Divider />

        <CardContent>
          {
            props.userData.actor.actor_type !== "admin" && props.match.params.id === "new"
            ? (
              <Grid container>
                <Grid item xs={11}>
                  <Button color="primary" onClick={() => setExcelDialog(true)}>{ dic.UPLOAD_EXCEL }</Button>
                </Grid>
              </Grid>
            )
            : null
          }
          
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={11}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => {
                    const labelProps = {}
                    if (errors[stepslabel[label]]) {
                      labelProps.error = true;
                    }
                    return (
                      <Step key={label}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    )
                  })}
                </Stepper>
                  {
                    activeStep === 0
                    ? (
                      <AccountingForm
                        actor_type = { props.userData.actor.actor_type }
                        formData = { accountingForm }
                        actors = { actors }
                        setFormData = { setAccountingData }
                        {...props}
                      />
                    )
                    : activeStep === 1
                      ? (
                        <ExpenseForm
                          formData = { expensesForm }
                          sections = { sections }
                          setFormData = { setExpensesData }
                          setAccountingData = { setAccountingData }
                          accountingData = { accountingForm }
                          setExpensensNotesErrors={setExpensensNotesErrors}
                          expensensNotesErrors={expensensNotesErrors}
                        />
                      )
                      : (
                        <SharesForm
                          formData = { sharesForm }
                          partners = { partners }
                          setFormData = { setSharesData }
                          total = { globalTotal }
                          totalShares = { globalTotalShares }
                          createNewPartner = { createNewPartner }
                        />
                      )
                  }
                </Grid>
              </Grid>
          </form>
        </CardContent>

        <CardActions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={12}>
              <Button
                color="primary"
                onClick={() => handleNavigation('back')}
              >
                { activeStep === 0 ? dic.BACK_TABLE : dic.BACK }
              </Button>
              <Button disabled={accountingForm.actor_id === 0 || (activeStep === 1 && expensensNotesErrors)} size="large" color="secondary" onClick={() => handleNavigation('next')}>
                { activeStep === steps.length - 1 ? actionButton : dic.NEXT }
              </Button>
            </Grid>
            {
              accountingForm.actor_id === 0
              ? (
                <Grid item xs={12}>
                  {dic.ACTOR_SELECT}
                </Grid>
              )
              : null
            }
          </Grid>
        </CardActions>
      </Card>

      <LoadingDialog open={isLoading.global} />
    </Fragment>
  )
}

let StatementsFormConnected = connect(
  state => ({
    userData: state.user,
    statementsData: state.statements,
    actorsData: state.actors
  })
)(StatementsForm)
export default StatementsFormConnected