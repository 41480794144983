import React from 'react'

// Material UI components
import { Card, CardHeader, CardContent, Button, CardActions } from '@material-ui/core'
import { 
    Chart,
	Interval,
	Coordinate,
	Legend,
	View,
	Annotation
} from 'bizcharts'
import {
    Visibility,
    AddBox,
    ListAlt,
  } from '@material-ui/icons/';

import { history } from '../../_helpers'
import { dic } from '../../_constants'

const LastMonthOverview = (props) => {
    // const brandFill = getTheme().colors10[0];
    const {title, data, date, urls, userData, numbers} = props
    
    const content = {
        siteCode: date.year,
        title: dic.MONTHS[date.month],
        percent: numbers.received+'/'+numbers.total,
    };

    const intervalConfig = {}
  return (
    <Card>
        <CardHeader
        title= {title}
        />
        <CardContent>
            <Chart placeholder={false} height={200} padding="auto" autoFit>
                <Legend visible={false} />
                <View
                    data={data}
                    scale={{
                        percent: {
                            formatter: (val) => {
                                return (val * 100).toFixed(2) + "%";
                            },
                        },
                    }}
                >
                    <Coordinate type="theta" innerRadius={0.75} />
                    <Interval
                        position="percent"
                        adjust="stack"
                        // color="type"
                        color={["type", ["rgba(30, 125, 165, 0.6)", "#eee"]]}
                        //color={["type", [brandFill, "#eee"]]}
                        size={16}
                        // style={{ fillOpacity: 0.6 }}
                        // label={['type', {offset: 40}]}
                        {...intervalConfig}
                    />
                    <Annotation.Text
                        position={["50%", "35%"]}
                        content={content.siteCode}
                        style={{
                            lineHeight: "240px",
                            fontSize: "16",
                            fill: "#000",
                            textAlign: "center",
                        }}
                    />
                    <Annotation.Text
                        position={["50%", "48%"]}
                        content={content.title}
                        style={{
                            lineHeight: "240px",
                            fontSize: "16",
                            fill: "#000",
                            textAlign: "center",
                        }}
                    />
                    <Annotation.Text
                        position={["50%", "62%"]}
                        content={content.percent}
                        style={{
                            lineHeight: "240px",
                            fontSize: "24",
                            fill: "#1E7DA5",
                            textAlign: "center",
                        }}
                    />
                </View>
            </Chart>
        </CardContent>
        <CardActions>
            {userData.actor.actor_type === 'admin' ?
            <Button color="primary" onClick={() => {history.push(urls.overview)}} startIcon={<Visibility fontSize="small" />}>
                {dic.OVERVIEW}
            </Button>
            : null
            }
            <Button color="primary" onClick={() => {history.push(urls.new)}} startIcon={<AddBox fontSize="small" color="secondary" />}>
                {dic.NEW}
            </Button>
            <Button color="primary" onClick={() => {history.push(urls.all)}} startIcon={<ListAlt fontSize="small" />}>
                {dic.ALL}
            </Button>
        </CardActions>
    </Card>
  )
}

export default LastMonthOverview