import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
/*eslint eqeqeq: "off"*/

// Material UI components
import { Card, CardHeader, CardContent, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { Divider } from '@material-ui/core'

import { dic } from '../../_constants'

const DialogList = (props) => {
  const {
    title = "Missing forms",
    open = false,
    setOpen,
    data = {
      month: 0,
      year: 0,
      actors_statements: []
    },
    actors = []
  } = props

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle style={{textAlign: "center"}}>{title} ({dic.MONTHS[data.month]} {data.year})</DialogTitle>

      <DialogContent>
        <Grid container spacing={2} justifyContent="center">
          {
            data?.actors_statements
            ? data.actors_statements.map((actor, i) => {
                if (actor.hasForm) {
                  return null
                } else {
                  return (
                    <Fragment>
                      <Grid item xs={12} style={{textAlign: "center"}}>
                        { actors.find(elem => elem.id == actor.id).corporate_name }
                      </Grid>
                      {
                        i < data.actors_statements.length -1
                        ? (
                          <Grid item xs={8}>
                            <Divider />
                          </Grid>
                        )
                        : null
                      }
                    </Fragment>
                  )
                }
              })
            : null
          }
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => {handleClose()}}>{dic.CLOSE}</Button>
      </DialogActions>
    </Dialog>
  )
}

const MissingForms = (props) => {
  const {
    title = "Missing forms",
    data = [],
    maxEntriesShown = 5
  } = props

  const [dialog, setDialog] = useState(false)
  const [listSelected, setListSelected] = useState([])

  const openDialog = (info) => {
    setListSelected(info)
    setDialog(true)
  }

  return (
    <Fragment>
      <DialogList
        title = { title }
        open = { dialog }
        setOpen = { setDialog }
        data = { listSelected }
        actors = { props.actorsData }
      />

      <Card>
        <CardHeader
          title = { title }
        />

        <CardContent>
          <Grid container justifyContent="center" spacing={2}>
            {
              data.map(entry => {
                let index = 0
                return (
                  <Grid item xs={12} sm={4}>
                    <Card style={{height: "100%"}}>
                      <CardContent>
                        <Grid container spacing={1} justifyContent="center">
                          <Grid item xs={12}>
                            { dic.MONTHS[entry.month]} {entry.year}
                          </Grid>
                          <Grid item xs={8}>
                            <Divider />
                          </Grid>
                          {
                            entry.actors_statements?.map((actor, i) => {
                              if (actor.hasForm) {
                                return null
                              } else {
                                index += 1
                                return index <= maxEntriesShown
                                ? (
                                  <Fragment>
                                    <Grid item xs={12} style={{textAlign: "left"}}>
                                      { props.actorsData.find(elem => elem.id == actor.id)?.corporate_name }
                                    </Grid>
                                    {
                                      i < entry.actors_statements.length -1 && index < maxEntriesShown
                                      ? (
                                        <Grid item xs={8}>
                                          <Divider />
                                        </Grid>
                                      )
                                      : null
                                    }
                                  </Fragment>
                                )
                                : i === entry.actors_statements.length -1 
                                  ? (
                                    <Fragment>
                                      <Grid item xs={12} style={{textAlign: "right"}}>
                                        <Button onClick={() => openDialog(entry)}>{dic.SEE_ALL}</Button>
                                      </Grid>
                                    </Fragment>
                                  ) : null
                              }
                            })
                          }
                          {
                            index === 0
                            ? (
                              <Grid item xs={12}>
                                {dic.NO_MISSING_FORMS}
                              </Grid>
                            ) : null
                          }
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              })
            }
            
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  )
}

let MissingFormsConnected = connect(state => ({actorsData: state.actors}))(MissingForms)
export default MissingFormsConnected