import React, { Fragment, useState, useEffect } from 'react'

// Material UI components
import {
  Collapse,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

// Material UI icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpenseNoteForm from './ExpenseNoteForm';

// Dictionary
import { dic } from '../../../_constants'

const ExpandIcon = (props) => {
  const { open, row, handleOpen, ...rest } = props

  return (
    open[row] 
    ? <IconButton onClick={() => handleOpen(row)} {...rest}><ExpandLess /></IconButton>
    : (
      <Fragment>
        <IconButton onClick={() => handleOpen(row)} {...rest}><ExpandMore /></IconButton>
        <Typography color="primary" variant="body2">
          ({ dic.EXPAND })
        </Typography>
      </Fragment>
    )
  )
}

const ExpenseForm = (props) => {
  const {
    formData = [],
    sections = [],
    setFormData,
    accountingData,
    setAccountingData,
    setExpensensNotesErrors,
    expensensNotesErrors
  } = props

  const [open, setOpen] = useState([null, true])
  const [selectedlRow, setSelectedRow] = useState(null);
  
  useEffect(() => {
    let transit = [null]
    for (let i = 1 ; i <= sections.length ; i++) {
      transit.push(true)
    }
    setOpen(transit)
  }, [])

  const handleOpen = (row) => {
    let transit = [...open]
    transit[row] = !open[row]
    setOpen(transit)
  }

  return (
    <Grid container>
      {
        sections.map(header => {
          let headerTotal = 0
          let subheadersTotals = {}
          var actualSubHeader = null
          return (
          <Fragment key={header.id}>
            <Grid item xs={12} id={header.id}>
              <Typography color="primary" variant="h6" style={{textAlign: 'left', marginTop: '20px'}}>
                {header.order_field}. {header.name} <ExpandIcon id={'title_' + header.id} open={open} row={header.id} handleOpen={handleOpen} />
              </Typography>
            </Grid>

            <Collapse in={open[header.id]} style={{width: "100%"}}>
              {header.items.map((item, index) => {
                headerTotal += formData[item.id - 1].amount
                if (!subheadersTotals[item.expense_sheet_subheader_id])
                    subheadersTotals[item.expense_sheet_subheader_id] = 0
                
                subheadersTotals[item.expense_sheet_subheader_id] += formData[item.id - 1].amount
                
                return (
                  <Fragment>
                  {actualSubHeader !== item.expense_sheet_subheader_id &&
                    <Fragment>
                    {actualSubHeader &&
                      <Grid key={header.id + "_" +item.id} container>
                        <Grid item xs={10} style={{ textAlign: 'right', marginTop: '5px' }}>
                          <Typography color="primary" variant="subtitle2">{dic.TOTAL}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography color="primary" variant="subtitle2">
                           
                            <CurrencyTextField
                              value={subheadersTotals[actualSubHeader]}
                              currencySymbol="€"
                              decimalCharacter=","
                              decimalCharacterAlternative="."
                              digitGroupSeparator="."
                              disabled
                              color="primary"
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                      <Typography color="primary" variant="subtitle2" style={{textAlign: 'left'}}>
                      <span style={{display:"none"}}>{actualSubHeader = item.expense_sheet_subheader_id}</span>
                      {item.subheader_order_field} {item.subheader_name}
                      </Typography>
                    </Fragment>
                  }
                  <Grid key={header.id + "_" +item.id} container>
                    <Grid item xs={10}>
                      <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px', paddingBottom: 1, borderBottom: (item.id === selectedlRow) ? '2px solid #1E7DA5' : '0px'}} color={item.id === selectedlRow ? 'primary' : 'inherit'}>
                        
                        {item.description_required > 0 ? 
                          <ExpenseNoteForm
                            sections = { sections }
                            headerId = { header.id }
                            setAccountingData = { setAccountingData }
                            accountingData = { accountingData }
                            others = {header.items[(header.items.length-1)]}
                            formData = { formData }
                            item={item}
                            itemValue={formData[item.id - 1].amount}
                            setExpensensNotesErrors={setExpensensNotesErrors}
                            expensensNotesErrors={expensensNotesErrors}
                          />
                        : <Fragment>{item.subheader_order_field}.{item.order_field} {item.name}</Fragment>
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <CurrencyTextField
                        id={"expenseForm_" + header.id + "-" + item.expense_sheet_item_id}
                        value={formData[item.id - 1].amount}
                        currencySymbol="€"
                        decimalCharacter=","
                        decimalCharacterAlternative="."
                        digitGroupSeparator="."
                        modifyValueOnWheel={false}
                        onBlur={(event, val) => {setFormData({
                          id: item.id - 1,
                          value: val
                        }); setSelectedRow(null)}}
                        onFocus={() => setSelectedRow(item.id)}
                      />
                    </Grid>
                  </Grid>
                  </Fragment>
                )
              })}
            </Collapse>

            <Grid item xs={10}>
              <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px', fontWeight: 'bold'}}>
                {dic.TOTAL}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography style={{textAlign: 'right', marginLeft: '20px', marginTop: '5px', fontWeight: 'bold'}}>
                € {new Intl.NumberFormat('de-DE').format(headerTotal)}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{marginTop: "10px"}}><Divider /></Grid>
          </Fragment>
        )})
      }
    </Grid>
  )
}

export default ExpenseForm