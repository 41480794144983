import React from 'react';
import { Grid } from '@material-ui/core';

import UsersTable from './users/Table'
import UsersForm from './users/Form'

export default function Users(props) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          {!props.match.params.userId ?
            <UsersTable actorId={props.match.params.id ? props.match.params.id : null}/>
            :
            <UsersForm actorId={props.match.params.id ? props.match.params.id : null} userId={props.match.params.userId ? props.match.params.userId : null}/>
          }
        </Grid>
      </Grid>
    )
}