import React from 'react'

// Material UI components
import {
  Grid,
  Paper,
  TableCell,
  TableHead,
  Table, 
  TableBody,
  TableRow,
  TextField,
  TableContainer
} from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField'

import { dic } from '../../../_constants';

const SurchargesForm = (props) => {
  const { formData, setFormData } = props

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {dic.ADDITIONAL_DETAILS}
                </TableCell>
                <TableCell align="center">
                  {dic.NUMBER}
                </TableCell>
                <TableCell align="center">
                  {dic.AMOUNT}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {
                formData.map((row, key) => (
                  <TableRow key={key}>
                    <TableCell>
                      {row.name}
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        onChange={(e) => {setFormData("update", {value: e.target.value, key, type: "number"})}}
                        value={row.number}
                      />
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        id={"amount-" + key}
                        value={row.amount}
                        currencySymbol="€"
                        decimalCharacter=","
                        decimalCharacterAlternative="."
                        modifyValueOnWheel={false}
                        digitGroupSeparator="."
                        onBlur={(event, val) => {setFormData("update", {value: val, key, type: "amount"})}}
                      />
                    </TableCell>
                  </TableRow>
                ))
                }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default SurchargesForm