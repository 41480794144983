import React from 'react'

// Material UI components
import {
  Grid,
  Paper,
  Button,
  IconButton,
  TableCell,
  TableHead,
  Table, 
  TableBody,
  TableRow,
  TextField,
  TableContainer
} from '@material-ui/core'

// Material Ui icons
import BackspaceIcon from '@material-ui/icons/Backspace';
import { dic } from '../../../_constants';
export default function  GrossTonnagesForm(props) {
  const { data, handleChange } = props

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {dic.FROM}
                </TableCell>
                <TableCell align="center">
                  {dic.TO}
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {
               data.map((el, key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Grid container>
                        <Grid item xs>
                          {dic.FROM}
                        </Grid>
                        <Grid item xs style={{textAlign: 'right'}}>
                          <TextField
                            type="number"
                            id={"from_" + key}
                            onChange={(e) => handleChange("update", key, e.target.value, "from")}
                            value={data[key].from}
                            inputProps={{min: 0, style: { textAlign: 'right' }}}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs>
                          {dic.TO}
                        </Grid>
                        <Grid item xs style={{textAlign: 'right'}}>
                          <TextField
                            type="number"
                            id={"to_" + key}
                            onChange={(e) => handleChange("update", key, e.target.value, "to")}
                            value={data[key].to}
                            inputProps={{min: 0, style: { textAlign: 'right' }}}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      {key === (data.length -1) ?
                      <IconButton onClick={() => handleChange("remove", key)} >
                        <BackspaceIcon />
                      </IconButton>
                      : null}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>

            <Button onClick={()=> handleChange("add")} style={{width: "100%"}}>{dic.ADD_GROSS_TONNAGE}</Button>
        </TableContainer>
      </Grid>
    </Grid>
  )
}