export const dic = {
    TITLE: 'Federazione Italiana Piloti dei Porti',
    USER: 'Utente',
    PROFILE: 'Profilo',
    NO_INTERNET: 'Connessione internet assente o insufficiente',
    LOGOUT: 'Esci',
    SHIP_DATA: 'Dati Nave',
    BACK: 'Indietro',
    NEXT: 'Avanti',
    CONFIRM: 'Conferma',
    SHIP_NAME: 'Nome Nave',
    GROSS_TONNAGE: 'Stazza Lorda',
    SHIP_TYPE: 'Tipo Nave',
    SHIP: 'Nave',
    FLAG: 'Bandiera',
    SAVE: 'Salva',
    CANCEL: 'Cancella',
    LENGTH: 'Lunghezza',
    WIDTH: 'Larghezza',
    DRAUGHT: 'Pescaggio',
    CONTACTS: 'Contatti',
    AGENCY: 'Agenzia',
    CUSTOMER: 'Cliente',
    NOTES: 'Note',
    LOAD_TYPE: 'Tipo di Carico',
    MILES: 'Miglia',
    WAITING_ON_BOARD: 'Ritardo Manovra Min',
    DATE: 'Data',
    TIME: 'Ora',
    HOME: 'Home',
    STATISTICS: 'Statistiche',
    ACCOUNTING_STATEMENTS: 'Rendiconti Contabili',
    ACCOUNTING_STATEMENT: 'Rendiconto Contabile',
    TURNOVER: 'Fatturato',
    SYSTEM_MANAGER: 'Gestione Sistema',
    ACTOR: 'Corporazione',
    ACTORS: 'Corporazioni',
    ADMINISTRATOR: 'Amministratore',
    PILOT: 'Pilota',
    PILOTS: 'Piloti',
    CHIEF_PILOT: 'Capo Pilota',
    SECOND_CHIEF_PILOT: 'Vice capo Pilota',
    APPRENTICE: 'Aspirante Pilota',
    RETIRED: 'Pensionato/a',
    WIDOW: 'Vedovo/a',
    DECEASED: 'Deceduto/a',
    CORPORATE_NAME: 'Ragione Sociale',
    ADDRESS: 'Indirizzo',
    ZIP_CODE: 'Cap',
    CITY: 'Città',
    TELEPHONE: 'Telefono',
    FAX: 'Fax',
    MAIL: 'Mail',
    ACTOR_NAME: 'Codice Corporazione',
    VAT_NUMBER: 'P. Iva',
    NOTIFICATIONS: 'Notifiche',
    MONTH: "Mese",
    YEAR: "Anno",
    CREATION_DATE: "Creato",
    LAST_UPDATE: "Modificato",
    CREATE: "Nuovo/a",
    EDIT: "Modifica",
    DELETE: "Rimuovi",
    PDF: "Pdf",
    DELETE_CONFIRM: "Sei sicuro di voler eliminare questo elemento?",
    REQUIRED_FIELD_MISSING: "Informazioni mancanti",
    OVERVIEW: "Overview",
    NEW_ACCOUNTING_STATEMENT: 'Nuovo Rendiconto',
    ALL_ACCOUNTING_STATEMENTS: 'Tutti i Rendiconti',
    NEW_STATISTIC: 'Nuova Statistica',
    ALL_STATISTICS: 'Tutte le Statistiche',
    NEW_TURNOVER: 'Nuovo Fatturato',
    ALL_TURNOVERS: 'Tutti i fatturati',
    NEW_ACTOR: 'Nuova Corporazione',
    ALL_ACTORS: 'Tutte le Corporazioni',
    ACCOUNT_SETTINGS: "Dati utente",
    COMPANY_SETTINGS: "Dati corporazione",
    CREATE_STATEMENT: "Crea un Rendiconto Contabile",
    UPDATE_STATEMENT: "Modifica il Rendiconto Contabile",
    EXPENSES_SHEET: "Partitario delle Spese",
    SHARES: "Ripartizione",
    FORM_ERROR: "Il form presenta degli errori",
    BACK_TABLE: "Indietro",
    MONTHS: [
        null,
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre"
    ],
    ACTOR_ID: "Corporazione",
    CREDITS: "Fatture non incassate al primo del mese",
    TURNOVER_LT_2000: "Fatture emesse per navi inferiori a 2.000 GT",
    TURNOVER_GT_2000: "Fatture emesse per navi superiori a 2.000 GT",
    INCOMES_LT_2000: "Fatture incassate per navi inferiori a 2.000 GT",
    INCOMES_GT_2000: "Fatture incassate per navi superiori a 2.000 GT",
    FUND_18_PERC: "Contributi al Fondo 18%",
    FUND_25_PERC: "Contributi al Fondo 25%",
    EXTRA_INCOMES: "Proventi finanziari, prov. straordinari, sopravvenienze di attivo, insussistenze di passivo",
    TOTAL: "Totale",
    CREATE_NEW_PARTNER: "Crea un nuovo avente diritto",
    NAME: "Nome",
    SURNAME: "Cognome",
    PARTNER_ID: "Identificativo avente diritto (lascia vuoto se non sai cosa scrivere)",
    CLOSE: "Chiudi",
    SHARES_OWNERS: "Aventi diritto",
    POSITION: "Ruolo",
    SHARES_QUANTITY: "Quote",
    AMOUNT: "Importo",
    DIFFERENCE: "Differenza",
    LIST_INFO: "Lista degli aventi diritto non inseriti in ripartizione. Premere aggiungi per inserire un avente diritto in ripartizione.",
    CREATE_OWNER: "Crea un nuovo avente diritto",
    ADD: "Aggiungi",
    ACTIONS: "Azioni",
    SEARCH: "Cerca",
    NO_DATA: "Nessun dato trovato",
    OF: "Di",
    ROWS: "Righe",
    ROWS_PER_PAGE: "Righe per pagina",
    FIRST_PAGE: "Prima pagina",
    LAST_PAGE: "Ultima pagina",
    PREVIOUS_PAGE: "Pagina precedente",
    NEXT_PAGE: "Pagina Successiva",
    LOADING: "Caricamento in corso",
    PARTNERS: "Aventi diritto",
    ID: "Id",
    PARTNER_INSERTED: "Avente diritto inserito correttamente",
    PARTNER_UPDATED: "Avente diritto modificato correttamente",
    PARTNER_DELETED: "Avente diritto eliminato correttamente",
    INSERT_ERROR: "Si è verificato un errore durante l'inserimento",
    UPDATE_ERROR: "Si è verificato un errore durante la modifica",
    REMOVE_ERROR: "Impossibile eliminare l'elemento selezionato",
    PARTNERIDALREADYEXISTS: "L'identificativo dell'avente diritto è già stato utilizzato",
    OK: "Ok",
    EXPIRED_SESSION: "Sessione non autorizzata o scaduta, effettuare nuovamente il login",
    LOGIN_FAILED: "Il codice corporazione, il nome utente o la password sono sbagliati, riprovare",
    HARBOURS: "Zone portuali",
    HARBOUR_INSERTED: "Zona portuale inserita correttamente",
    HARBOUR_UPDATED: "Zona portuale modificata correttamente",
    HARBOUR_DELETED: "Zona portuale eliminata correttamente",
    ACCOUNTING_DATA: "Dati rendiconto",
    DATE_PICKER: "Data per il rendiconto",
    UPLOAD_EXCEL: "Carica file excel",
    EXPAND: "Espandi per vedere",
    EXPORT: "Esportazione",
    CSV_EXPORT: "Esporta in CSV",
    PDF_EXPORT: "Esporta in PDF",
    REVENUE: "Entrate",
    SURCHARGES: "Maggiorazioni",
    DISCOUNTS: "Sconti",
    CREATE_STATISTICS: "Crea una nuova statistica",
    UPDATE_STATISTICS: "Aggiorna statistica",
    HARBOUR_ID: "Porto",
    ITALIAN_AMOUNT: "Fatturato italiano",
    FOREIGN_AMOUNT: "Fatturato estero",
    USERNAME: "Nome utente",
    USERS: "Utenti",
    PASSWORD: "Password",
    PASSWORD_CONFIRM: "Conferma Password",
    UNSAFE_PASSWORD: "Password non sicura, la password  deve essere di almeno 8 caratteri (maiuscoli e minuscoli) e deve contenere almeno un numero e un carattere speciale tra i seguenti: !@#_*?",
    PASSWORDSNOTMATCHING: "Le password non corrispondono",
    USERNAMEALREADYEXISTS: "Il nome utente selezionato esiste già",
    USER_INSERTED: "Utente inserito correttamente",
    USER_UPDATED: "Utente modificato correttamente",
    USER_DELETED: "Utente eliminato correttamente",
    USER_CREATE: "Crea utente",
    USER_UPDATE: "Aggiorna utente",
    WRONG_OLD_PASSWORD: "La vecchia password non è corretta",
    PASSWORD_UPDATE: "Aggiorna password",
    OLD_PASSWORD: "Vecchia password",
    PASSWORD_UPDATED: "Password modifcata correttamente",
    ACTOR_INSERTED: "Corporazione inserita correttamente",
    ACTOR_UPDATED: "Corporazione modificata correttamente",
    ACTOR_DELETED: "Corporazione rimossa correttamente",
    ACTOR_CREATE: "Crea Corporazione",
    ACTOR_UPDATE: "Aggiorna Corporazione",
    ACTORNAMEALREADYEXISTS: 'Il codice corporazione selezionato esiste già, deve essere un valore univoco',
    ACTOR_INFORMATION: "Informazioni corporazione",
    CONTACT_INFORMATION: "Contatti",
    ADMINISTRATION_INFORMATION: "Livello di accesso",
    VHF: "VHF",
    OTHER: "Altro",
    FROM: "Da",
    TO: "A",
    NORMAL: "Normali",
    "SECOND PILOT": "Il pilota",
    DAILY: "Plurig.",
    EXTRA: "Non obbl.",
    ADDITIONAL_DETAILS: "Dettaglio maggiorazioni",
    NUMBER: "Numero",
    DISCOUNTS_DETAILS: "Dettaglio sconti",
    TOTAL_DISCOUNT: "Totale sconto",
    ADD_SURCHARGE: "Aggiungi maggiorazione",
    ADD_TONNAGES: "Aggiungi scaglione di stazze lorde",
    ADD_DISCOUNT: "Aggiungi sconto",
    STATISTIC_INSERTED: "Statistica inserita correttamente",
    STATISTIC_UPDATED: "Statistica modificata correttamente",
    STATISTIC_DELETED: "Statistica eliminata correttamente",
    ACCOUNTING_STATEMENT_INSERTED: "Rendiconto inserito correttamente",
    ACCOUNTING_STATEMENT_UPDATED: "Rendiconto modificato correttamente",
    ACCOUNTING_STATEMENT_DELETED: "Rendiconto eliminato correttamente",
    ACTOR_HARBOUR_SELECT: "Selezionare una corporazione e una zona portuale",
    ACTOR_SELECT: "Selezionare una corporazione",
    ACCOUNTING_STATEMENT_ALREADYEXISTS: "Il rendiconto contabile è già esistente, modificare quello o cambiare periodo di riferimento.",
    ACCOUNTING_STATEMENT_INSERT_ERROR: "Si è verificato un errore in fase di inserimento del rendiconto contabile.",
    ACCOUNTING_STATEMENT_EXPENSES_INSERT_ERROR: "Si è verificato un errore in fase di inserimento delle uscite.",
    ACCOUNTING_STATEMENT_SHARES_INSERT_ERROR: "Si è verificato un errore in fase di inserimento della ripartizione.",
    ACCOUNTING_STATEMENT_UPDATE_ERROR: "Si è verificato un errore in fase di modifica del rendiconto contabile.",
    ACCOUNTING_STATEMENT_EXPENSES_UPDATE_ERROR: "Si è verificato un errore in fase di modifica delle uscite.",
    ACCOUNTING_STATEMENT_SHARES_UPDATE_ERROR: "Si è verificato un errore in fase di modifica della ripartizione.",
    ACCOUNTING_STATEMENT_EXPENSES_REMOVE_ERROR: "Si è verificato un errore in fase di rimozione delle uscite.",
    ACCOUNTING_STATEMENT_SHARES_REMOVE_ERROR: "Si è verificato un errore in fase di rimozione della ripatizione.",
    STATISTIC_ALREADYEXISTS: "La statistica è già esistente, modificare quella o cambiare periodo di riferimento.",
    STATISTIC_INSERT_ERROR: "Si è verificato un errore in fase di inserimento della statistica.",
    STATISTIC_GROSS_TONNAGES_INSERT_ERROR: "Si è verificato un errore in fase di inserimento delle prestazioni suddivise per scaglioni di stazza lorda.",
    STATISTIC_DISCOUNTS_INSERT_ERROR: "Si è verificato un errore in fase di inserimento del dettaglio sugli sconti applicati.",
    STATISTIC_SURCHARGES_INSERT_ERROR: "Si è verificato un errore in fase di inserimento del dettaglio sulle maggiorazioni applicate.",
    STATISTIC_UPDATE_ERROR: "Si è verificato un errore in fase di modifca della statistica.",
    STATISTIC_GROSS_TONNAGES_UPDATE_ERROR: "Si è verificato un errore in fase di modifica delle prestazioni suddivise per scaglioni di stazza lorda.",
    STATISTIC_DISCOUNTS_UPDATE_ERROR: "Si è verificato un errore in fase di modifica del dettaglio sugli sconti applicati.",
    STATISTIC_SURCHARGES_UPDATE_ERROR: "Si è verificato un errore in fase di modifica del dettaglio sulle maggiorazioni applicate.",
    STATISTIC_GROSS_TONNAGES_REMOVE_ERROR: "Si è verificato un errore in fase di rimozione delle prestazioni suddivise per scaglioni di stazza lorda.",
    STATISTIC_DISCOUNTS_REMOVE_ERROR: "Si è verificato un errore in fase di rimozione del dettaglio sugli sconti applicati.",
    STATISTIC_SURCHARGES_REMOVE_ERROR: "Si è verificato un errore in fase di rimozione del dettaglio sulle maggiorazioni applicate.",
    NO_GROSS_TONNAGES: "Non è presente alcuna definizione degli scaglioni di stazza lorda.",
    GROSS_TONNAGES: "Scaglioni di stazza lorda",
    VALID_FROM_DATE: "Valido da",
    VALID_TO_DATE: "a",
    EXCEL_DROPZONE: "Cliccare o trascinare qui un file",
    PREVIEW_EXCEL: "Anteprima:",
    GROSS_TONNAGE_CREATE: "Crea una definizione di scaglioni di stazza lorda",
    GROSS_TONNAGE_UPDATE: "Modifica una definizione di scaglioni di stazza lorda",
    FILTER: "Opzioni di ricerca",
    ADD_GROSS_TONNAGE: "Aggiungi scaglione",
    EVERY_GROSS_TONNAGE: "Intervallo per gli scaglioni superiori a quelli definiti nel D.T.",
    GROSS_TONNAGE_INSERTED: "Definizione di scaglioni di stazza lorda inserita correttamente",
    GROSS_TONNAGE_UPDATED: "Definizione di scaglioni di stazza lorda modificata correttamente",
    GROSS_TONNAGE_DELETED: "Definizione di scaglioni di stazza lorda eliminata correttamente",
    GROSS_TONNAGES_ALREADY_EXIST: "Esiste già una definizione di scaglioni di stazza lorda per le date selezionate",
    SURCHARGE_INSERTED: "Tipologia di maggiorazione inserita correttamente",
    SURCHARGE_UPDATED: "Tipologia di maggiorazione modificata correttamente",
    SURCHARGE_DELETED: "Tipologia di maggiorazione eliminata correttamente",
    STATISTICS_INSERT: "Statistica inserita correttamente",
    STATISTICS_UPDATED: "Statistica modificata correttamente",
    STATISTICS_DELETED: "Statistica eliminata correttamente",
    SHIP_TYPE_INSERTED: "Tipologia di nave inserita correttamente",
    SHIP_TYPE_UPDATED: "Tipologia di nave modificata correttamente",
    SHIP_TYPE_DELETED: "Tipologia di nave eliminata correttamente",
    SHIP_TYPES: 'Tipi di navi',
    ORDER_FIELD: 'Indice',
    SHEET_HEADER_INSERTED: "Descrizione di rendiconto inserita correttamente",
    SHEET_HEADER_UPDATED: "Descrizione di rendiconto modificata correttamente",
    SHEET_HEADER_DELETED: "Descrizione di rendiconto eliminata correttamente",
    SHEET_HEADERS: "Descrizioni di rendiconto",
    SHEET_ITEM_INSERTED: "Voce di rendiconto inserita correttamente",
    SHEET_ITEM_UPDATED: "Voce di rendiconto modificata correttamente",
    SHEET_ITEM_DELETED: "Voce di rendiconto eliminata correttamente",
    SHEET_ITEMS: "Voci di rendiconto",
    NO_MISSING_FORMS: "Tutti i documenti sono stati caricati",
    SEE_ALL: "Vedi tutti/e",
    DOWNLOAD: "Scarica",
    XLSX: "Excel",
    RECEIVED: "Ricevuti",
    OVERALL_OVERVIEW: "Overview complessiva",
    ACCOUNTING_STATEMENTS_OVERVIEW: "Overview rendiconti",
    TURNOVERS_OVERVIEW: "Overview fatturati",
    STATISTICS_OVERVIEW: "Overview Statistiche",
    
    TURNOVERS: "Fatturati",
    TURNOVER_INSERTED: "Fatturato inserito correttamente",
    TURNOVER_UPDATED: "Fatturato modificato correttamente",
    TURNOVER_DELETED: "Fatturato eliminato correttamente",
    SELECTION_DATE: "Selezione data",
    ACTOR_SELECTED: "Corporazione selezionata",
    TURNOVER_DATA: "Dati Fatturato",
    ADD_CUSTOMER: "Aggiungi un cliente",

    CREATE_TURNOVER: "Crea una statistica fatturato",
    UPDATE_TURNOVER: "Modifica una statistica fatturato",
    TURNOVER_ALREADYEXISTS: "Fatturato già inserito",
    TURNOVER_INSERT_ERROR: "Si è verificato un errore in fase di inserimento del fatturato.",
    TURNOVER_DETAILS_INSERT_ERROR: "Si è verificato un errore in fase di inserimento dei dettagli del fatturato.",
    TURNOVER_UPDATE_ERROR: "Si è verificato un errore in fase di modifica del fatturato.",
    TURNOVER_DETAILS_UPDATE_ERROR: "Si è verificato un errore in fase di modifica dei dettagli del fatturato.",
    TURNOVER_DETAILS_REMOVE_ERROR: "Si è verificato un errore in fase di rimozione del fatturato.",
    CUSTOMER_ID_ALREADY_EXISTS: "L'identificativo del cliente è già presente.",
    RESET_FORM_QUESTION: "Cancella",
    RESET_FORM_TEXT: "Sei sicuro di voler cancellare tutti i dati inseriti nel modulo di compilazione?",
    RESET_FORM: "Cancella dati",
    SUBMIT: "Conferma",
    WRITE_CUSTOMER_NAME: "Ragione sociale del cliente",
    CREDIT_NOTE: "Note di credito",
    VAT: "IVA",
    TURNOVER_BASE_RATE: "Fatturato Tariffa Base",
    DISCOUNT_APPLIED: "Sconto Applicato",
    PERCENTAGE_DISCOUNT_APPLIED: "% Sconto applicato",
    TOTAL_DISCOUNT_TURNOVER: "Totale Fatturato Scontato",
    NUMBER_OF_BENEFITS: "Numero Prestazioni",
    SECOND_PILOT: "II Pilota",
    TURNOVER_SECOND_PILOT: "Fatturato Solo II Pilota",
    TOTAL_TURNOVER: "Totale Fatturato Fuori Limite",
    ADD_NEW_CUSTOMER: "Aggiungi un nuovo cliente",
    REGISTER_CUSTOMER_INFO: "Dati nuovo cliente",
    PROVINCE: "Provincia",
    COUNTRY: "Nazione",
    VAT_NUMBER_CODE: "Codice P.IVA",
    FISCAL_CODE: "Codice fiscale",
    CREDIT: "Credito",
    CUSTOMERS: "Clienti",
    UPDATE: "Modifica",

    FILTERING_OPTIONS: "Opzioni di ricerca",
    NO_TONNAGE_AVAILABLE: "Non sono presenti le informazioni relative agli scaglioni di stazza per il porto selezionato. Contattare la Federazione Piloti",
    HARBOUR_SELECT: "Seleziona un porto",
    EXCEL_ERROR: "Impossibile importare il file excel, file incompatibile",
    CUSTOMER_CREATION_SUCCESS: "Cliente inserito correttamente.",
    CUSTOMER_UPDATE_SUCCESS: "Cliente modificato correttamente.",
    CUSTOMER_REMOVE_SUCCESS: "Cliente eliminato correttamente.",
    CUSTOMER_CREATION_FAIL: "Si è verificato un errore in fase di inserimento del cliente.",
    CUSTOMER_UPDATE_FAIL: "Si è verificato un errore in fase di modifica del cliente",
    ACTORS_CUSTOMER_INSERTED: "Cliente inserito correttamente.",
    ACTORS_CUSTOMER_UPDATED: "Cliente modificato correttamente.",
    ACTORS_CUSTOMER_DELETED: "Cliente eliminato correttamente.",

    ALL: "Tutti",
    MISSING_FORMS: "Documenti mancanti",
    YEARLY: "Annuale",
    BISEMESTRIAL: "Bi-semestrale",
    PREVIOUS_YEAR: "Anno precedente",
    NEXT_YEAR: "Anno successivo",
    MISSING_DOCUMENTS: "Mancanti",
    NEW: "Nuovo/a",
    ACTOR_TYPE: "Tipo società",
    EXPORTS: "Altre Esportazioni",

    SHARES_OWNER: "Avente diritto",
    TEMPORARY_PILOT: "Incaricato di Pilotaggio",

    SPECIFIC_RISK: "Rischio specifico",
    YES: "Si",
    NO: "No"
}

export const dicTypes = {
    _ACTOR_TYPES_ :{
        admin: dic.ADMINISTRATOR,
        pilots: dic.PILOTS
    },

    _USER_TYPES_ :{
        admin: dic.ADMINISTRATOR,
        user: dic.USER
    },

    _USER_POSITIONS_ :{
        1: dic.CHIEF_PILOT,
        2: dic.SECOND_CHIEF_PILOT,
        3: dic.PILOT,
        4: dic.APPRENTICE,
        5: dic.RETIRED,
        6: dic.WIDOW,
        7: dic.DECEASED,
        8: dic.SHARES_OWNER,
        9: dic.TEMPORARY_PILOT
    },

    _TABLES_LOCALIZATION_ :{
        header: {
            actions: dic.ACTIONS
        },
        toolbar: {
            searchTooltip: dic.SEARCH, 
            searchPlaceholder: dic.SEARCH,
            exportTitle: dic.EXPORT,
            exportAriaLabel: dic.EXPORT,
            exportCSVName: dic.CSV_EXPORT,
            exportPDFName: dic.PDF_EXPORT
        }, 
        body: {
            addTooltip: dic.NEW,
            emptyDataSourceMessage: dic.NO_DATA,
            editRow: { 
                deleteText: dic.DELETE_CONFIRM, 
                cancelTooltip: dic.CANCEL, 
                saveTooltip: dic.CONFIRM 
            }, 
            deleteTooltip: dic.DELETE
        },
        pagination: {
            labelDisplayedRows: '{from}-{to} '+dic.OF+' {count}',
            labelRowsSelect: dic.ROWS,
            labelRowsPerPage: dic.ROWS_PER_PAGE,
            firstAriaLabel: dic.FIRST_PAGE,
            firstTooltip: dic.FIRST_PAGE,
            previousAriaLabel: dic.PREVIOUS_PAGE,
            previousTooltip: dic.PREVIOUS_PAGE,
            nextAriaLabel: dic.NEXT_PAGE,
            nextTooltip: dic.NEXT_PAGE,
            lastAriaLabel: dic.LAST_PAGE,
            lastTooltip: dic.LAST_PAGE
        },
    }
}
