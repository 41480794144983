import { userConstants } from '../../_constants/user.constants'
import { getPreviousDate, getPreviousMonth } from '../../_helpers';

export function user(state = {isLogged: false, user: false, actor: false, configuration: false, notifications: false, last_actor: false}, action) {
  switch (action.type) {
    case userConstants.LOGIN:
        return state;
    case userConstants.RECEIVE_USER_DATA:
        switch(action.result){
          // se ho un errore
          case "error":
            return {isLogged: false, user: false, actor: false, configuration: false, notifications: false, last_actor: state.last_actor, errors: action.user_data};
          // se il login ha avuto successo
          case "ok":
            let defaultConf = {
              year: {
                from: action.user_data.actor_info.actor_type!=="admin"?getPreviousDate(12).year:getPreviousMonth().year,
                to: new Date().getFullYear()
              },
              month: {
                from: action.user_data.actor_info.actor_type!=="admin"
                ? (getPreviousDate(12).month + 1) < 10
                  ? "0" + (getPreviousDate(12).month + 1)
                  : (getPreviousDate(12).month + 1)
                : (getPreviousMonth().month + 1) < 10
                  ? "0" + (getPreviousMonth().month + 1)
                  : (getPreviousMonth().month + 1),
                to: (new Date().getMonth() + 1) < 10 ? "0" + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
              },
              actor_id: action.user_data.actor_info.actor_type==="admin"?0:null
            }
            return {
                  isLogged: true, 
                  user: action.user_data.user_info, 
                  actor: action.user_data.actor_info,
                  notifications: action.user_data.notifications,
                  last_actor: action.user_data.actor_info.actorname,
                  configuration: {
                    statements: defaultConf,
                    statistics: defaultConf,
                    turnovers: defaultConf,
                  }
            };
          default:
            return state;
        }
      
    case userConstants.UPDATE_CONFIGURATION:
      let selected = {
        ...action.selected,
        month: {
          from: String(action.selected.month.from).padStart(2, '0'),
          to: String(action.selected.month.to).padStart(2, '0')
        }
      }
      return {...state, configuration: {...state.configuration, [action.key]: selected }};
    case userConstants.DESTROY_USER_DATA:
      return {isLogged: false, user: false, actor: false, notifications: false, last_actor: state.last_actor};
    default:
      return state
  }
}
