// crea un component che gestisce le richieste di versioni di statement
import React, { useCallback, useEffect, useState } from "react";
import StatementsVersionsDialog from '../_components/accountingStatements/Versions'

import { statementsServices } from '../_services'
import { dic, dicTypes } from "../_constants";

export default function StatementsVersions(props) {
    const { open, setOpen, searchParams, actors, sheetHeaders } = props;
    const [versions, setVersions] = useState([]);
    const [selectedVersions, setSelectedVersions] = useState([]);
    const [differences, setDifferences] = useState([]);
    const [expensesDiffs, setExpensesDiffs] = useState([]);
    const [sharesDiffs, setSharesDiffs] = useState([]);
    const [notesDiffs, setNotesDiffs] = useState([]);
    // searching all the versions of the statement
    useEffect(() => {
        if (open)
            statementsServices.statementsVersions(searchParams).then((resp) => {
                setVersions(resp.response.details);
            })
        else{
            setVersions([]);
            setSelectedVersions([]);
            setDifferences([]);
        }
    }, [searchParams, open])

    // manage the selection of the versions
    const addNewSelection = (version) => {
        if (selectedVersions.length < 2) {
            setSelectedVersions([...selectedVersions, version]);
        } else {
            setSelectedVersions([version]);
        }
    }

    const compareVersions = useCallback(() => {
        const fieldsToCheck = [ 
            "credits", "turnover_lt_2000", "turnover_gt_2000",  "incomes_lt_2000", 
            "incomes_gt_2000", "fund_18_perc",  "fund_25_perc", "extra_incomes"
        ];
        
        // check if the selected versions are two
        const diffs = fieldsToCheck.map((field) => {
            if (selectedVersions.length === 2) {
              if (selectedVersions[0][field] !== selectedVersions[1][field] && 
                  !(selectedVersions[0][field] === null && selectedVersions[1][field] === null) &&
                  !(selectedVersions[0][field] === 0 && selectedVersions[1][field] === 0) &&
                  !(selectedVersions[0][field] === "" && selectedVersions[1][field] === "")
              ) {
                  return {
                      field: field,
                      version1: selectedVersions[0][field],
                      version2: selectedVersions[1][field],
                      fieldType: "number",
                      operationType: "update"
                  }
              }
            }
            return null;
        }).filter(item => item !== null);
        
        /* il campo accounting_statements_expenses è un array di oggetti. Gli oggetti sono identificati univocamente dal campo expense_sheet_item_id
         voglio un array (eDiffs) di oggetti che rappresentano le differenze tra i due array di oggetti.
         field è item_name, field_type è number, operationType è update se amount !== amount (null e "" sono considerati uguali)
         operationType è insert se l'oggetto è presente solo in version 2, delete se l'oggetto è presente solo in uno version 1
         operationType è update se l'oggetto è presente in entrambe le versioni e ha amount diversi */
        const eDiffs = selectedVersions.length === 2 ? 
            selectedVersions[1].expenses.map((item) => {
                const item1 = selectedVersions[0].expenses.find((i) => i.expense_sheet_item_id === item.expense_sheet_item_id);
                if (item1) {
                    if (item.amount !== item1.amount && 
                        !(item.amount === null && item1.amount === null) &&
                        !(item.amount === 0 && item1.amount === 0) &&
                        !(item.amount === "" && item1.amount === "")
                    ) {
                        return {
                            field: item.header_order_field + item.subheader_order_field + "." + item.item_order_field + " - " + item.item_name,
                            version1: item1.amount,
                            version2: item.amount,
                            fieldType: "number",
                            operationType: "update"
                        }
                    }
                } else {
                    return {
                        field: item.header_order_field + item.subheader_order_field + "." + item.item_order_field + " - " + item.item_name,
                        version1: null,
                        version2: item.amount,
                        fieldType: "number",
                        operationType: "insert"
                    }
                }
                return null;
            })
            // filtra eventuali null
            .filter(item => item !== null).concat(selectedVersions[0].expenses.map((item) => {
                const item2 = selectedVersions[1].expenses.find((i) => i.expense_sheet_item_id === item.expense_sheet_item_id);
                if (!item2) {
                    return {
                        field: item.header_order_field + item.subheader_order_field + "." + item.item_order_field + " - " + item.item_name,
                        version1: item.amount,
                        version2: null,
                        fieldType: "number",
                        operationType: "delete"
                    }
                }
                return null;
            }).filter(item => item !== null)) : [];
            
         /* il campo shares è un array di oggetti. Gli oggetti sono identificati univocamente dal campo partner_id
         voglio un array (sDiffs) di oggetti che rappresentano le differenze tra i due array di oggetti.
        field_type è number, operationType è update se amount !== amount oppure se share !== share o se position diverso da position (null e "" sono considerati uguali)
         field è name + surname + dic[AMOUNT] se cambia amount, dic[SHARE] se cambia share, dic[POSITION] se cambia position
         se per Mario Rossi è cambiato amount e share aggiungi due oggetti, uno per amount e uno per share
         operationType è insert se l'oggetto è presente solo in version 2, delete se l'oggetto è presente solo in uno version 1
         operationType è update se l'oggetto è presente in entrambe le versioni e ha amount diversi:
         i campi version1 e version2 sono stringhe con presenti, se modificati, i valori di amount, share e position */
         const sDiffs = selectedVersions.length === 2 ? 
         selectedVersions[1].shares.flatMap((item) => {
             const item1 = selectedVersions[0].shares.find((i) => i.partner_id === item.partner_id);
             let diffs = [];
             if (item1) {
                 if (item.amount !== item1.amount && 
                     !(item.amount === null && item1.amount === null) &&
                     !(item.amount === 0 && item1.amount === 0) &&
                     !(item.amount === "" && item1.amount === "")
                 ) {
                     diffs.push({
                         field: item.name + " " + item.surname + ": " + dic.AMOUNT,
                         version1: item1.amount,
                         version2: item.amount,
                         fieldType: "number",
                         operationType: "update"
                     });
                 }
                 if (item.share !== item1.share && 
                     !(item.share === null && item1.share === null) &&
                     !(item.share === 0 && item1.share === 0) &&
                     !(item.share === "" && item1.share === "")
                 ) {
                     diffs.push({
                         field: item.name + " " + item.surname + ": " + dic.SHARES_QUANTITY,
                         version1: item1.share,
                         version2: item.share,
                         fieldType: "number",
                         operationType: "update"
                     });
                 }
                 if (item.position !== item1.position && 
                     !(item.position === null && item1.position === null) &&
                     !(item.position === 0 && item1.position === 0) &&
                     !(item.position === "" && item1.position === "")
                 ) {
                     diffs.push({
                         field: item.name + " " + item.surname + ": " + dic.POSITION,
                         version1: dicTypes._USER_POSITIONS_[item1.position],
                         version2: dicTypes._USER_POSITIONS_[item.position],
                         fieldType: "string",
                         operationType: "update"
                     });
                 }
             } else {
                 diffs.push({
                     field: item.name + " " + item.surname,
                     version1: null,
                     version2: dicTypes._USER_POSITIONS_[item.position] + " - " + item.share + " - " + parseFloat(item.amount).toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                     fieldType: "string",
                     operationType: "insert"
                 });
             }
             return diffs;
         }).concat(selectedVersions[0].shares.map((item) => {
             const item2 = selectedVersions[1].shares.find((i) => i.partner_id === item.partner_id);
             if (!item2) {
                 return {
                     field: item.name + " " + item.surname,
                     version1: dicTypes._USER_POSITIONS_[item.position] + " - " + item.share + " - " + parseFloat(item.amount).toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                     version2: null,
                     fieldType: "string",
                     operationType: "delete"
                 }
             }
             return null;
         }).filter(item => item !== null)) : []
        
        /* crea le differenze per le note: le note sono strutturate come {"1":[{"name":"TEST di nota C"}]}
        la chiave "1" rappresenta l'id di uno sheetHeaders, il valore è un array di oggetti con chiave "name" e valore "TEST di nota C"
        anche in questo caso evidenzia se la nota è stata inserita, cancellata o modificata 
        gestisci i null...
        */
        const nDiffs = selectedVersions.length === 2 ? 
            Object.keys(selectedVersions[1].expenses_notes).flatMap((header) => {
                const notes1 = selectedVersions[0].expenses_notes[header] && (selectedVersions[0].expenses_notes[header][0] ? selectedVersions[0].expenses_notes[header][0] : false);
                const notes2 = selectedVersions[1].expenses_notes[header] && selectedVersions[1].expenses_notes[header] && selectedVersions[1].expenses_notes[header][0] ? selectedVersions[1].expenses_notes[header][0] : false;
                // l'array contiene sempre un solo oggetto
                if (notes1 && notes2) {
                    if (notes1.name !== notes2.name && 
                        !(notes1.name === null && notes2.name === null) &&
                        !(notes1.name === "" && notes2.name === "")
                    ) {
                        return {
                            field: sheetHeaders.find((h) => h.id === header).name,
                            version1: notes1.name,
                            version2: notes2.name,
                            fieldType: "string",
                            operationType: "update"
                        }
                    }
                } else if (notes2) {
                    return {
                        field: sheetHeaders.find((h) => h.id === header).name,
                        version1: '',
                        version2: notes2.name,
                        fieldType: "string",
                        operationType: "insert"
                    }
                }
                return null;
            }
        ).filter(item => item !== null).concat(Object.keys(selectedVersions[0].expenses_notes).flatMap((header) => {
            const notes1 = selectedVersions[0].expenses_notes[header] && (selectedVersions[0].expenses_notes[header][0] ? selectedVersions[0].expenses_notes[header][0] : false);
            const notes2 = selectedVersions[1].expenses_notes[header] && selectedVersions[1].expenses_notes[header] && selectedVersions[1].expenses_notes[header][0] ? selectedVersions[1].expenses_notes[header][0] : false;
            if (!notes2) {
                return {
                    field: sheetHeaders.find((h) => h.id === header).name,
                    version1: notes1.name,
                    version2: null,
                    fieldType: "string",
                    operationType: "delete"
                }
            }
            return null;
        }
        ).filter(item => item !== null)) : [];
        
                
        
        
        setDifferences(diffs);
        setExpensesDiffs(eDiffs)
        setSharesDiffs(sDiffs);
        setNotesDiffs(nDiffs);
    }, [selectedVersions])

    useEffect(() => compareVersions(), [selectedVersions, compareVersions])

    

    return (
        <StatementsVersionsDialog
            versions={versions}
            open={open}
            setOpen={setOpen}
            searchParams={searchParams}
            actor={actors.find((actor) => actor.id === searchParams.actor_id)}
            selectedVersions={selectedVersions}
            addNewSelection={addNewSelection}
            differences={differences}
            expensesDiffs={expensesDiffs}
            sharesDiffs={sharesDiffs}
            notesDiffs={notesDiffs}
        />
    )
}