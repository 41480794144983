import { setCall, handleResponse } from '../_helpers';

export const userServices = {
    userLogin, userLogout, checkSession, usersFetchAll, userInsert, userUpdate, userDelete, userFetch
};

export async function checkSession() {
    const requestOptions = {
        method: 'POST',
        body: setCall('users', 'is_logged', {}),
        headers: {"Content-type": "application/json; charset=UTF-8" }
    }
    
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response);

    return json;
}

async function userLogin(params) {
    const requestOptions = {
        method: 'POST',
        body: setCall('users', 'login', params),
        headers: {"Content-type": "application/json; charset=UTF-8" }
    }
    
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response);
    
    return json;
}

async function userLogout() {
    const requestOptions = {
        method: 'POST',
        body: setCall('users', 'logout', {}),
        headers: {"Content-type": "application/json; charset=UTF-8" }
    }
    
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response);
    
    return json;
}

async function usersFetchAll(searchParams) {
    const requestOptions = {
        method: 'POST',
        body: setCall('users', 'users_search', !searchParams ? {} : searchParams),
        headers: {"Content-type": "application/json; charset=UTF-8" }
    }
    
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response);
    
    return json;
}

async function userFetch(data) {
    const requestOptions = {
        method: 'POST',
        body: setCall('users', 'get_user_data', data),
        headers: {"Content-type": "application/json; charset=UTF-8" }
    }

    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response)

    return json;
}

async function userInsert(user) {
    
    const requestOptions = {
        method: 'POST',
        body: setCall('users', 'user_insert', user),
        headers: {"Content-type": "application/json; charset=UTF-8" }
    }
    
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response);
    
    return json;
}

async function userUpdate(user, passwordOnly) {
    let selectedFunction = passwordOnly ? "password_update" : "user_update"
    let selectedFields = passwordOnly ? 
                            {
                                id: user.user_id,
                                actor_id: user.actor_id,
                                password: user.password,
                                password_confirm: user.password_confirm,
                                old_password: user.old_password
                            }
                            :
                            {
                                id: user.id,
                                actor_id: user.actor_id,
                                name: user.name,
                                surname: user.surname,
                                mail: user.mail
                            }
    const requestOptions = {
        method: 'POST',
        body: setCall('users', selectedFunction, selectedFields),
        headers: {"Content-type": "application/json; charset=UTF-8" }
    }
    
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response);
    
    return json;
}

async function userDelete(data) {
    
    const requestOptions = {
        method: 'POST',
        body: setCall('users', 'user_remove', data),
        headers: {"Content-type": "application/json; charset=UTF-8" }
    }
    
    const response = await fetch('/api/', requestOptions);
    const json = await handleResponse(response);
    
    return json;
}