import { sheetsServices } from '../../_services'
import { statementsConstants } from '../../_constants'

export function createExpensesForms() {
  return function(dispatch) {
    sheetsServices.sheet()
      .then(json => {
        let expensesFormPattern = []

        for (let header in json) {
          for (let section in json[header].items) {
            expensesFormPattern.push({amount: 0, expense_sheet_item_id: json[header].items[section].id})
          }
        }
        
        dispatch({
          type: statementsConstants.CREATE_EXPENSES_FORM,
          formPattern: expensesFormPattern,
          sections: json
        })
      })
  }
}
