import React from 'react';
import { connect } from 'react-redux'

import {AppBar, Toolbar, Typography} from '@material-ui/core/';

import { dic } from '../../_constants';

import Menu from './Menu';
import User from './User';

class Header extends React.Component {
  render(){
      const { userData } = this.props;
      
      return (
        <React.Fragment>
          <AppBar position="fixed">
            <Toolbar>
              {userData.isLogged && (<Menu userData={userData}/>)}
              <Typography noWrap align="left" variant="h6" style={{flexGrow: 1}}>
                {dic.TITLE}
              </Typography>
              {userData.isLogged && (<User userData={userData}/>)}
            </Toolbar>
          </AppBar>
          <Toolbar />
        </React.Fragment>
      )
  }
}

const connectedHeader = connect(state => ({userData: state.user}))(Header);
export default connectedHeader