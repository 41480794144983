import React, { Fragment, useEffect, useState } from 'react'
/*eslint eqeqeq: "off"*/

// Material UI components
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider
} from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

// Material Ui icons
import BackspaceIcon from '@material-ui/icons/Backspace';

// Dictionnary
import { dic, dicTypes } from '../../../_constants'
const positions = Object.keys(dicTypes._USER_POSITIONS_).map((value, key) => {return {value: value, text: dicTypes._USER_POSITIONS_[value] }})

const PartnersSelection = (props) => {
  const { partners, setAddPartnerDialog, addPartnerToList, formData } = props

  const [showedList, setShowedList] = useState([])

  useEffect(() => {
    let newShowedList = []
    for (let i = 0 ; i < partners.length ; i++) {
      let found = false
      for (let j = 0 ; j < formData.length ; j++) {
        if (formData[j].partner_id == partners[i].id) found = true
      }
      if (!found) newShowedList.push(partners[i])
    }
    setShowedList(newShowedList)
  }, [formData.length])

  return (
    <Paper style={{padding: "10px", background: "#EEEEEE", color: "#1E7DA5"}}>
      <Grid container style={{textAlign: 'left'}} justifyContent="center">
        <Grid item xs={12}>
          <Typography style={{fontWeight: 'bold'}}>
            {dic.LIST_INFO}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => {setAddPartnerDialog(true)}}>{dic.CREATE_OWNER}</Button>
        </Grid>
        {showedList.map((partner, i) => (
          <Grid item xs={10} id={"partner-" + partner.id}>
            {
              i > 0
              ? <Divider style={{margin: "5px 0px"}} />
              : null
            }
            <Grid container>
              <Grid item xs={10}>
                <Typography>
                  {partner.name} {partner.surname} (id: {partner.id})
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button onClick={() => {addPartnerToList(partner)}}>
                  {dic.ADD}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}

const PartnerDialog = (props) => {
  const {
    isOpen,
    handleDialog,
    createNewPartner
  } = props

  const [formData, setFormData] = useState({})

  return (
    <Dialog open={isOpen} onClose={() => {handleDialog(false)}}>
      <DialogTitle>{dic.CREATE_NEW_PARTNER}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px'}}>
              {dic.NAME}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              id="new-partner_name"
              value={formData.name}
              onChange={(e) => {setFormData({...formData, name: e.target.value})}}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px'}}>
              {dic.SURNAME}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              id="new-partner_surname"
              value={formData.surname}
              onChange={(e) => {setFormData({...formData, surname: e.target.value})}}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px'}}>
              {dic.PARTNER_ID}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              id="new-partner_id"
              value={formData.id}
              onChange={(e) => {setFormData({...formData, id: e.target.value})}}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {handleDialog(false)}} color="secondary">{dic.CLOSE}</Button>
        <Button
          onClick={() => {
            createNewPartner({
              "name": formData.name,
              "surname": formData.surname,
              "id": formData.id?formData.id:Math.floor(Math.random() * 1000000000000000)
            })
            handleDialog(false)
          }}
          color="primary"
        >{dic.CREATE}</Button>
      </DialogActions>
    </Dialog>
  )
}

const SharesForm = (props) => {
  const {
    formData = [],
    partners = [],
    setFormData,
    total = 0,
    createNewPartner,
    totalShares
  } = props

  const [addPartnerDialog, setAddPartnerDialog] = useState(false)
  /*
  useEffect(() => {
    console.log('calculate')
    setFormData("calculate")
  }, [])
  */

  const addPartnerToList = (partnerData) => {
    setFormData('addPartner', partnerData)
  }

  return (
    <Fragment>
      <PartnerDialog
        isOpen = { addPartnerDialog }
        handleDialog = { setAddPartnerDialog }
        createNewPartner = { createNewPartner }
      />

      <Grid container>
        <Grid item xs={12}>
          <Typography color="primary" variant="h6" style={{textAlign: 'left', marginTop: '20px'}}>
            {dic.SHARES_OWNERS}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography style={{textAlign: 'center', fontWeight: 'bold'}}>
                {dic.NAME}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography style={{textAlign: 'center', fontWeight: 'bold'}}>
                {dic.POSITION}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{textAlign: 'center', fontWeight: 'bold'}}>
                {dic.SHARES_QUANTITY}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{textAlign: 'center', fontWeight: 'bold'}}>
                {dic.AMOUNT}
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>

          <Grid container spacing={2}>
            {
              formData.map((val, key) => (
                <Fragment>
                  <Grid item xs={3}>
                    {partners.filter(elem => elem.id === val.partner_id).map(elem => (
                      <Fragment>{elem.name} {elem.surname}</Fragment>
                    ))}
                  </Grid>
                  <Grid item xs={2}>
                    <Select
                      id={key + "_position"}
                      value={formData[key].position}
                      onChange={(e, child) => {setFormData("update", {
                        id: key,
                        field: 'position',
                        value: e.target.value
                      })}}
                    >
                      {positions.map(elem => (
                        <MenuItem id={key + "_position_" + elem.value} value={elem.value} key={elem.text}>{elem.text}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={3}>
                    <CurrencyTextField
                      id={"share_" + key}
                      value={formData[key].share}
                      currencySymbol=""
                      decimalCharacter=","
                      decimalCharacterAlternative="."
                      digitGroupSeparator="." 
                      modifyValueOnWheel={false}
                      onBlur={(event, val) => {setFormData("update", {
                        id: key,
                        field: 'share',
                        value: val
                      })}}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CurrencyTextField
                      id={"amount_" + key}
                      value={formData[key].amount}
                      currencySymbol="€"
                      decimalCharacter=","
                      decimalCharacterAlternative="."
                      digitGroupSeparator="." 
                      modifyValueOnWheel={false}
                      onBlur={(event, val) => {setFormData("update", {
                        id: key,
                        field: 'amount',
                        value: val
                      })}}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => {setFormData("delete", { id: key })}} >
                      <BackspaceIcon />
                    </IconButton>
                  </Grid>
                </Fragment>
              ))
            }
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px', fontWeight: 'bold'}}>
                {dic.TOTAL} {dic.SHARES_QUANTITY}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{textAlign: 'right', marginLeft: '20px', marginTop: '5px', fontWeight: 'bold'}}>
                {new Intl.NumberFormat('de-DE').format(totalShares)}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px', fontWeight: 'bold'}}>
                {dic.TOTAL} {dic.SHARES}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{textAlign: 'right', marginLeft: '20px', marginTop: '5px', fontWeight: 'bold'}}>
                € {new Intl.NumberFormat('de-DE').format(formData.reduce((acc, val) => acc + val.amount, 0))}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px', fontWeight: 'bold'}}>
                {dic.DIFFERENCE}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  textAlign: 'right',
                  marginLeft: '20px',
                  marginTop: '5px',
                  fontWeight: 'bold'
                }}
              >
                € {new Intl.NumberFormat('de-DE').format(total - formData.reduce((acc, val) => acc + val.amount, 0))}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} style={{marginTop: "15px"}}>
              <PartnersSelection
                partners = { partners }
                setAddPartnerDialog = { setAddPartnerDialog }
                addPartnerToList = { addPartnerToList }
                formData = { formData }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default SharesForm