import React from 'react';
import { Grid } from '@material-ui/core';

import StatementsTable from './accountingStatements/Table'
import StatementsForm from './accountingStatements/Form'
import Overview from './accountingStatements/Overview'

const AccountingStatements = (props) => {
  // Overview page
  if (props.match.params.id === undefined) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={12} sm={11} md={10}>
          <Overview />
        </Grid>
      </Grid>
    );
  }

  // All items page
  if (props.match.params.id === "all") {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          <StatementsTable {...props} />
        </Grid>
      </Grid>
    );
  }

  // New Statement page
  if (props.match.params.id === "new") {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          <StatementsForm {...props} />
        </Grid>
      </Grid>
    );
  }

  // Edit statement page
  if (props.match.url.search('/edit') !== -1) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={11}>
          <StatementsForm {...props} />
        </Grid>
      </Grid>
    );
  }

  /*if (props.match.params.month) {
    return (
      <Grid container className="root" justifyContent="center">
        <Grid item xs={12} sm={10} md={8}>
          Need to figure out how to do the download, was bugging in my previous attempt
        </Grid>
      </Grid>
    )
  }*/

  // Table with preselected data if only id is sent, or with the year
  return (
    <Grid container className="root" justifyContent="center">
      <Grid item xs={12} sm={10} md={8}>
        <StatementsTable {...props} />
      </Grid>
    </Grid>
  );
}

export default AccountingStatements