
import { setCall, handleResponse } from '../_helpers';

export const sheetsServices = {
  sheetHeaders,
  sheetItems,
  sheet
}
async function sheetHeaders() {
  const requestOptions = {
    method: 'POST',
    body: setCall('expense_sheet_headers', 'get_expense_sheet_headers', {}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function sheetItems(header_id = "") {
  let requestOptions = {}
  if (header_id === "") {
    requestOptions = {
      method: 'POST',
      body: setCall('expense_sheet_items', 'get_expense_sheet_items', {}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  } else {
    requestOptions = {
      method: 'POST',
      body: setCall('expense_sheet_items', 'get_expense_sheet_items', {expense_sheet_header_id: header_id}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function sheet() {
  const headers = await sheetHeaders()
  let fullSheet = headers.response.details

  const items = await sheetItems()
  const itemsDetails = items.response.details

  for (let i = 0 ; i < itemsDetails.length ; i++) {
    const pointer = itemsDetails[i].expense_sheet_header_id - 1
    if (fullSheet[pointer] && !fullSheet[pointer].items) fullSheet[pointer].items = []

    if (fullSheet[pointer]) fullSheet[pointer].items.push(itemsDetails[i])
  }

  return fullSheet
}