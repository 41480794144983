import React, { useState, Fragment } from 'react'
/*eslint eqeqeq: "off"*/
// Material UI components
import { Card, CardHeader, CardContent, Button, CardActions, Tab, Tabs, Box, Typography } from '@material-ui/core'
import { Chart, Interval, Tooltip } from 'bizcharts'
import { dic } from '../../_constants'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const FormsYearOverview = (props) => {
  const {
    title = "Title",
    data = [
      {date: "05-2020", delivered: 100, name: "received"},
      {date: "06-2020", delivered: 100, name: "received"},
      {date: "07-2020", delivered: 100, name: "received"},
      {date: "08-2020", delivered: 100, name: "received"}
    ],
    dataBis = [
      {date: "05-2020", delivered: 100, name: "received"},
      {date: "06-2020", delivered: 100, name: "received"},
      {date: "07-2020", delivered: 100, name: "received"},
      {date: "08-2020", delivered: 100, name: "received"}
    ],
    position = "date*delivered",
    handlePrevious,
    handleNext
  } = props

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Card>
      <CardHeader
        title= { title }
        action = {
          <Tabs value={value} onChange={handleChange}>
            <Tab label={dic.YEARLY}>{dic.YEARLY}</Tab>
            <Tab label={dic.BISEMESTRIAL}>{dic.BISEMESTRIAL}</Tab>
          </Tabs>
        }
      />
      
      <CardContent>
        <TabPanel value={value} index={0}>
          <Chart height={250} autoFit data={data} interactions={['active-region']} padding="auto">
            <Interval
              adjust={[
                {
                  type: 'dodge',
                  marginRatio: 0,
                },
              ]}
              position={position}
              color={["name", ["#eee", "rgba(30, 125, 165, 0.6)"]]}
            />
            <Tooltip shared />
          </Chart>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Chart height={250} autoFit data={dataBis} interactions={['active-region']} padding="auto">
            <Interval
              adjust={[
                {
                  type: 'dodge',
                  marginRatio: 0,
                },
              ]}
              position={position}
              color={["name", ["#eee", "rgba(30, 125, 165, 0.6)"]]}
            />
            <Tooltip shared />
          </Chart>
        </TabPanel>
      </CardContent>

      <CardActions>
        {
          value === 0
          ? (
            <Fragment>
              <Button onClick={() => handlePrevious(0)}>{dic.PREVIOUS_YEAR}</Button>
              <Button disabled={data.length > 0 && data[data.length-1].date.split('-')[1] == new Date().getFullYear()} onClick={() => handleNext(0)}>{dic.NEXT_YEAR}</Button>
            </Fragment>
          )
          : (
            <Fragment>
              <Button onClick={() => handlePrevious(1)}>{dic.PREVIOUS_YEAR}</Button>
              <Button disabled={dataBis.length > 0 && dataBis[dataBis.length-1].date.split('-')[1] == new Date().getFullYear()} onClick={() => handleNext(1)}>{dic.NEXT_YEAR}</Button>
            </Fragment>
          )
        }
        
      </CardActions>
    </Card>
  )
}

export default FormsYearOverview