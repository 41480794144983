import React, { Fragment } from 'react'

import { Grid, Typography, Select, MenuItem } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import itLocale from "date-fns/locale/it";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// Dictionary
import { dic } from '../../../_constants'

const UserDataForm = (props) => {
  const {
    formData,
    setFormData,
    actor_type = "pilots",
    actors = [],
    hasSelectedCustomer
  } = props

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={8}>
        <Typography color="primary" variant="h6" style={{textAlign: 'left', marginTop: '20px'}}>
          { dic.TURNOVER_DATA }
        </Typography>
      </Grid>

      {
        actor_type === "admin"
        ? (
          <Fragment>
            <Grid item xs={8}>
              <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px'}}>
                {dic.ACTOR_ID}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Select
                id="actor_id"
                value={formData.actor_id?formData.actor_id:0}
                onChange={(e) => {setFormData({
                  id: "actor_id",
                  value: e.target.value
                })}}
                disabled={props.match.params.id !== "new" || hasSelectedCustomer}
              >
                {actors.map(elem => (
                  <MenuItem id={"actor_id-" + elem.id} value={elem.id} key={elem.actorname}>{elem.corporate_name}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Fragment>
        )
        : null
      }

      <Grid item xs={8}>
        <Typography style={{textAlign: 'left', marginLeft: '20px', marginTop: '5px'}}>
          {dic.DATE_PICKER}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            value={new Date(Date.parse(formData.year + "-" + formData.month + "-01"))}
            onChange={(val) => {
              setFormData({ id: "date", value: val})
            }}
            disabled={props.match.params.id !== "new"}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  )
}

export default UserDataForm