import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux'

// Material UI components
import {
  Grid,
  TextField,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';

import { userServices } from '../../_services/users.services'
import { dic } from '../../_constants/dictionary.constants'
import { history } from '../../_helpers/history'

// External components
import { useSnackbar  } from 'notistack';
import LoadingDialog from '../../_components/utils/Loading'

function PasswordForm(props) {
  const actorId = props.match.params.id
  const userId = props.match.params.userId
  const {userData} = props
  const [data, setData] = useState({
    old_password: '',
    password: '',
    password_confirm: '',
    actor_id: actorId,
    user_id: userId
  })
  const [isLoading, setIsLoading] = useState(false);

  const [selectedUserData, setUserData] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () =>{
        setIsLoading(true)

        let userData = await userServices.userFetch({user_id: userId, actor_id: actorId})
        userData = userData.response.details

        setUserData(userData)

        setIsLoading(false)
    }

    fetchData()
  }, [userId, actorId]);

  const handleChange = (event) => {
    if (event.target.id === undefined) {
      setData({...data, user_type: event.target.value })
    } else {
      setData({...data, [event.target.id]: event.target.value })
    }
  }

  const responseNotification = (data) => {
    if (data.header.result === 'ok')
        enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
    else
        for (var i in data.response.details)
            enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
}

  const responseTextNotification = (response) => {
      switch (response.header.function){
          case 'password_update':
              return dic.PASSWORD_UPDATED
          default: 
              return response.header.function
      }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    userServices.userUpdate(data, true)
    .then((data) => {
        responseNotification(data)
        
        if (data.header.result === 'ok')
            if (userData.actor.actor_type === 'admin')
                history.push('/actors/' + actorId + '/users')
            else
                history.push('/actors/' + actorId + '/users/' + userId)
    })
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <LoadingDialog open={isLoading} />
      <Card>
        <CardHeader
          subheader={dic.PASSWORD_UPDATE}
          title={dic.USER+' '+selectedUserData.name+' '+selectedUserData.surname}
        />
        <Divider />

        <CardContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10}>
              <Grid container spacing={2}>
                {userData.actor.actor_type !== 'admin' ?
                    <Grid item xs={12}>
                        <TextField required id="old_password" type="password" fullWidth label={dic.OLD_PASSWORD} variant="outlined" value={data.old_password} onChange={handleChange} />
                    </Grid>
                    :
                    null
                }
                <Grid item xs={12}>
                    <TextField required id="password" type="password" fullWidth label={dic.PASSWORD} variant="outlined" value={data.password} onChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                    <TextField required id="password_confirm" type="password" fullWidth label={dic.PASSWORD_CONFIRM} variant="outlined" value={data.password_confirm} onChange={handleChange} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        
        <CardActions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
                {userData.actor.actor_type === "admin" ?
                <Button color="secondary" size="large" onClick={() => history.push('/actors/'+actorId+'/users')}>{dic.BACK_TABLE}</Button>
                :null}
              <Button color="primary" type="submit" size="large">{dic.PASSWORD_UPDATE}</Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </form>
  );
}
let PasswordFormConnected = connect(state => ({userData: state.user}))(PasswordForm)
export default (PasswordFormConnected)