import { shipTypesServices } from '../../_services'
import { shipTypesConstants } from '../../_constants'

export function createShipsList() {
  return function(dispatch) {
    shipTypesServices.shipTypesFetchAll()
      .then(resp => {
        if (resp.header.result === 'ok') {
          const data = resp.response.details
          let transitForm = []

          for (let i = 0 ; i < data.length ; i++) {
            transitForm.push({
              id: data[i].id,
              entries: [
                {
                  ship_type_id: data[i].id,
                  customer_id: 0,
                  amount: 0,
                  discount_amount: 0,
                  second_pilot_amount: 0,
                  miles_amount: 0,
                  services: 0,
                  second_pilot_services: 0
                }
              ]
            })
          }
          dispatch({
            type: shipTypesConstants.CREATE_SHIPS_LIST,
            list: [...data],
            formData: [...transitForm]
          }) 
        } else {
          // error
          console.error(resp)
        }
      })
  }
}