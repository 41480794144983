import { setCall, handleResponse } from '../_helpers';

export const partnerServices = {
  partnersFetchAll, partnerInsert, partnerUpdate, partnerDelete
}

async function partnersFetchAll(searchParams) {
  const  requestOptions = {
      method: 'POST',
      body: setCall('partners', 'partners_search', !searchParams ? {} : searchParams),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function partnerInsert(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('partners', 'partner_insert', data),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function partnerUpdate(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('partners', 'partner_update', data),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function partnerDelete(keys) {
  const requestOptions = {
    method: 'POST',
    body: setCall('partners', 'partner_remove', keys),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}