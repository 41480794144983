import React, { useState, useEffect } from 'react';

// Material UI components
import {
  Grid,
  TextField,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';

import { gross_tonnagesServices } from '../../../_services/gross_tonnages.services'
import { dic } from '../../../_constants/dictionary.constants'
import { history } from '../../../_helpers/history'

import GrossTonnagesForm from './GrossTonnagesForm'
// External components
import { useSnackbar  } from 'notistack';
import LoadingDialog from '../../../_components/utils/Loading'

export default function GrossTonnageForm(props) {
  const { harbourId, actorId, grossTonnageId } = props
  const [data, setData] = useState({
    start_date: '2020-01-01',
    end_date: '2099-12-31',
    every: 10000,
    gross_tonnages: {},
    harbour_id: harbourId,
    actor_id: actorId
  })
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState([{from: 0, to: 500}])
  
  useEffect(() => {
    const fetchData = async () =>{
      setIsLoading(true)

      if (grossTonnageId !== "new") {
        let grossTonnageData = await gross_tonnagesServices.grossTonnageFetch({gross_tonnage_id: grossTonnageId, actor_id: actorId})
        grossTonnageData = grossTonnageData.response.details

        setData(grossTonnageData)

        let formatedData = []
        for (let entry in grossTonnageData.gross_tonnages) {
          formatedData.push({from: Number(grossTonnageData.gross_tonnages[entry]), to: Number(entry)})
        }

        setFormData(formatedData)
      }

      setIsLoading(false)
    }
    fetchData()
  }, [grossTonnageId, actorId]);

  const handleChange = (event) => {
    setData({...data, [event.target.id]: event.target.value })
  }

  const handleGrossTonnages = (type, key = 0, value = 0, id = "") => {
    let transit = [...formData]
    switch (type) {
      case 'update':
        transit[key][id] = Number(value)
        setFormData(transit)
        break
      case "add":
        transit.push({
          from: transit[transit.length - 1].to + 1,
          to: transit[transit.length - 2] ? transit[transit.length - 1].to + (transit[transit.length - 1].to - transit[transit.length - 2].to) : transit[transit.length - 1].to + 500
        })
        setFormData(transit)
        break
      case "remove":
        transit.splice(key, 1)
        setFormData(transit)
        break
      default:
        break
    }
  }

  const responseNotification = (data) => {
    if (data.header.result === 'ok')
      enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
    else
      for (var i in data.response.details)
        enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
  }

  const responseTextNotification = (response) => {
    switch (response.header.function){
      case 'gross_tonnage_insert':
        return dic.GROSS_TONNAGE_INSERTED
      case 'gross_tonnage_update':
        return dic.GROSS_TONNAGE_UPDATED
      case 'gross_tonnage_remove':
        return dic.GROSS_TONNAGE_DELETED
      default:
        return
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    let formatedGrossTonnages = {}
    
    for (let i = 0 ; i < formData.length ; i++) {
      formatedGrossTonnages[formData[i].to] = formData[i].from
    }
    
    let dataToSend = {...data, gross_tonnages: formatedGrossTonnages}

    if (grossTonnageId === "new"){
      gross_tonnagesServices.grossTonnageInsert(dataToSend)
      .then((data) => {
        responseNotification(data)
        
        if (data.header.result === 'ok')
          history.push('/actors/'+ actorId + '/harbours/'+ harbourId + '/gross_tonnages')
      })
    } else {
      gross_tonnagesServices.grossTonnageUpdate(dataToSend)
      .then((data) => {
        responseNotification(data)
        
        history.push('/actors/'+ actorId + '/harbours/'+ harbourId + '/gross_tonnages')
        
      })
    }
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <LoadingDialog open={isLoading} />
      <Card>
        <CardHeader
          subheader={grossTonnageId === "new" ? dic.GROSS_TONNAGE_CREATE : dic.GROSS_TONNAGE_UPDATE}
          title={dic.GROSS_TONNAGE}
        />
        <Divider />

        <CardContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        variant="outlined" 
                        id="start_date"
                        label={dic.VALID_FROM_DATE}
                        type="date"
                        value={data.start_date}
                        InputLabelProps={{shrink: true}}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        variant="outlined" 
                        id="end_date"
                        label={dic.VALID_FROM_DATE}
                        type="date"
                        value={data.end_date}
                        InputLabelProps={{shrink: true}}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={4}>
                  <TextField required id="every" type="number" fullWidth label={dic.EVERY_GROSS_TONNAGE} variant="outlined" value={data.every} onChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                  <GrossTonnagesForm data={formData} handleChange={handleGrossTonnages} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        
        <CardActions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button color="secondary" size="large" onClick={() => history.push('/actors/'+ actorId + '/harbours/'+ harbourId + '/gross_tonnages')}>{dic.BACK_TABLE}</Button>
              <Button color="primary" type="submit" size="large">{grossTonnageId === "new" ? dic.GROSS_TONNAGE_CREATE : dic.GROSS_TONNAGE_UPDATE}</Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </form>
  );
}