import React, { useState, Fragment } from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Grid, Typography } from '@material-ui/core';
import { AiOutlineApi, AiOutlineInfoCircle } from 'react-icons/ai';
import { MdAttachFile } from 'react-icons/md';
import { RiFileExcel2Line } from 'react-icons/ri';

import { documentServices } from '../_services'

import Loading from './utils/Loading';
import ReactPlayer from 'react-player/youtube'
export default function Documents(props) {
    const [loading, setLoading] = useState(false)

    const getDocument = async (doc, fileType, fileName) => {
        setLoading(true)
        await documentServices.getDocument(doc, fileType)
        .then(resp => {
            const file = URL.createObjectURL(new Blob([resp], {type: "application/"+fileType}))
            let link = document.createElement('a');
            link.href = file;
            link.download= fileName+"."+fileType;
            link.click();
            setLoading(false)
        })
      }
    
    return (
        <Fragment>
            <Loading open={loading} message={"Scarimento in corso, attendere prego.."} />
            <Grid container spacing={3} style={{marginLeft: 5, marginTop: 5}}>
                <Grid item xs={6}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem onClick={() => getDocument('man', 'pdf', "Manuale Utente")} style={{cursor: "pointer"}}>
                            <ListItemAvatar>
                                <Avatar>
                                    <AiOutlineInfoCircle />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Manuale utente" secondary="Scarica il manuale in PDF per l'utilizzo del programma" />
                        </ListItem>
                        <ListItem onClick={() => getDocument('xls', 'pdf', "Documentazione Excel")} style={{cursor: "pointer"}}>
                            <ListItemAvatar>
                                <Avatar>
                                    <RiFileExcel2Line />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Documentazione Excel" secondary="Scarica la documentazione in PDF per utilizzare le importazioni excel" />
                        </ListItem>
                        <ListItem onClick={() => getDocument('api', 'pdf', "Documentazione API")} style={{cursor: "pointer"}}>
                            <ListItemAvatar>
                                <Avatar>
                                    <AiOutlineApi />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Documentazione API" secondary="Scarica la documentazione in PDF per il collegamento alle api" />
                        </ListItem>
                        <ListItem onClick={() => getDocument('att', 'zip', "Allegati")} style={{cursor: "pointer"}}>
                            <ListItemAvatar>
                                <Avatar>
                                    <MdAttachFile />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Allegati" secondary="Scarica lo ZIP degli allegati utili per l'utilizzo dell'importazione excel e per le API" />
                        </ListItem>
                    </List>
                    <Typography gutterBottom variant="h5" component="div" style={{textAlign: "left"}} color="primary"><u>Attivare i sottotitoli durante la visualizzazione dei video tutorial</u></Typography>
                </Grid>
                <Grid item xs={6}>
                    <ReactPlayer url='https://youtu.be/GAN9KTvW8vo' controls={true} width="90%" height="360px" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <ReactPlayer url='https://youtu.be/w1Ubdv-dehk' controls={true} width="90%" height="360px" />
                        </Grid>
                        <Grid item xs={6}>
                            <ReactPlayer url='https://youtu.be/_vf4Va-E3YY' controls={true} width="90%" height="360px" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}