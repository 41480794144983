import { actorsConstants } from '../../_constants'

export function actors(state = [], action) {
  switch(action.type) {
    case actorsConstants.CREATE_ACTORS_LIST:
      return action.list
    case actorsConstants.CLEAR:
      return null
    default:
      return state
  }
}