import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux'

class RouteManager extends React.Component {
    render(){
        const { component: Component, ...rest} = this.props;
        const { userData } = this.props;
        
        return (
            <Route {...rest} render={props => (
                userData.isLogged
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/login' }} />
        )} />
        )
    }
}


const connectedRouteManager = connect(state => ({userData: state.user}))(RouteManager);
export default connectedRouteManager