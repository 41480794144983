import { surchargesServices } from '../../_services'
import { statisticsConstants } from '../../_constants'

export function createSurchargesForms() {
  return function(dispatch) {
    surchargesServices.surchargesFetchAll()
      .then(json => {
        if (json.header.result === 'ok') {
          const data = json.response.details
          let surchargesFormPattern = []

          for (let i = 0 ; i < data.length ; i++) {
            surchargesFormPattern.push({
              surcharge_id: data[i].id,
              number: 0,
              amount: 0,
              name: data[i].name
            })
          }

          dispatch({
            type: statisticsConstants.CREATE_SURCHARGES_FORM,
            formPattern: surchargesFormPattern
          })
        } else {
          // error
          console.error(json)
        }
      })
  }
}
