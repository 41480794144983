import React from 'react';
import { connect } from 'react-redux'

// Material UI components
import { Grid } from '@material-ui/core';
import TurnoverBySemesters from './TurnoverBySemesters';

const Export = (props) => {
  const { actors } = props;

  return (
      <Grid container>
        <Grid item style={{margin: 25, marginTop: 50}}>
            <TurnoverBySemesters
                actors={actors}
            
            />
        </Grid>
      </Grid>
  )
}

let ExportConnected = connect(state => ({actors: state.actors}))(Export)
export default ExportConnected