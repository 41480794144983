import { setCall, handleResponse } from '../_helpers';

export const actors_customersServices = {
  customersFetchAll,
  customersFetch,
  customersInsert,
  customersUpdate,
  customerUpdate,
  customersDelete
}

async function customersFetchAll(searchParams) {
  const requestOptions = {
    method: 'POST',
    body: setCall('actors_customers', 'actors_customers_search', !searchParams ? {} : searchParams),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function customersFetch(data) {
  const requestOptions = {
      method: 'POST',
      body: setCall('actors_customers', 'get_actors_customer_data', data),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function customersInsert(data) {
  const requestOptions = {
      method: 'POST',
      body: setCall('actors_customers', 'actors_customer_insert', data),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)
  
  return json;
}

async function customerUpdate(data) {  
  const requestOptions = {
      method: 'POST',
      body: setCall('actors_customers', 'actors_customer_update', data),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function customersUpdate(data) {
  delete data.id
  
  const requestOptions = {
      method: 'POST',
      body: setCall('actors_customers', 'actors_customers_update', {actors_customers: data}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function customersDelete(id, actor_id) {
  const requestOptions = {
      method: 'POST',
      body: setCall('actors_customers', 'actors_customer_remove', { actor_id: actor_id, id: id}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  console.log(requestOptions)
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)
  
  return json;
}
