import { setCall, handleResponse } from '../_helpers';

export const gross_tonnagesServices = { grossTonnageSearch, grossTonnageFetch, grossTonnageInsert, grossTonnageUpdate, grossTonnageDelete }

async function grossTonnageSearch(actor_id, harbour_id, search_date) {
  let requestOptions = {
    method: 'POST',
    body: setCall('gross_tonnages', 'gross_tonnages_search', search_date ? {actor_id, harbour_id, search_date} : {actor_id, harbour_id}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function grossTonnageFetch(data) {
  const requestOptions = {
      method: 'POST',
      body: setCall('gross_tonnages', 'get_gross_tonnage_data', data),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function grossTonnageInsert(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('gross_tonnages', 'gross_tonnage_insert', data),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function grossTonnageUpdate(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('gross_tonnages', 'gross_tonnage_update', data),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function grossTonnageDelete(keys) {
  const requestOptions = {
    method: 'POST',
    body: setCall('gross_tonnages', 'gross_tonnage_remove', keys),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}