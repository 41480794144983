import { Grid } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux'

import LastMonthOverview from './widgets/LastMonthOverview';
import LoadingDialog from './utils/Loading';

import { dic } from '../_constants'
import { statisticsServices, turnoversServices, statementsServices } from '../_services'
import { getPreviousDate } from '../_helpers'
import { 
  createExpensesForms,
  createSurchargesForms,
  createActorsList,
  createShipsList,
  createCustomersList,
  initCustomersList
} from '../_redux/_actions'

const Home = (props) => {
  const {userData, actors} = props
  
  const defaultData = [
    { type: dic.RECEIVED, percent: 0 },
    { type: dic.MISSING_DOCUMENTS, percent: 1 },
  ];
  const [statementData, setStatementData] = useState(defaultData)
  const [statisticData, setStatisticData] = useState(defaultData)
  const [turnoverData, setTurnoverData] = useState(defaultData)
  
  const [statement, setStatement] = useState({received: 0, total: 0})
  const [turnover, setTurnover] = useState({received: 0, total: 0})
  const [statistic, setStatistic] = useState({received: 0, total: 0})
  const [loading, setLoading] = useState(false)
  const defaultDate = getPreviousDate(0);

  useEffect(() => {
    const getStatementsData = async () => {
      await statementsServices.statementsOverview(defaultDate, defaultDate)
      .then(resp => {
        if (resp.header.result === "ok") {
          const data = resp.response.details
          const received = data[defaultDate.year][defaultDate.month].number_of_statements
          const total = userData.actor.actor_type === "admin" ? Object.keys(data[defaultDate.year][defaultDate.month].actors_statements).length : 1
          const receivedPercent = received > 0 ? (received/total) : 0
          const missingPercent = 1-receivedPercent;
          setStatement({received: received, total: total});
          setStatementData([
            { type: dic.RECEIVED, percent: receivedPercent },
            { type: dic.MISSING_DOCUMENTS, percent: missingPercent },
          ])
        }
      })
    }
    const getTurnoverData = async () => {
      await turnoversServices.turnoversOverview(defaultDate, defaultDate)
      .then(resp => {
        if (resp.header.result === "ok") {
          const data = resp.response.details
          const received = data[defaultDate.year][defaultDate.month].number_of_turnovers
          const total = userData.actor.actor_type === "admin" ? Object.keys(data[defaultDate.year][defaultDate.month].actors_turnovers).length : 1
          const receivedPercent = received > 0 ? (received/total) : 0
          const missingPercent = 1-receivedPercent;
          setTurnover({received: received, total: total});
          setTurnoverData([
            { type: dic.RECEIVED, percent: receivedPercent },
            { type: dic.MISSING_DOCUMENTS, percent: missingPercent },
          ])
        }
      })
    }
    const getStatisticData = async () => {
      await statisticsServices.statisticsOverview(defaultDate, defaultDate)
      .then(resp => {
        if (resp.header.result === "ok") {
          const data = resp.response.details
          const received = data[defaultDate.year][defaultDate.month].number_of_statistics
          const total = Object.keys(data[defaultDate.year][defaultDate.month].harbours_statistics).length
          const receivedPercent = received > 0 ? (received/total) : 0
          const missingPercent = 1-receivedPercent;
          setStatistic({received: received, total: total});
          setStatisticData([
            { type: dic.RECEIVED, percent: receivedPercent },
            { type: dic.MISSING_DOCUMENTS, percent: missingPercent },
          ])
        }
      })
    }
    const actorsList = () => {
      props.createActorsList()
    }
    
    const initialisation = () => {
      
      if (!props.statement || !props.statement.expenses.sections || props.statement.expenses.sections.length === 0) {
        setLoading(true);
        props.createExpensesForms()
      }

      if (!props.statistics || !props.statistics.formPattern || props.statistics.formPattern.length === 0) {
        setLoading(true);
        props.createSurchargesForms()
      }

      if (!props.ships || props.ships.length === 0) {
        setLoading(true);
        props.createShipsList()
      }

      if (!props.customers || props.customers.length === 0) {
        if (userData.actor.actor_type === 'pilots'){
          setLoading(true);
          props.createCustomersList()
        }
        else
          props.initCustomersList()
      }
    }
    
    if (userData.isLogged){
      //setTimeout(function () {
      initialisation();
      if (userData.actor.actor_type === "admin" && (!actors || actors.length === 0))
        actorsList()
    
      getStatementsData();
      getStatisticData();
      getTurnoverData();
      //}, 300);
      
    }
  },[userData.isLogged]);

  useEffect(() => {
    if (props.statement && props.statistics && props.ships && (props.customers || userData.actor.actor_type === 'admin'))
      setLoading(false);

  },[props]);

  return (
    <div>
      {!loading ? 
        <Grid container justifyContent="center" style={{marginTop: "20px"}}>
          <Grid item xs={12} lg={3} style={{padding: '10px'}}>
              <LastMonthOverview 
                title={dic.ACCOUNTING_STATEMENTS} 
                data={statementData} 
                date={defaultDate}
                userData={userData}
                numbers={statement}
                urls={{
                  overview: '/accounting_statements/',
                  new: '/accounting_statements/new',
                  all: '/accounting_statements/all',
                }}
              />
          </Grid>
          <Grid item xs={12} lg={3} style={{padding: '10px'}}>
              <LastMonthOverview
                title={dic.STATISTICS}
                data={statisticData}
                date={defaultDate}
                userData={userData}
                numbers={statistic}
                urls={{
                  overview: '/statistics/',
                  new: '/statistics/new',
                  all: '/statistics/all',
                }}
              />
          </Grid>
          <Grid item xs={12} lg={3} style={{padding: '10px'}}>
              <LastMonthOverview
                title={dic.TURNOVERS} 
                data={turnoverData} 
                date={defaultDate}
                userData={userData}
                numbers={turnover}
                urls={{
                  overview: '/turnover/',
                  new: '/turnover/new',
                  all: '/turnover/all',
                }}
              />
          </Grid>
        </Grid>
      :
      <LoadingDialog
        open={true}
      />
    }
    </div>
  )
}

let HomeConnected = connect(
    state => (
      {
        userData: state.user,
        actors: state.actors,
        statement: state.statements,
        statistics: state.statistics,
        ships: state.ships,
        customers: state.customers
      }
    ),
    {createActorsList, createExpensesForms, createSurchargesForms, createShipsList, createCustomersList, initCustomersList}
  )(Home)
export default HomeConnected