import { shipTypesConstants } from '../../_constants'

export function ships(state = [], action) {
  switch(action.type) {
    case shipTypesConstants.CREATE_SHIPS_LIST:
      return {
        ...state,
        list: action.list,
        formData: action.formData
      }
    case shipTypesConstants.CLEAR:
      return null
    default:
      return state
  }
}