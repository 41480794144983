import React, { forwardRef, useEffect, useState }  from 'react';

import MaterialTable from 'material-table'

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
}  from '@material-ui/icons';

import { sheetHeadersServices } from '../../_services/sheet_headers.services'
import { dic, dicTypes } from '../../_constants/dictionary.constants'

import { useSnackbar  } from 'notistack';



function SheetHeadersTable() {
    const { enqueueSnackbar } = useSnackbar();
    const [fetchingData, setFetchingData] = useState(false)
    const [sheetHeadersList, setsheetHeadersList] = useState([])
    
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };
      
      const parameters = [
        { title: dic.ID, field: 'id', editable: "never"},
        { title: dic.ORDER_FIELD, field: 'order_field', validate: rowData => rowData.order_field !== '' && rowData.order_field !== undefined, cellStyle: {color: "#1E7DA5"}},
        { title: dic.NAME, field: 'name', validate: rowData => rowData.name !== '' && rowData.name !== undefined, cellStyle: {color: "#1E7DA5"}}
      ]

    const responseNotification = (data) => {
        if (data.header.result === 'ok'){
            enqueueSnackbar(responseTextNotification(data), { variant: 'success', persist: false });
            getsheetHeaders();
        }
        else{
            for (var i in data.response.details)
                enqueueSnackbar(dic[data.response.details[i]], { variant: 'error'});
            setFetchingData(false)
        }
    }

    const responseTextNotification = (response) => {
        switch (response.header.function){
            case 'expense_sheet_header_insert':
                return dic.SHEET_HEADER_INSERTED
            case 'expense_sheet_header_update':
                return dic.SHEET_HEADER_UPDATED
            case 'expense_sheet_header_remove':
                return dic.SHEET_HEADER_DELETED
            default:
                return
        }
    }

    const getsheetHeaders = () => {
        setFetchingData(true)
        sheetHeadersServices.sheetHeadersFetchAll()
            .then(resp => {
                setsheetHeadersList(resp.response.details)
                setFetchingData(false)
            })
    }

    useEffect(() => {
        getsheetHeaders();
    }, [])

    return (
            <MaterialTable
            title={dic.SHEET_HEADERS}
            icons={tableIcons}
            columns={parameters}
            isLoading={fetchingData}
            data={sheetHeadersList}  
            options={{actionsColumnIndex: -1, exportButton: true, exportAllData: true}}
            localization={dicTypes._TABLES_LOCALIZATION_}
            editable={{
                onRowAdd: newData => {
                    return new Promise((resolve, reject) => {
                        if (!newData.name || !newData.order_field)
                            return reject();
                        
                        sheetHeadersServices.sheetHeaderInsert(newData)
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                            })
                    })
                },
                onRowUpdate: (newData, oldData) => {
                    return new Promise((resolve, reject) => {
                        if (!newData.name || !newData.order_field)
                            return reject();
                        newData.expense_sheet_header_id = oldData.id
                        sheetHeadersServices.sheetHeaderUpdate(newData)
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                        })
                    })
                },
                onRowDelete: oldData =>{
                    return new Promise((resolve, reject) => {
                        sheetHeadersServices.sheetHeaderDelete({expense_sheet_header_id: oldData.id})
                        .then((data) => {
                            responseNotification(data)
                            return resolve()
                        })
                    })
                },
            }}
            />
    )
}
export default (SheetHeadersTable)